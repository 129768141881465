import React from 'react';
import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress
} from "@mui/material";

const RouteVehicleStatusTable = ({ loading, error, vehicleData, formatDistance, formatTime }) => {
    const sortedVehicles = Object.values(vehicleData)
        .sort((a, b) => {
            const distA = parseFloat(a.distance);
            const distB = parseFloat(b.distance);
            if (isNaN(distA) && isNaN(distB)) return 0;
            if (isNaN(distA)) return 1;
            if (isNaN(distB)) return -1;
            return distA - distB;
        });

    return (
        <TableContainer component={Paper} elevation={3}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', fontSize: '1rem' }}>
                            Vehicle ID
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', fontSize: '1rem' }}>
                            Distance
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', fontSize: '1rem' }}>
                            ETA
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', fontSize: '1rem' }}>
                            Speed (km/h)
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', fontSize: '1rem' }}>
                            Temperature (°C)
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', fontSize: '1rem' }}>
                            Satellites
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {error ? (
                        <TableRow>
                            <TableCell colSpan={6} sx={{ textAlign: 'center', color: 'error.main', py: 2 }}>
                                {error}
                            </TableCell>
                        </TableRow>
                    ) : sortedVehicles.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={6} sx={{ textAlign: 'center', py: 2 }}>
                                No vehicle data available
                            </TableCell>
                        </TableRow>
                    ) : (
                        sortedVehicles.map((data) => (
                            <TableRow
                                key={data.device_id}
                                sx={{
                                    '&:nth-of-type(odd)': {
                                        backgroundColor: '#fafafa',
                                    },
                                    '&:hover': {
                                        backgroundColor: '#f0f0f0',
                                    },
                                }}
                            >
                                <TableCell sx={{ fontWeight: '500' }}>{data.device_id}</TableCell>
                                <TableCell>{formatDistance(data.distance)}</TableCell>
                                <TableCell>{formatTime(data.eta)}</TableCell>
                                <TableCell>{data.speed}</TableCell>
                                <TableCell>{data.temperature || "N/A"}</TableCell>
                                <TableCell>{data.satellites || "N/A"}</TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RouteVehicleStatusTable;