import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled
} from "@mui/material";

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: '#183059',
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 600,
    padding: '8px 16px',
  },
  '&.MuiTableCell-body': {
    fontSize: 14,
    color: '#333',
    padding: '12px 20px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: '16px',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
}));

const formatTime = (seconds) => {
  if (!seconds || seconds <= 0) return "N/A";

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  } else if (minutes > 0) {
    return `${minutes}m ${secs}s`;
  } else {
    return `${secs}s`;
  }
};

const NextStopTable = ({ stops, stopDetails }) => {
  // Sort stops by distance
  const sortedStops = [...(stops || [])].sort((a, b) => {
    const distA = stopDetails[a?.stopId]?.distance;
    const distB = stopDetails[b?.stopId]?.distance;

    const numA = typeof distA === 'number' ? distA : parseFloat(distA);
    const numB = typeof distB === 'number' ? distB : parseFloat(distB);

    if (isNaN(numA) && isNaN(numB)) return 0;
    if (isNaN(numA)) return 1;
    if (isNaN(numB)) return -1;

    return numA - numB;
  });

  const nextStop = sortedStops[0] || {};
  const nextStopDetails = nextStop?.stopId ? stopDetails[nextStop.stopId] : null;

  return (
    <StyledTableContainer component={Paper}>
      <Table aria-label="next stop table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Next Stop</StyledTableCell>
            <StyledTableCell>Stop ID</StyledTableCell>
            <StyledTableCell>Distance</StyledTableCell>
            <StyledTableCell>ETA</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>{nextStop?.name || "---"}</StyledTableCell>
            <StyledTableCell>{nextStop?.stopId || "---"}</StyledTableCell>
            <StyledTableCell>
              {nextStopDetails?.distance !== "N/A" && nextStopDetails?.distance
                ? `${parseFloat(nextStopDetails.distance).toFixed(1)} km`
                : "---"}
            </StyledTableCell>
            <StyledTableCell>
              {nextStopDetails?.eta ? formatTime(nextStopDetails.eta) : "---"}
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default NextStopTable;