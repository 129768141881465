import axios from "axios";
import config from "../../config.json"
export const fetchRoutes = async (setRoutes) => {
  try {
    const response = await axios.get(`${config.baseURL}/route/`, {
      withCredentials: true,
    });
    setRoutes(response.data);
  } catch (error) {
    console.log("error fetching routes", error);
  }
};
export const fetchDrivers = async (setDrivers,organizationId) => {
  try {
    const response = await axios.get(
      `${config.baseURL}/driver/organization`,
      { organizationId: 1 , withCredentials: true}
    );
    setDrivers(response.data);
  } catch (error) {
    console.log("error fetching drivers", error);
  }
};

export const fetchSites = async (setSites, organizationId) => {
  const siteEntity = `${`${config.baseURL}/site/organization`}`;
  try {
    const response = await axios.get(siteEntity, {
      organizationId: 1,
    });
    setSites(response.data);
  } catch (error) {
    console.log("error fetching sites", error);
  }
};

export const deleteVehicle = async (id) => {
  console.log(id)
  try {
    const response = await axios.delete(`${config.baseURL}/vehicle`, {
      data: { vehicleId:id },
    });
    return response.data;
  } catch (error) {
    console.log("Error in deleting vehicle data: ", error);
    throw error;
  }
};

export const getAllVehicles = async () => {
  try { 
    const response = await axios.get(`${config.baseURL}/vehicle/organization`, { withCredentials: true });
    console.log("Response: ", response.data);
    return response.data;
  }
  catch (error) {
    console.log("Error in fetching vehicle data: ", error);
    throw error;
  }
}