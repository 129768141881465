import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "../../components/header/DashboardHeader";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

function ManageData({ getVisibleAlerts, openModal, openDeleteModal, scheduleId }) {
  const navigate = useNavigate();

  return (
    <>
      <DashboardHeader title="Manage Data" />
      <div className="page_container">
        <div className="form_header">Data Backup</div>

        {/* Backup Section */}
        <div style={{ background: 'rgba(232, 234, 238, 1)', padding: '20px', borderRadius: '8px', marginBottom: '20px' }}>
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
            <div style={{ flex: 1 }}>
              <label>
                Select Organization
                <select style={{ width: '100%', padding: '8px', marginTop: '10px', borderRadius: '8px' }}>
                  <option value="1">Organization 1</option>
                </select>
              </label>
            </div>
            <div style={{ flex: 1 }}>
              <label>
                Backup Duration:
                <select style={{ width: '100%', padding: '8px', marginTop: '10px', borderRadius: '8px' }}>
                  <option value="2">Duration 2</option>
                </select>
              </label>
            </div>
          </div>
          <div className="addbutton_container" style={{ display: 'flex', gap: '10px', marginTop: '16px' }}>
            <button
              type="button"
              onClick={() => navigate("/schedule")}
              className="customButton_add"
              style={{
                padding: "12px 24px",
                border: "none",
                borderRadius: "4px",
                backgroundColor: "rgba(242, 242, 243, 1)",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="customButton_add"
              style={{
                padding: "12px 24px",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              {scheduleId ? "Update" : "Submit"}
            </button>
          </div>
        </div>



        {/* Import / Export Section */}
        <div className="form_header" style={{ marginTop: '20px' }}>Import / Export</div>
        <div style={{ display: 'flex', gap: '20px', justifyContent: 'space-between', marginTop: '20px' }}>
          {/* Import Box */}
          <div style={{ flex: 1, background: '#E8EAEE', borderRadius: '8px' }}>
            <div style={{ background: '#647490', padding: '10px', borderRadius: '4px', color: '#FFF' }}>Import</div>
            <div style={{ padding: '20px', }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px', margin: '10px 0' }}>
                <CloudDownloadIcon style={{ color: '#1A8DFF', fontSize: '20px' }} />
                <a href="#" style={{ color: '#1A8DFF', textDecoration: 'none' }}>
                  Download Sample Format
                </a>
              </div>
              <select style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '8px' }}>
                <option>Select Import Option</option>
              </select>
              <button
                className="customButton_add"
                style={{
                  background: 'rgba(32, 168, 224, 1)',
                  color: '#FFF',
                  padding: '10px',
                  border: 'none',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                + Attach Documents
              </button>
              <div style={{ marginTop: '10px', fontSize: '14px' }}>
                1. File Name.xls <span style={{ color: 'red' }}>✖</span>
              </div>
              <button
                className="customButton_add"
                style={{
                  background: '#FFD400',
                  color: '#000',
                  padding: '10px',
                  border: 'none',
                  borderRadius: '8px',
                  marginTop: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <CloudUploadIcon style={{ fontSize: '20px' }} /> Import
              </button>
            </div>
          </div>

          {/* Export Box */}
          <div style={{ flex: 1, background: '#E8EAEE', borderRadius: '8px' }}>
            <div style={{ background: '#647490', padding: '10px', borderRadius: '4px', color: '#FFF' }}>Export</div>
            <div style={{ padding: '20px', }}>
              <select style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '8px', marginTop: "40px" }}>
                <option>Select Export Option</option>
              </select>
              <button
                className="customButton_add"
                style={{
                  background: '#FFD400',
                  color: '#000',
                  padding: '10px',
                  border: 'none',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <CloudDownloadIcon style={{ fontSize: '20px' }} /> Export
              </button>
            </div>
          </div>
        </div>
        {/* Delete Data Section */}
        <div className="form_header" style={{ marginTop: '20px' }}>Delete Data</div>
        <div style={{ background: 'rgba(232, 234, 238, 1)', padding: '20px', borderRadius: '8px', marginTop: '10px' }}>
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
            <div style={{ flex: 1 }}>
              <label>
                Select Module:
                <select style={{ width: '100%', padding: '8px', marginTop: '10px', borderRadius: '8px' }}>
                  <option value="1">Module 1</option>
                </select>
              </label>
            </div>
            <div style={{ flex: 1 }}>
              <label>
                Select Duration:
                <select style={{ width: '100%', padding: '8px', marginTop: '10px', borderRadius: '8px' }}>
                  <option value="2">Duration 2</option>
                </select>
              </label>
            </div>
          </div>
          <div className="addbutton_container" style={{ marginBottom: '20px' }}>
            <button className="customButton_add" style={{ background: 'rgba(242, 242, 243, 1)', color: 'rgba(65, 73, 88, 1)', padding: '10px', border: 'none', borderRadius: '8px', margin: '20px', }}>
              Delete
            </button>
          </div>
        </div>


        {/* Trash Section */}
        <div
          className="search-filter-wrappers"
          style={{
            background: 'rgba(100, 116, 144, 1)',
            display: 'flex',
            justifyContent: "space-between",
            padding: '16px 24px',
            marginTop: '32px',
            alignItems: 'center',
            color: '#FFF',
            fontSize: '32px',
            fontWeight: '600',
            lineHeight: '1.5',
          }}
        >
          Trash
        </div>


        <div
          className="stop_strip"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '16px 24px',
            alignItems: 'center',
            backgroundColor: '#2ca8ff',
            color: '#FFF',
            fontSize: '24px',
            fontWeight: '500',

          }}
        >
          <div style={{ fontWeight: 'bold' }}>Deleted Items</div>
          <div style={{ display: 'flex', gap: '20px' }}>
            <div>Date</div>
            <div>Restore</div>
          </div>
        </div>

      </div>
    </>
  );
}

export default ManageData;
