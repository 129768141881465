/* eslint-disable no-undef */
import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
    OverlayView,
} from "@react-google-maps/api";
import DashboardHeader from "../../components/header/DashboardHeader";
import { loadOptions } from "../stops/mapConfig";
import config from "../../config.json";
import BusIcon from "../../images/bus-svgrepo-com.svg"

const VehicleAllTrack = () => {
    const location = useLocation();
    const [isLoadingVehicles, setIsLoadingVehicles] = useState(true);
    const allVehicleData = location.state?.allVehicleData || [];
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [mapBounds, setMapBounds] = useState(null);
    const [allVehicle, setAllVehicle] = useState([]);
    const { isLoaded } = useJsApiLoader(loadOptions);

    // Function to fetch location for a single vehicle
    const fetchVehicleLocation = async (deviceId) => {
        try {
            const response = await fetch(
                `${config.baseURL}/location/latest/${deviceId}`
            );
            const data = await response.json();
            return {
                deviceId,
                latitude: data.latitude,
                longitude: data.longitude,
            };
        } catch (error) {
            console.error(`Error fetching location for device ${deviceId}:`, error);
            return null;
        }
    };

    // Function to calculate bounds and center
    const updateMapView = (locations) => {
        if (locations.length === 0) return;

        const bounds = new window.google.maps.LatLngBounds();
        let validLocationsCount = 0;

        locations.forEach(location => {
            if (location && location.latitude && location.longitude) {
                bounds.extend({
                    lat: parseFloat(location.latitude),
                    lng: parseFloat(location.longitude)
                });
                validLocationsCount++;
            }
        });

        if (validLocationsCount > 0) {
            // Get the center from bounds
            const center = bounds.getCenter();
            setMapCenter({ lat: center.lat(), lng: center.lng() });
            setMapBounds(bounds);
        }
    };

    useEffect(() => {
        if (allVehicleData.length === 0) return;

        const updateLocations = async () => {
            setIsLoadingVehicles(true);
            try {
                const batchSize = 5; // Process 5 vehicles at a time
                const allLocations = [];

                // Process vehicles in batches
                for (let i = 0; i < allVehicleData.length; i += batchSize) {
                    const batch = allVehicleData.slice(i, i + batchSize);
                    const batchPromises = batch.map(vehicle =>
                        fetchVehicleLocation(vehicle.device_id)
                    );

                    const batchResults = await Promise.all(batchPromises);
                    const validLocations = batchResults.filter(location => location !== null);
                    allLocations.push(...validLocations);

                    // Update state with each batch
                    setAllVehicle(prev => [...prev, ...validLocations]);
                }

                // Set initial map center if we have locations
                if (allLocations.length > 0) {
                    setMapCenter({
                        lat: parseFloat(allLocations[0].latitude),
                        lng: parseFloat(allLocations[0].longitude),
                    });
                }
                updateMapView(allLocations);
            } catch (error) {
                console.error("Error updating vehicle locations:", error);
            } finally {
                setIsLoadingVehicles(false);
            }
        };

        // Reset vehicle state before fetching new data
        setAllVehicle([]);
        updateLocations();
    }, [allVehicleData]); // Only depend on allVehicleData

    const mapContainerStyle = {
        width: "100%",
        height: "600px",
    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <>
            <DashboardHeader title="Vehicle > TrackAllVehicle" />
            <div className="page_container" style={{ border: 'none' }}>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={14.5}
                    onLoad={map => {
                        map.setCenter({ lat: 13.1986, lng: 77.7099 });
                    }}
                >
                    {allVehicle.map((vehicle) => (
                        vehicle && vehicle.latitude && vehicle.longitude && (
                            <>
                                {/* Custom Overlay for the Label */}
                                <OverlayView
                                    position={{
                                        lat: parseFloat(vehicle.latitude),
                                        lng: parseFloat(vehicle.longitude),
                                    }}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            bottom: "14px", 
                                            transform: "translateX(-50%)",
                                            color: "green",
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            fontFamily: "Arial, sans-serif",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {vehicle.deviceId}
                                    </div>
                                </OverlayView>
                                <Marker
                                    key={vehicle.deviceId}
                                    position={{
                                        lat: parseFloat(vehicle.latitude),
                                        lng: parseFloat(vehicle.longitude),
                                    }}
                                    icon={{
                                        url: BusIcon,
                                        scaledSize: new google.maps.Size(40, 40),
                                        anchor: new google.maps.Point(20, 20),
                                    }}
                                />
                            </>
                        )
                    ))}
                </GoogleMap>

                {isLoadingVehicles &&
                    <div style={{
                        fontSize: '18px',
                        fontWeight: '500',
                        color: '#374151',
                        textAlign: 'center',
                        padding: '20px 0'
                    }}>
                        Loading All Vehicles...
                    </div>
                }
            </div>
        </>
    );
};

export default VehicleAllTrack;