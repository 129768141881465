import { Add } from "@mui/icons-material";
import { Button, Divider, MenuItem, Select, Dialog, DialogContent, DialogTitle, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import DashboardHeader from "../../../components/header/DashboardHeader";
import StarIcon from "../../../components/starIcon/StarIcon"
import {
  StyledFormButton,
  StyledFormControl,
  StyledFormInput,
  StyledFormInputLabel,
  StyledPaper,
} from "../../../components/formComponents";
import ImageUpload from "../../../components/imageUpload";
import useAdd from "../../../hooks/useAdd";
import useUpdate from "../../../hooks/useUpdate";
import {
  vehicleFormFields,
  vehicleFormFieldsDesc,
  vehicleFormFieldsLeftLower,
  vehicleFormFieldsRightLower,
  vehicleSiteFormField,
  vehicle_base_url,
} from "../../organization/utils";
import { fetchDrivers, fetchRoutes, fetchSites } from "../VehicleAPI";
import "./AddVehicle.css";
import config from "../../../config.json";
import ScheduleSelectionComponent from "../vehicleSchedule";

const validateField = (value, fieldName) => {
  if (value === undefined || value === null) return false;

  const validations = {
    device_id: value => value.trim().length > 0,
    name: value => value.trim().length >= 2,
    // VehicleNumber: value => /^[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/.test(value),
    VehicleNumber: value => value.trim().length >= 2,
    Make: value => value.trim().length > 0,
    Model: value => value.trim().length > 0,
    Year: value => {
      const currentYear = new Date().getFullYear();
      const year = parseInt(value, 10);
      return year >= 1900 && year <= currentYear;
    },
    chassisNumber: value => /^[A-HJ-NPR-Z0-9]{17}$/.test(value),
    insurancePolicyNumber: value => /^[A-Z]{3}\d{7}$/.test(value),
    insurancePolicyExpiry: value => new Date(value) > new Date(),
    description: value => value.trim().length >= 10
  };

  const validation = validations[fieldName];
  return validation ? validation(value) : true;
};

const ErrorMessage = ({ message }) => (
  <div style={{ color: 'red', fontSize: '0.8rem', marginTop: '-10px', marginBottom: "5px" }}>
    {message}
  </div>
);

const DropDownWrapper = ({
  item,
  vehicleFormData,
  handleDropdownChange,
  options = [],
}) => {
  const dropDownOptions = (item, options) => {
    if (item.id === "routes") {
      return options.map((option) => (
        <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
      ));
    } else if (item.id === "sites") {
      return options.map((option) => (
        <MenuItem key={option.id} value={option.id}>{option.siteId}</MenuItem>
      ));
    } else if (item.id === "drivers") {
      return options.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.first_name + " " + option.last_name}
        </MenuItem>
      ));
    }
    return [];
  };

  return (
    <Select
      id={item.id}
      value={vehicleFormData[item.fieldName] || ''}
      onChange={(e) => handleDropdownChange(e, item.fieldName)}
      input={<StyledFormInput />}
      placeholder={item.placeholder}
      label={item.placeholder}
    >
      {dropDownOptions(item, options)}
    </Select>
  );
};

const FormFieldWrapper = ({
  handleVehicleInputValue,
  item,
  vehicleFormData,
  routes = [],
  drivers = [],
  sites = [],
  errors = {}
}) => {
  return (
    <StyledFormControl variant="standard" fullWidth>
      <StyledFormInputLabel shrink htmlFor={item.id}>
        {item.label}
      </StyledFormInputLabel>

      {item.type === "select" ? (
        <>
          <DropDownWrapper
            item={item}
            vehicleFormData={vehicleFormData}
            handleDropdownChange={handleVehicleInputValue}
            options={
              item.id === "routes"
                ? routes
                : item.id === "sites"
                  ? sites
                  : drivers
            }
          />
        </>
      ) : (
        <>
          <StyledFormInput
            id={item.id}
            onChange={(e) => handleVehicleInputValue(e, item.fieldName)}
            value={vehicleFormData[item.fieldName] || ""}
            placeholder={item.placeholder}
            error={!!errors[item.fieldName]}
          />
          {errors[item.fieldName] && (
            <ErrorMessage message={errors[item.fieldName]} />
          )}
        </>
      )}
    </StyledFormControl>
  );
};

export default function AddVehicle() {
  const location = useLocation();
  const navigate = useNavigate();

  const [vehicleFormData, setVehicleFormData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [sites, setSites] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSchedules, setSelectedSchedules] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const { addEntity } = useAdd(config.BaseUrl);
  const { updateEntity } = useUpdate(vehicle_base_url);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);


  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      'device_id', 'name', 'VehicleNumber', 'description'
    ];

    requiredFields.forEach(field => {
      const value = vehicleFormData[field];
      if (!validateField(value, field)) {
        newErrors[field] = getErrorMessage(field);
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const requiredFields = [
      'device_id', 'name', 'VehicleNumber', 'description'
    ];

    const isFormValid = requiredFields.every(field =>
      vehicleFormData[field] && validateField(vehicleFormData[field], field)
    );

    setIsSubmitDisabled(!isFormValid);
  }, [vehicleFormData]);

  const getErrorMessage = (fieldName) => {
    const errorMessages = {
      device_id: 'Device ID is required',
      name: 'Vehicle name must be at least 2 characters',
      VehicleNumber: 'Invalid vehicle number (e.g., MH02AB1234)',
      Make: 'Vehicle make is required',
      Model: 'Vehicle model is required',
      Year: 'Year must be between 1900 and current year',
      chassisNumber: 'Invalid 17-character chassis number',
      insurancePolicyNumber: 'Invalid insurance policy number format',
      insurancePolicyExpiry: 'Insurance policy must be future dated',
      description: 'Description must be at least 10 characters'

    };
    return errorMessages[fieldName] || 'Invalid input';
  };

  useEffect(() => {
    const fetchVehicleDataById = async (vehicleId, organizationId) => {
      try {
        const updateVehicleUrl = `${config.baseURL}/vehicle/id`;
        const response = await axios.get(
          updateVehicleUrl,
          {
            params: {
              vehicleId,
              organizationId,
            },
          },
          { withCredentials: true }
        );
        setVehicleFormData(response.data.vehicle);
      } catch (error) {
        console.log(error);
      }
    };

    if (location.state) {
      fetchVehicleDataById(location.state, 1);
    }
  }, [location.state]);

  useEffect(() => {
    fetchRoutes(setRoutes);
    fetchSites(setSites, 1);
    fetchDrivers(setDrivers, 1);
  }, []);

  const handleVehicleInputValue = (e, fieldName) => {
    const inputValue = e.target.value;
    const updatedFormData = { ...vehicleFormData, [fieldName]: inputValue };

    const isValid = validateField(inputValue, fieldName);
    setErrors(prev => ({
      ...prev,
      [fieldName]: isValid ? '' : getErrorMessage(fieldName)
    }));

    setVehicleFormData(updatedFormData);
  };


  const handleSchedulesSelected = async (selectedScheduleIds, vehicleId) => {
    try {
      await axios.patch(`${config.baseURL}/vehicle/schedule`, {
        scheduleIds: Object.keys(selectedScheduleIds),
        vehicleId: vehicleId,
      });
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error updating schedules:", error);
      alert("Failed to update schedules. Please try again.");
    }
  };

  const handleRoutesSelected = async (vehicleId) => {
    const selectedRouteId = vehicleFormData.routes;
    if (!selectedRouteId) {
      console.error("No route selected");
      return;
    }
    try {
      const resp = await axios.patch(`${config.baseURL}/vehicle/route`, {
        routeId: selectedRouteId,
        vehicleId: vehicleId,
      });
      console.log(resp.data, "route added to vehicle");
    } catch (error) {
      console.error("Error updating route:", error);
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    const clonedVehicleFormData = { ...vehicleFormData };

    try {
      if (location.state) {
        const resp = await axios.put(
          `${config.baseURL}/vehicle`,
          clonedVehicleFormData,
          { withCredentials: true }
        );

        if (resp.data) {
          await handleSchedulesSelected(selectedSchedules, location.state);
          await handleRoutesSelected(location.state);
          navigate("/Vehicle");
        }
      } else {
        const resp = await axios.post(
          `${config.baseURL}/vehicle`,
          clonedVehicleFormData,
          { withCredentials: true }
        );

        if (resp.data) {
          await handleSchedulesSelected(selectedSchedules, resp.data.vehicle.id);
          await handleRoutesSelected(resp.data.vehicle.id);
          navigate("/Vehicle");
        }
      }
    } catch (error) {
      console.error('Submission failed', error);
      setErrors(prev => ({
        ...prev,
        submitError: 'Unable to submit form. Please check your inputs.'
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate("/Vehicle");
  };

  const handleAddScheduleInVehicle = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDocumentUpload = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  return (
    <>
      <DashboardHeader title="Vehicles > Add " />

      <div className="page_container">
        <div className="addbutton_container">
          <button
            variant="contained"
            color="primary"
            onClick={handleAddScheduleInVehicle}
            className="customButton_add"
            style={{
              color: "rgba(255, 255, 255, 1)",
              background: "rgba(32, 168, 224, 1)",
              marginRight: "-24px",
            }}
          >
            Assign Schedule
          </button>

          <Dialog
            open={isModalOpen}
            onClose={handleCloseModal}
            maxWidth="md"
            fullWidth
            sx={{ width: "100vw", height: "100vh", overflow: "auto" }}
          >
            <DialogTitle>Select Schedules</DialogTitle>
            <DialogContent>
              <ScheduleSelectionComponent
                vehicleId={location.state}
                onSchedulesSelected={(selected) => {
                  setSelectedSchedules((prev) => ({
                    ...prev,
                    ...selected.reduce((acc, id) => {
                      acc[id] = true;
                      return acc;
                    }, {}),
                  }));
                }}
                selectedSchedules={selectedSchedules}
                closeWindow={handleCloseModal}
              />
            </DialogContent>
          </Dialog>

          <div className="site-form-field">
            {vehicleSiteFormField.map((item) => (
              <FormFieldWrapper
                key={item.id}
                item={item}
                handleVehicleInputValue={handleVehicleInputValue}
                vehicleFormData={vehicleFormData}
                sites={sites}
                errors={errors}
              />
            ))}
          </div>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", gap: '8px', marginTop: "10px" }}>
          {vehicleFormFields.map((item) => {
            const isRequired = item.isRequired !== false;
            const labelText = isRequired ? item.label.replace(' *', '') : item.label;

            return (
              <StyledFormControl
                variant="standard"
                fullWidth
                required={isRequired}
                key={item.id}
                style={{ flex: "1 1 48%" }}
              >
                <StyledFormInputLabel shrink htmlFor={item.id}>
                  {labelText}
                </StyledFormInputLabel>
                <StyledFormInput
                  id={item.id}
                  placeholder={item.placeholder}
                  onChange={(e) => handleVehicleInputValue(e, item.fieldName)}
                  value={vehicleFormData[item.fieldName] || ""}
                />
                {errors[item.fieldName] && (
                  <ErrorMessage message={errors[item.fieldName]} />
                )}
              </StyledFormControl>
            );
          })}
        </div>

        <div>
          <div className="form-container">
            <div>
              {vehicleFormFieldsLeftLower.map((item) => (
                <FormFieldWrapper
                  key={item.id}
                  item={item}
                  handleVehicleInputValue={handleVehicleInputValue}
                  vehicleFormData={vehicleFormData}
                  errors={errors}
                />
              ))}
            </div>
            <div>
              {vehicleFormFieldsRightLower.map((item) => (
                <FormFieldWrapper
                  key={item.id}
                  item={item}
                  handleVehicleInputValue={handleVehicleInputValue}
                  vehicleFormData={vehicleFormData}
                  errors={errors}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="desc-form-field" style={{ width: "100%", padding: "0px" }}>
          {vehicleFormFieldsDesc.map((item) => {
            const isRequired = item.isRequired !== false;
            const labelText = isRequired ? item.label.replace(' *', '') : item.label;

            return (<>
              <StyledFormControl
                variant="standard"
                fullWidth
                required={isRequired}
                key={item.id}
                style={{ flex: "1 1 48%" }}
              >
                <StyledFormInputLabel shrink htmlFor={item.id}>
                  {labelText}
                </StyledFormInputLabel>
                <StyledFormInput
                  id={item.id}
                  placeholder={item.placeholder}
                  onChange={(e) => handleVehicleInputValue(e, item.fieldName)}
                  value={vehicleFormData[item.fieldName] || ""}
                />
                {errors[item.fieldName] && (
                  <ErrorMessage message={errors[item.fieldName]} />
                )}
              </StyledFormControl>
            </>);
          })}
        </div>


        {errors.submitError && (
          <ErrorMessage message={errors.submitError} />
        )}

        <div className="addbutton_container" style={{ marginTop: '40px' }}>
          <button
            variant="outlined"
            style={{ background: "rgba(242, 242, 243, 1)" }}
            onClick={handleCancel}
            className="customButton_add"
          >
            Cancel
          </button>
          <button
            variant="contained"
            onClick={handleSubmit}
            className="customButton_add"
          // disabled={isSubmitting || isSubmitDisabled || Object.keys(errors).length > 0}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </div>

      {isSubmitting && (
        <Dialog open={true}>
          <DialogTitle>Submitting</DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100px' }}>
              <CircularProgress />
              <p style={{ marginTop: '16px' }}>Please wait while we process your vehicle information...</p>
            </div>
          </DialogContent>
        </Dialog>
      )}

    </>
  );
}