import React, { useEffect, useState } from 'react'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import Pagination from '../../../components/pagination/Pagination';
import "./stopScheduleDetails.css";
import { Button, Paper } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircleIcon from '@mui/icons-material/Circle'
import { ArrowDropDown, ArrowDropUp, Schedule } from '@mui/icons-material';
import { getSortedData } from '../../organization/utils';
import styled from '@emotion/styled';



// Function to create a new row data object
function createData(id,vehicles, arrivalTime, departureTime,repitation,routes) {
	return { id,vehicles, arrivalTime, departureTime,repitation,routes };
  }
  
  // Column definitions for the table
  const columns = [
	{ label: "Vehicles", sortable:true,id: "vehicles" },
	{ label: "Arrival Time",sortable:true, id: "arrivalTime" },
	{ label: "Departure Time", id: "departureTime" },
	{ label: "Repitation", sortable:true,id: "repitation" },
	{ label: "Routes", id: "routes" },
  
  ];
   // Function to transform raw data into table rows
   const getRows = (rows) =>
   rows.map((item, index) => {
	 return createData(
    index,

	   item.vehicles,
	   item.arrivalTime,
	   item.departureTime,
	   item.repitation,
	   item.routes
	 );
   });

function StopSchedulesTable({filteredSchedulesData}) {

  const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setitemPerPage] = useState();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");

  const theme = useTheme();
  // console.log("filteredSchedulesData",filteredSchedulesData)


  useEffect(() => {
    setitemPerPage(10);
    }, []);
    
     // Handle sorting of table columns
  const handleColumnSort = (id) => {
    const newOrder = order === "asc" ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(id);
    };

	// Inline styles
	const styles = {
	  header: { backgroundColor: theme.palette.primary.main, color: "white" },
	};
   // Calculate start and end index for pagination
   const startIndex = (currentPage - 1) * itemsPerPage;
   const endIndex = currentPage * itemsPerPage;
 
   // Calculate total count and total pages for pagination
   const totalCount = parseInt(filteredSchedulesData?.length);
   const totalPages = Math.ceil(totalCount / itemsPerPage) || 1;
 
   // Function to handle page change
   const handlePageChange = (pageNumber) => {
     setCurrentPage(pageNumber);
   };
   const sortedData = Array.isArray(filteredSchedulesData) ? filteredSchedulesData.sort((a, b) =>
   getSortedData(a, b, orderBy, order)
 ) : [];
 
  const currentData = sortedData?.slice(startIndex, endIndex);    
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(32, 168, 224, 1)",
      color: "white",
      fontSize: 20,
      fontWeight: 600,
      paddingLeft:30,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 24,
    },
  }));
  
  return (
	<>
        <TableContainer style={{marginTop:"-20px"}}>
       
          <Table >
            <TableHead >
            <TableRow >
            
            {columns.map((column) => (
                    <StyledTableCell
                      align="left"
                      key={column.id}
                      onClick={() => handleColumnSort(column.id)}
                    >
                      {column.label}{" "}
                      {column.sortable &&
                        (orderBy === column.id ? (
                          order === "asc" ? (
                            <ArrowDropUp
                              style={{ verticalAlign: "middle", cursor: "pointer",color:'rgba(24, 48, 89, 1)' }}
                            />
                          ) : (
                            <ArrowDropDown
                              style={{ verticalAlign: "middle", cursor: "pointer",color:'rgba(24, 48, 89, 1)' }}
                            />
                          )
                        ) : (
                          <ArrowDropUp
                            style={{ verticalAlign: "middle", cursor: "pointer" ,color:'rgba(24, 48, 89, 1)'}}
                          />
                        ))}
                    </StyledTableCell>
            
                  ))}
  
            </TableRow>
            </TableHead>
            <TableBody className='TableBody-root'>
              {getRows(currentData).map((row)=>(
                 <TableRow key={row.id}
                 className="MuiTableRow-root"
                 style={{ color: "black"}} 
                 sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
               >
                 <TableCell align="center" className="MuiTableCell-root">{row.vehicles.toString()}</TableCell>
                 <TableCell align="center" className="MuiTableCell-root">{row.arrivalTime}</TableCell>
                 <TableCell align="center" className="MuiTableCell-root">{row.departureTime}</TableCell>
                 <TableCell align="center" className="MuiTableCell-root">{row.repitation}</TableCell>
                 <TableCell align="center" className="MuiTableCell-root">{row.routes}</TableCell>
                 <TableCell align="center">

                 </TableCell>
               </TableRow>

              ))}
              
              
            </TableBody>
          </Table>
        </TableContainer>
        {currentData.length === 0 && (
        <div style={{ padding: '16px', textAlign: 'center', color: 'gray' }}>
          No schedules found for the stop
        </div>
      )}
        { <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        /> } 
	</>  
  )
}

export default StopSchedulesTable