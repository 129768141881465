import React, { useEffect, useState } from 'react'
import axios from 'axios'
import config from '../../config.json' 
import sortByParam from '../../hooks/SortByParam';
import sarchInTheTable from '../../hooks/SarchInTable';
import filterBy from '../../hooks/FilterBy';
import DashboardHeader from '../../components/header/DashboardHeader';
import { Paper } from '@mui/material';
import FeatureForm from './FeatureForm';
import FeaturesTable from './FeaturesTable';
import FeatureSearch from './FeatureSearch';


let IncomingFfeaturesList = []; // to store incoming feature objects

/**
 * This function calls an api to return the list of features
 * @returns List<Feature>
 */
async function getAllFeature() {
  try {
    const response = await axios.get(`${config.baseURL}/feature`, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching features:', error);
    throw error;
  }
}

/**
 * calls an api to delete the feature from the backend
 * @param {int} id 
 * @returns responseObjet-> message,feature
 */
async function deleteFeature(id) {
  try {
    const response = await axios.delete(`${config.baseURL}/feature/${id}`, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

/**
 * 
 * @param {name:string,
 * description:string,
 * api_path:string,
 * action:string} feture 
 * @returns 
 */
async function createFeature(feture) {
  try {
    const response = await axios.post(`${config.baseURL}/feature`, feture, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function updateFeature(feature, id) {
  try {
    const response = await axios.put(`${config.baseURL}/feature/${id}`, feature, {
      withCredentials: true
    });
    return response.data.feature;
  } catch (error) {
    throw error
  }
}

const Feature = () => {
  const [currentTableData,setCurrentTableData]=useState([])
  const [searchStr,setSearchStr]=useState('');
  const [apiPath,setApiPath]=useState('')
  const [operation,setOperation]=useState('');
  const [sortParam, setSortParam] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');



   // onChange for searching 
   const handleSearchChange = (e) => {
    const searchText = e.target.value.toLowerCase(); // Convert search text to lowercase
    console.log("Search Text:", searchText); // Log search text
    setSearchStr(searchText); // Update search string state
  };
  
  // on change for filtering by api_path
  const handleApiPathChange = (e) => {
    const selectedValue = e.target.value;
    console.log("Selected API Path:", selectedValue); // Log selected value
    // If "All" is selected, set apiPath state to an empty string to remove the filter
    // Otherwise, set it to the selected value
    setApiPath(selectedValue === 'All' ? '' : selectedValue);
  };
  
  // on change for filtering  by operation
  const handleOperationChange = (e) => {
    const selectedValue = e.target.value;
    console.log("Selected operation:", selectedValue); // Log selected value
    // If "All" is selected, set operation state to an empty string to remove the filter
    // Otherwise, set it to the selected value
    setOperation(selectedValue === 'All' ? '' : selectedValue);
  };
  const handleSortChange = (param) => {
    // If the currently sorted parameter is the same as the clicked one, toggle direction
    const newSortDirection = sortParam === param ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
    setSortDirection(newSortDirection);
    setSortParam(param);
  };
  
  
  useEffect(() => {
    getAllFeature()
      .then((features) => {
        IncomingFfeaturesList=features;
        setCurrentTableData(features);
        console.log(features);
  
        // Apply sorting
        let sortedFeatures = sortByParam(sortParam, features);
      if (sortDirection === 'desc') {
        // If sorting direction is descending, reverse the sorted array
        sortedFeatures = sortedFeatures.reverse();
      }
      setCurrentTableData(sortedFeatures);
        // Apply search
       if(searchStr){
        let searchResult = sarchInTheTable(searchStr, features);
        setCurrentTableData(searchResult);
        console.log(searchResult, 'search result');
       }
  
        // Apply filtering
        if (apiPath) {
          let filteredResults = filterBy('api_path', apiPath, features);
          setCurrentTableData(filteredResults); // Update state after filtering
        }
       if(operation){
        let operationFilter=filterBy('action',operation,features);
        setCurrentTableData(operationFilter);
       }
       
      })
      .catch((error) => console.error(error));
  }, [searchStr, apiPath,operation,sortParam,sortDirection])
  
  
  
  function addFeature(feature) {
    console.log(feature.feature, 'feature');
    const newList= [...currentTableData, feature.feature];
    setCurrentTableData(newList);
  }

  // deleteFeature(2).
  //   then(response => console.log(response)).catch(error => console.log(error))
  // updateFeature({
  //   "name": "get all users",
  //   "description": "this feature allow the user to git list of organization",
  //   "api_path": "/api/user/",
  //   "action": "DELETE"
  // },3).then(response => console.log(response)).catch(error => console.log(error));
  return (
    <>
    <DashboardHeader title="Features"/>
    <div className='page_container'>
      <FeatureForm createFeature={createFeature} addFeature={addFeature} />
      <FeatureSearch featuresList={IncomingFfeaturesList}
      totalFeatures={IncomingFfeaturesList.length}
       handleSearchChange={handleSearchChange}
       handleApiPathChange={handleApiPathChange}
       handleOperationChange={handleOperationChange}
       />
      <FeaturesTable 
      featuresList={currentTableData}
       onDelete={deleteFeature}
       onUpdate={updateFeature}
       onSortChange={handleSortChange}
       sortParam={sortParam}
       />

    </div>
    </>
  )
}

export default Feature
