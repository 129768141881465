import React, { useState } from "react";
import "./Login.css";
import { google, logo } from "../../config/Pageitems";
// import AppleIcon from '@mui/icons-material/Apple';
import Snackbar from '@mui/material/Snackbar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faLinkedin,
  faTwitterSquare,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import config from "../../config.json";
import HomeHeader from "../../components/header/homeHeader/HomeHeader";
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useUser } from "./UserContextPersistent";

const baseURL = config.baseURL;
const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [checked, setChecked] = React.useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = React.useState(false);


  const { dispatch } = useUser();
  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log(event.target.checked);
  };

  const validateForm = () => {
    let isValid = true;
    setEmailError("");
    setPasswordError("");

    if (!email) {
      setEmailError("Username/Email is required");
      isValid = false;
    }
    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    }

    return isValid;
  };

  const userLogin = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;

    }
    setErrorMessage(""); // Clear previous error messages
    const response = await fetch(`${baseURL}/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        password,
      }),
      credentials: "include", // Include cookies in the request
    });
    console.log(response);
    // const cookieHeader = response.headers['set-cookie'];
    // console.log(cookieHeader)
    // console.log(response.headers)
    if (response.ok) {
      // Extract cookies from the responseponse headers
      const data = await response.json();
      const cookies = new Cookies();
      const token = cookies.get("token"); // Assuming 'token' is the name of the cookie storing the
      setSuccessMessage("login successful");
      dispatch({ type: "SET_USER", payload:{ ...data.tokenUser.tokenuser,role:data.tokenUser.userRoleWithAccess.name }});
     dispatch({
        type: "SET_FEATURES",
        payload: data.tokenUser.userRoleWithAccess.features.map((feature) => ({
          action: feature.feature.action,
          api_path: feature.feature.api_path,
        })),
      });
      setEmail("");
      setPassword("");
      // Display the success message for 2 sec before navigating

      navigate("/overview");
    } else {
      // Handle login failure
      setErrorMessage("An error occurred while logging in. Please try again.");
      // Clear the input fields
      setEmail("");
      setPassword("");
      setOpen(true);
      // Hide the error message after 3 seconds
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      console.error("Login failed");
    }
  };
  // handle change for email change and removing the errors when the user satrts entering credentials
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };
  // for password
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };
  const handleForgotPassword = () => {
    setShowForgotPassword(true);
  };

  const handleBackToLogin = () => {
    setShowForgotPassword(false);
    setMessage("");
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${config.baseURL}/user/forgot/password`, { email });
      setMessage(
        "Your password reset request has been sent. You will receive communication from admin soon."
      );
    } catch (error) {
      setMessage(
        "Your password reset request has been sent. You will receive communication from admin soon."
      );
    }
  };
  // password visibility and visibility off
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <HomeHeader />

      {/* {errorMessage && (
        // <Typography
        //   color="error"
        //   variant="body2"
        //   sx={{ marginLeft: "680px", marginTop: "30px" }}
        // >
        //   {errorMessage}
        // </Typography>

        <Snackbar
          open={open}
          autoHideDuration={6000}
          message={errorMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{
            '& .MuiSnackbarContent-root': {
              backgroundColor: 'red',
              color: 'white',
            },
          }}
        />
      )} */}
      {successMessage && (
        <Typography
          color="blue"
          variant="body2"
          sx={{ marginLeft: "680px", marginTop: "30px" }}
        >
          {successMessage}
        </Typography>
      )}

      <div className="login_container">
        <div className="content_container">
          <div className="container_container1">
            <div className="content_container11">
              {/* {config.pages.login_page.header} */}
              Track. Manage. Optimize.
              <div
                className="content_container11_2"
                style={{}}
              >
                All in Real-time
              </div>
            </div>

            <div className="content_container12">
              <span
                style={{
                  font: "28px",
                  fontWeight: "400",
                  lineHeight: "39.38px",

                }}
              >
                RouteEye is designed to streamline your fleet management. This
                platform offers GPS based real-time vehicle tracking along with
                anomaly detection and detailed analytics, empowering you to
                enhance operational efficiency, reduce costs, and improve
                overall fleet performance.
                <br />
                <br />
              </span>
              <span
                style={{
                  font: "28px",
                  fontWeight: "600",
                  lineHeight: "39.38px",
                }}
              >
                Stay connected and in control.
              </span>
            </div>
          </div>

          <div className="container_container2">
            <form
              autoComplete="off"
              onSubmit={
                showForgotPassword ? handleForgotPasswordSubmit : userLogin
              }
            >
              <Typography variant="h6">Username/Email</Typography>
              <TextField
                id="email"
                placeholder="Type Username/Email"
                type="text"
                variant="outlined"
                fullWidth
                className="form_field"
                autoComplete="new-email"
                error={!!emailError}
                helperText={emailError || " "}
                onChange={handleEmailChange}
                value={email}
                InputProps={{
                  sx: {
                    borderRadius: "16px",
                    border: "2px solid rgba(24, 48, 89, 1)",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  },
                }}
              />

              {!showForgotPassword ? (
                <>
                  <Typography variant="h6">Password</Typography>
                  <TextField
                    id="password"
                    placeholder=" Type Password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    className="form_field"
                    error={!!passwordError}
                    helperText={passwordError || " "}
                    onChange={handlePasswordChange}
                    autoComplete="new-password"
                    value={password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className="eye_icon">
                          {showPassword ? (
                            <VisibilityIcon
                              onClick={togglePasswordVisibility}
                            />
                          ) : (
                            <VisibilityOffIcon
                              onClick={togglePasswordVisibility}
                            />
                          )}
                        </InputAdornment>
                      ),
                      sx: {
                        borderRadius: "16px",
                        border: "2px solid rgba(24, 48, 89, 1)",
                        mb: "10px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      },
                    }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <FormControlLabel
                      label="Remember Me"
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={(e) => setChecked(e.target.checked)}
                          color="primary"
                          sx={{
                            color: "rgba(65, 73, 88, 1)", // Color of the unchecked box
                            '&.Mui-checked': {
                              color: "rgba(32, 168, 224, 1)", // Color of the checked box
                            },
                          }}
                        />
                      }
                      sx={{
                        ".MuiFormControlLabel-label": {
                          fontWeight: "400",
                          fontSize: { xs: "14px", sm: "20px" },
                          color: "rgba(65, 73, 88, 1)",
                        },
                      }}
                    />

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        cursor: "pointer",
                        color: "rgba(32, 168, 224, 1)",
                        fontWeight: "400",
                        fontSize: { xs: "14px", sm: "20px" },
                      }}
                      onClick={handleForgotPassword}
                    >
                      Forgot Password?
                    </Typography>
                  </Box>
                  <Typography
                    color="error"
                    variant="body2"
                  >
                    {errorMessage}
                  </Typography>
                </>
              ) : (
                message === "" && (
                  <Typography
                    variant="body2"
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    Don't worry. Resetting your password is easy, just tell us
                    the username or email address you registered with RouteEye.
                  </Typography>
                )
              )}

              {message && (
                <Typography
                  color="primary"
                  variant="body2"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  {message}
                </Typography>
              )}
              <Button
                className="customButton_add"
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  width: "100%",
                  borderRadius: "16px",
                  marginTop: "40px",
                  backgroundColor: "#FFFF00", // Yellow color
                  color: "#000000",
                  boxShadow: 'none',

                }}
              >
                <span style={{ textTransform: "none", fontWeight: '600', fontSize: '32px' }}>
                  {showForgotPassword ? "Send" : "Sign in"}
                </span>

              </Button>

              {showForgotPassword && (
                <Button
                  onClick={handleBackToLogin}
                  style={{ marginTop: "10px" }}
                >
                  Back to Login
                </Button>
              )}
            </form>
          </div>
        </div>

        <div
          className="content_container13"
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "40px",
            justifyContent: "center",
            gap: "2rem"
            // flexDirection: "row"
          }}
        >
          <Typography
            variant="body2"
            style={{
              fontSize: "32px",
              color: "black",
              marginTop: "4px",
              font: "Roboto",
            }}
          >
            Don't have an account?
          </Typography>


          <button
            variant="contained"
            className="customButton_add"
            style={{
              fontSize: "20px",
              width: "212px",
              marginTop: "10px",
              marginLeft: "40px",
              padding: "12px 24px 12px 24px ",
              borderRadius: "8px",
              textTransform: "none",
              background: "#20A8E0",
              boxShadow: "none",
              color: "white",
            }}
            onClick={() => {
              console.log("clicked");
              navigate("/register");
            }}
          >
            Register Now
          </button>
          <button
            className="customButton_add"
            variant="contained"
            color="secondary"
            style={{
              fontSize: "20px",
              width: "212px",
              marginTop: "10px",
              marginLeft: "40px",
              padding: "12px 24px 12px 24px",
              borderRadius: "8px",
              textTransform: "none",
              gap: "10px",
              background: "white",
              boxShadow: "none",
              color: "rgba(31, 36, 46, 1)",
            }}
            onClick={() => window.open("https://routeeye.io/contact", "_blank")}
          >
            Contact Us
          </button>
        </div>
      </div>
    </>
  );
};

export default Login;