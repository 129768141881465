import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircleIcon from "@mui/icons-material/Circle";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Navigate, useNavigate } from "react-router";
import Pagination from "../../components/pagination/Pagination";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import "./role.css";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";

// Function to create data rows
function createData(id, name, description, features) {
  return { id, name, description, features };
}

// Define columns for the table
const columns = [
  { label: "Role Name", sortable: false, id: "Role Name" },
  { label: "Description", sortable: false, id: "Description" },
  { label: "Access", sortable: true, id: "Access" },
  { label: "Actions", sortable: false, id: "Actions" },
];
// Function to format rows from API response
const getRows = (rows) =>
  rows.map((item) =>
    createData(
      item.id,
      item.name,
      item.description,
      Array.isArray(item.features) ? item.features.map((f) => f.feature) : [] // Check if item.features is an array
    )
  );

// Component for displaying route schedules table
function RoleTable({ roleList, updateRole, deleteRole }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editableRowId, setEditableRowId] = useState(null);
  const [editedFeature, setEditedFeature] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setitemPerPage] = useState(10);
  const [loading, setLoading] = useState(false); // Loading state variable

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleOpenMenu = (event, id) => {
    event.preventDefault();
    setSelectedRoleId(id);
    setAnchorEl(event.currentTarget);
    console.log("selected role id", selectedRoleId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  // handling delete modal open
  const openDeleteModal = (id) => {
    id = selectedRoleId;

    console.log("this delet ", id);

    // setSelectedStopId(selectedStopId);
    setIsDeleteModalOpen(true);
    setAnchorEl(null);
  };
  // handling delete modal dailog close
  const handleDeleteDialogClose = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDelete = (id) => {
    id = selectedRoleId;
    console.log("id to delete role", id);
    setIsDeleteModalOpen(false);
    setAnchorEl(null);
    setLoading(true); // Start loading before deletion
    deleteRole(id)
      .then(() => {
        setLoading(false); // Stop loading after successful deletion
        navigate("/role");
        window.location.reload();
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log("Unauthorized request");
          navigate("/");
          return;
        }
        console.error("Error deleting role:", error);
        setLoading(false); // Stop loading on error
      });
  };
  //  Handles the click event to make a row editable.
  const handleEditClick = (row) => {
    setEditableRowId(row.id);
    setEditedFeature({ ...row });
    setAnchorEl(null);
  };
  const handleCancelClick = () => {
    setEditableRowId(null);
    setEditedFeature({});
  };
  // Handles the click event to save the edited feature.
  const handleSaveClick = (id) => {
    updateRole(editedFeature, id)
      .then(() => {
        setEditableRowId(null);
        setEditedFeature({});
        window.location.reload();
      })
      .catch((error) => console.error("Error updating feature:", error));
  };
  // Handles input changes for the editable fields.
  const handleInputChange = (e, field) => {
    setEditedFeature({ ...editedFeature, [field]: e.target.value });
  };
  // on click to navigate to  role access management with the role id
  const manageAccess = (id) => {
    id = selectedRoleId;
    console.log("id to manage access", selectedRoleId);
    navigate("/users/Role/:Id", { state: { id } });
  };

  // Inline styles for header
  const styles = {
    header: {
      backgroundColor: theme.palette.primary.main,
      background: "#20A8E0",
      color: "white",
      fontSize: "18px",
    },
  };
  // calculating pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const totalCount = parseInt(roleList?.length);
  const totalPages = Math.ceil(totalCount / itemsPerPage) || 1;
  // handle pagination page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Ensure roleList is an array before mapping over it
  const rows = Array.isArray(roleList)
    ? roleList.slice(startIndex, endIndex)
    : [];

  return (
    <>

      <TableContainer component={Paper} >
        <Table sx={{ minWidth: 650, }} aria-label="simple table" size="large">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  style={styles.header}
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className='TableBody-root'>
            {getRows(rows).map((row) => (    // Map over rows to render table data 
              <TableRow
                className="MuiTableRow-root"
                style={{
                  color: "black",
                }}

                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell style={{ paddingLeft: '15px' }}>

                  {editableRowId === row.id ? (
                    <TextField
                      value={editedFeature.name}
                      onChange={(e) => handleInputChange(e, 'name')}
                    />
                  ) : (
                    row.name
                  )}
                </TableCell>
                <TableCell style={{ paddingLeft: '15px' }}>
                  {editableRowId === row.id ? (
                    <TextField
                      value={editedFeature.description}
                      onChange={(e) => handleInputChange(e, 'description')}
                    />
                  ) : (
                    row.description
                  )}
                </TableCell>
                <TableCell style={{ paddingLeft: '15px' }}>
                  <Tooltip title={row.features.map(feature => feature.name).join(", ")}>
                    <ul style={{ listStyleType: 'none', }}>
                      {row.features.slice(0, 2).map((feature, index) => (
                        <li key={index}>{feature.name}</li>
                      ))}
                    </ul>
                  </Tooltip>
                </TableCell>
                <TableCell style={{ paddingLeft: '15px' }}>
                  {editableRowId === row.id ? (
                    <div>
                      <Button
                        onClick={() => handleSaveClick(row.id)}
                        sx={{
                          backgroundColor: '#03a9f4', // Matches the blue button in the image
                          color: 'white',
                          minWidth: '20px',
                          minHeight: '20px',
                          width: '20px',
                          height: '24px',
                          '&:hover': {
                            backgroundColor: '#0288d1', // Slightly darker on hover
                          },
                        }}
                        variant="contained"
                      >
                        <CheckIcon style={{ width: '20px', height: '20px' }} />
                      </Button>

                      <Button
                        onClick={handleCancelClick}
                        sx={{
                          backgroundColor: 'transparent',
                          color: 'red',
                          width: '24px',
                          height: '24px',
                          border: '2px solid red',
                          minWidth: '20px',
                          minHeight: '20px',
                          marginLeft: '20px',
                          borderRadius: '50%',
                          '&:hover': {
                            backgroundColor: 'rgba(255,0,0,0.1)',
                          },
                        }}
                      >
                        <CloseIcon />
                      </Button>
                    </div>

                  ) : (
                    <IconButton
                      aria-label="action-btn"
                      id="action-btn"
                      onClick={(event) => handleOpenMenu(event, row.id)}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                  {anchorEl && selectedRoleId === row.id && (
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleCloseMenu}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                      PaperProps={{ style: { backgroundColor: "white", borderRadius: "8px", boxShadow: "none" } }}
                    >
                      <MenuItem onClick={() => handleEditClick(row)}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(32, 168, 224, 1)", color: "rgba(255, 255, 255, 1)"
                            // Light blue hover effect
                          },
                        }}>

                        <ListItemIcon>
                          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.15659 3.18652H2.59035C2.16857 3.18652 1.76405 3.35408 1.4658 3.65233C1.16755 3.95058 1 4.35509 1 4.77688V15.9093C1 16.3311 1.16755 16.7356 1.4658 17.0339C1.76405 17.3321 2.16857 17.4997 2.59035 17.4997H13.7228C14.1446 17.4997 14.5491 17.3321 14.8474 17.0339C15.1456 16.7356 15.3132 16.3311 15.3132 15.9093V10.3431" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14.1189 1.99406C14.4352 1.67772 14.8643 1.5 15.3116 1.5C15.759 1.5 16.1881 1.67772 16.5044 1.99406C16.8208 2.3104 16.9985 2.73945 16.9985 3.18682C16.9985 3.6342 16.8208 4.06325 16.5044 4.37959L8.95024 11.9338L5.76953 12.7289L6.56471 9.54824L14.1189 1.99406Z" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                        </ListItemIcon>
                        Update
                      </MenuItem>
                      <MenuItem onClick={manageAccess}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(32, 168, 224, 1)", color: "rgba(255, 255, 255, 1)"
                            // Light blue hover effect
                          },
                        }}>
                        <ListItemIcon>
                          <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.66536 6.83366C4.43334 6.83263 5.17747 6.56683 5.77229 6.08106C6.36712 5.59529 6.77624 4.91927 6.9307 4.16699H8.33203V5.50033H9.66537V4.16699H10.9987V6.16699H12.332V4.16699H12.9987V2.83366H6.9307C6.77624 2.08138 6.36712 1.40536 5.77229 0.919592C5.17747 0.433824 4.43334 0.168019 3.66536 0.166992C1.82736 0.166992 0.332031 1.66233 0.332031 3.50033C0.332031 5.33833 1.82736 6.83366 3.66536 6.83366ZM3.66536 1.50033C4.76803 1.50033 5.66536 2.39766 5.66536 3.50033C5.66536 4.60299 4.76803 5.50033 3.66536 5.50033C2.5627 5.50033 1.66536 4.60299 1.66536 3.50033C1.66536 2.39766 2.5627 1.50033 3.66536 1.50033Z" fill="#1F242E" />
                          </svg>
                        </ListItemIcon>
                        Manage access
                      </MenuItem>
                      <MenuItem onClick={() => openDeleteModal(row.id)}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(32, 168, 224, 1)", color: "rgba(255, 255, 255, 1)"
                            // Light blue hover effect
                          },
                        }}>
                        <ListItemIcon>
                          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 4.40039H2.77778H17" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.2218 4.4V16.3C15.2218 16.7509 15.0345 17.1833 14.7011 17.5021C14.3677 17.8209 13.9155 18 13.444 18H4.55512C4.08363 18 3.63144 17.8209 3.29804 17.5021C2.96464 17.1833 2.77734 16.7509 2.77734 16.3V4.4M5.44401 4.4V2.7C5.44401 2.24913 5.63131 1.81673 5.96471 1.49792C6.29811 1.17911 6.75029 1 7.22179 1H10.7773C11.2488 1 11.701 1.17911 12.0344 1.49792C12.3678 1.81673 12.5551 2.24913 12.5551 2.7V4.4" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.22266 8.65039V13.7504" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.7773 8.65039V13.7504" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                        </ListItemIcon>
                        Delete
                      </MenuItem>
                    </Menu>
                  )}
                </TableCell>
              </TableRow>
            )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />

      <Dialog
        open={isDeleteModalOpen}
        onClose={handleDeleteDialogClose}
        PaperProps={{
          style: {
            backgroundColor: "white",
            width: "594px",
            maxWidth: "600px",
            height: {
              xs: "auto",
              md: "294px", 
            },
          },
        }}
      >
        <DialogTitle style={{ fontWeight: "bolder", height: "80px" }}>
          <span
            style={{
              color: "#EE3B2B",
              width: "514px",
              fontSize: "32px",
              fontWeight: "600",
            }}
          >
            Delete User Role!
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              color: "rgba(58, 58, 58, 1)",
              // width: "514px",
              fontSize: "24px",
              fontWeight: "400",
              marginTop: "-6px",
            }}
          >
            Do you want to proceed?
          </DialogContentText>
        </DialogContent>

        <div
          // style={{
          //   marginLeft: "100px",
          //   marginRight: "108px",
          //   marginBottom: "40px",
          // }}
          className="responsive-container"
        >
          <DialogActions>
            <button
              className="customButton_add"
              onClick={handleDeleteDialogClose}
              color="primary"
              style={{
                padding: "10px 40px",
                fontWeight: "bold",
                textTransform: "none",
                background: "#F2F2F3",
                marginRight: "50px",
              }}
            >
              Cancel
            </button>
            <button
              className="customButton_add"
              color="primary"
              variant="contained"
              autoFocus
              onClick={handleDelete}
              style={{
                padding: "10px 40px",
                fontWeight: "bold",
                textTransform: "none",
                background: "#EE3B2B",
                color: "white",
              }}
            >
              Delete
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}

export default RoleTable; // Exporting the RouteSchedulesTable component as default
