import { Dashboard } from '@mui/icons-material';
import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react'
import DashboardHeader from "../../../components/header/DashboardHeader";
import RouteSchedulesTable from './RouteSchedulesTable';
import RouteSchedulesHeader from './RouteSchedulesHeader';
import { useLocation, useNavigate } from 'react-router';
import { route_base_url } from '../../stops/utils';
import axios from 'axios';

// Functional component for displaying route schedules
function RouteSchedules() {
	const location=useLocation();
	const navigate=useNavigate();
	const id=location.state.id
	const [routeSchedules,setRouteSchedules]=useState([]);

	useEffect(()=>{
		async function fetchSchedules (){
			try{
				if(id){
					const response= await axios.get(`${route_base_url}/id`,{
						params:{
							id:id
						}
					})
					console.log("route for schedules ",response.data)
					setRouteSchedules(response.data);
				}
	
			}catch(error){
				if (error.response && error.response.status === 401) {
					console.log("Unauthorized request");
					navigate("/");
					return;
				  }
				console.log("error in fetching route",error)
	
			}
		}
		fetchSchedules();
	},[])
  return (
	<>
	<DashboardHeader title="Routes Schedules"/>  {/* Dashboard header component with title */}
	<div className='page_container'>
		<RouteSchedulesHeader routeSchedules={routeSchedules}/>  {/* rendering Header for route schedules */}
		<RouteSchedulesTable routeSchedules={routeSchedules}/>   {/* rendering Table for displaying route schedules */}
	</div>
	</>
  )
}


export default RouteSchedules    // Exporting the RouteSchedules component as default