import { Grid, MenuItem, Select, Typography, useMediaQuery } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import HomeHeaderDashboard from "../../dashboard/HomeHeaderDashboard";
import { useLocation, useNavigate } from "react-router-dom";

import {
  StyledFormButton,
  StyledFormControl,
  StyledFormInput,
  StyledFormInputLabel,
  StyledPaper,
} from "../../../components/formComponents";
import {
  contactFormFields,
  getTransformedFormData,
  getTransformedPayload,
  transportFormFields,
  orgFormFields,
  org_base_url,
} from "../utils";
import "../addOrganization/AddOrganization.css";
import useAdd from "../../../hooks/useAdd";
import useUpdate from "../../../hooks/useUpdate";
import Sidebar from "../../../components/navbar/Sidebar";
import config from "../../../config.json";

async function registedOrganization(formData) {
  try {
    const response = await axios.post(`${config.baseURL}/register`, formData);
    console.log("response", response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export default function RegisterOrganization() {
  const location = useLocation();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [formData, setFormData] = useState({ organizationStatus: "INACTIVE" });
  const navigate = useNavigate();
  const { addEntity } = useAdd(org_base_url);
  const { updateEntity } = useUpdate(org_base_url);
  const [errors, setErrors] = useState({});
  const [showSummaryError, setShowSummaryError] = useState(false);

  useEffect(() => {
    const fetchOrgDataById = async (id) => {
      try {
        const updateUrl = `${org_base_url}/${id}`;
        const response = await axios.get(updateUrl);
        const transformedFormData = getTransformedFormData(
          response.data.organization
        );
        setFormData(transformedFormData);
      } catch (error) {
        console.log(error);
      }
    };
    if (location.state) {
      fetchOrgDataById(location.state);
    }
  }, [location.state]);

  const handleInputValue = (e, fieldName) => {
    const clonedFormData = { ...formData };
    const inputValue = e.target.value;
    clonedFormData[fieldName] = inputValue;
    setFormData(clonedFormData);

    // Clear error for this field when user starts typing
    if (errors[fieldName]) {
      setErrors((prev) => ({ ...prev, [fieldName]: "" }));
    }
  };
  const validateForm = () => {
    const newErrors = {};
    [...orgFormFields, ...contactFormFields].forEach((field) => {
      if (field.isRequired !== false && !formData[field.fieldName]) {
        newErrors[field.fieldName] = "This field is required";
      }
    });
    setErrors(newErrors);
    setShowSummaryError(Object.keys(newErrors).length > 0);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const transformedPayload = getTransformedPayload(formData);

    if (location.state) {
      const { responseData } = await updateEntity(
        { ...transformedPayload, id: location.state },
        location.state
      );

      if (responseData?.data?.organization) {
        navigate("/Organization");
      }
    } else {
      const response = await registedOrganization(transformedPayload);

      if (response) {
        navigate("/");
      }
    }
  };

  const handleCancel = () => {
    navigate("/");
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        {isDesktop && <Sidebar />}
        <div style={{ flexGrow: 1, background: 'white' }}>
          <div className="hide-icon">
            <HomeHeaderDashboard title="Organizations > Register" />
          </div>
          <div style={{ margin: '32px', background: 'white', borderRadius: '8px' }}>

            <div className="form_header" style={{ marginTop: '0px' }}>
              Organization Details
            </div>
            <Grid container spacing={2}>
              {orgFormFields
                .filter((item) => item.label !== "Organization Status")
                .map((item) => (
                  <Grid item xs={12} sm={6} key={item.id}>
                    <FormFieldWrapper
                      item={item}
                      handleInputValue={handleInputValue}
                      formData={formData}
                      error={errors[item.fieldName]}
                    />
                  </Grid>
                ))}
            </Grid>

            <div className="form_header">Contact Details</div>
            <Grid container spacing={2}>
              {contactFormFields.map((item) => (
                <Grid item xs={12} sm={6} key={item.id}>
                  <FormFieldWrapper
                    item={item}
                    handleInputValue={handleInputValue}
                    formData={formData}
                    error={errors[item.fieldName]}
                  />
                </Grid>
              ))}
            </Grid>

            <div className="form_header">Transport In-Charge Details (Optional)</div>
            <div className="no-asterisk">
              <Grid container spacing={2}>
                {transportFormFields.map((item) => (
                  <Grid item xs={12} sm={6} key={item.id}>
                    <FormFieldWrapper
                      item={item}
                      handleInputValue={handleInputValue}
                      formData={formData}
                    />
                  </Grid>
                ))}

              </Grid>
              {showSummaryError && (
                <Typography
                  color="error"
                  style={{
                    marginTop: "20px",
                    marginBottom: "10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Please fill all the mandatory fields
                </Typography>
              )}
            </div>
            <div className="addbutton_container" style={{ marginTop: '32px' }}>
              <button
                variant="outlined"
                onClick={handleCancel}
                className="customButton_add"
                style={{ background: 'rgba(242, 242, 243, 1)' }}
              >
                Cancel
              </button>
              <button
                variant="contained"
                onClick={handleSubmit}
                className="customButton_add"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const FormFieldWrapper = ({ handleInputValue, item, formData, error }) => {
  return (
    <StyledFormControl
      variant="standard"
      fullWidth
      required={item.isRequired !== false}
      error={!!error}
    >
      <StyledFormInputLabel shrink htmlFor={item.id}>
        {item.label}
      </StyledFormInputLabel>
      {item.type === "select" ? (
        <Select
          id={item.id}
          value={formData[item.fieldName]}
          onChange={(e) => handleInputValue(e, item.fieldName)}
          input={<StyledFormInput />}
        >
          <MenuItem value="ACTIVE">Active</MenuItem>
          <MenuItem value="INACTIVE">Inactive</MenuItem>
        </Select>
      ) : (
        <StyledFormInput
          id={item.id}
          onChange={(e) => handleInputValue(e, item.fieldName)}
          value={formData[item.fieldName] || ""}
          required={item.isRequired !== false}
          error={!!error}
          helperText={error}
        />
      )}
    </StyledFormControl>
  );
};
