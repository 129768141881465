import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { loadOptions } from "../../stops/mapConfig";
const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 13.025,
  lng: 77.628,
};

// Icons for origin, destination, and stops
const originIcon = {
  url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
  scaledSize: { width: 40, height: 40 },
};

const destinationIcon = {
  url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
  scaledSize: { width: 40, height: 40 },
};

const stopIcon = {
  url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
  scaledSize: { width: 30, height: 30 },
};

const intermediateIcon = {
  url: "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
  scaledSize: { width: 20, height: 20 },
};

function AddRouteMap({
  origin,
  destination,
  routePoints,
  routeType,
  onAddRoutePoint,
}) {
  const [directions, setDirections] = useState([]);
  const [mapInstance, setMapInstance] = useState(null);
  const { isLoaded, loadError } = useJsApiLoader(loadOptions);
  const [errorMessage, setErrorMessage] = useState("");

  const directionsService = useRef(null);

  const onLoad = useCallback(function callback(map) {
    setMapInstance(map);
    directionsService.current = new window.google.maps.DirectionsService();
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMapInstance(null);
    directionsService.current = null;
  }, []);

  const handleMapClick = useCallback(
    (e) => {
      try {
        const clickedLat = e.latLng.lat();
        const clickedLng = e.latLng.lng();

        let insertIndex = 0;
        let minDistance = Infinity;

        let allPoints = [
          { lat: Number(origin?.latitude), lng: Number(origin?.longitude) },
          ...routePoints,
          { lat: Number(destination?.latitude), lng: Number(destination?.longitude) },
        ].filter(point => point.lat && point.lng);

        for (let i = 0; i < allPoints.length - 1; i++) {
          const start = new window.google.maps.LatLng(
            allPoints[i].lat,
            allPoints[i].lng
          );
          const end = new window.google.maps.LatLng(
            allPoints[i + 1].lat,
            allPoints[i + 1].lng
          );
          const clickedPoint = new window.google.maps.LatLng(
            clickedLat,
            clickedLng
          );

          const distance =
            window.google.maps.geometry.spherical.computeDistanceBetween(
              clickedPoint,
              new window.google.maps.LatLng(
                (start.lat() + end.lat()) / 2,
                (start.lng() + end.lng()) / 2
              )
            );

          if (distance < minDistance) {
            minDistance = distance;
            insertIndex = i;
          }
        }

        const newPoint = {
          lat: clickedLat,
          lng: clickedLng,
          isStop: false,
          name: `Intermediate ${insertIndex}`,
        };
        onAddRoutePoint(newPoint, insertIndex);
      }
      catch (error) {
        console.error("Error handling map click:", error);
        setErrorMessage("An error occurred. Please refresh the page.");
      }
    },
    [onAddRoutePoint, routePoints, origin, destination]
  );

  useEffect(() => {
    if (isLoaded && directionsService.current && origin && destination) {
      const waypoints = routePoints.map((point) => ({
        location: { lat: point.lat, lng: point.lng },
        stopover: point.isStop
      }));

      const calculateRoute = async () => {
        try {
          const request = {
            origin: {
              lat: Number(origin.latitude),
              lng: Number(origin.longitude)
            },
            destination: {
              lat: Number(destination.latitude),
              lng: Number(destination.longitude)
            },
            waypoints: waypoints,
            travelMode: window.google.maps.TravelMode.DRIVING,
            optimizeWaypoints: false
          };

          const result = await new Promise((resolve, reject) => {
            directionsService.current.route(request, (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                resolve(result);
              } else {
                reject(status);
              }
            });
          });

          setDirections([result]);
        } catch (status) {
          console.error(`Directions request failed due to ${status}`);
          setDirections([]);
        }
      };

      calculateRoute();
    }
  }, [isLoaded, origin, destination, routePoints]);

  const fitBounds = useCallback(() => {
    if (mapInstance && origin && destination) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(
        new window.google.maps.LatLng(
          Number(origin.latitude),
          Number(origin.longitude)
        )
      );
      bounds.extend(
        new window.google.maps.LatLng(
          Number(destination.latitude),
          Number(destination.longitude)
        )
      );
      routePoints.forEach((point) => {
        bounds.extend(new window.google.maps.LatLng(point.lat, point.lng));
      });
      mapInstance.fitBounds(bounds);
    }
  }, [mapInstance, origin, destination, routePoints]);

  useEffect(() => {
    if (isLoaded && mapInstance) {
      fitBounds();
    }
  }, [isLoaded, mapInstance, fitBounds]);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <>
      {errorMessage && (
        <div style={{ color: "red", margin: "20px 0", textAlign: "center" }}>
          {errorMessage}
        </div>
      )}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={handleMapClick}
      >
        {origin && (
          <Marker
            position={{
              lat: Number(origin.latitude),
              lng: Number(origin.longitude),
            }}
            icon={originIcon}
            label={origin.name}
            title={origin.name}
          />
        )}
        {destination && (
          <Marker
            position={{
              lat: Number(destination.latitude),
              lng: Number(destination.longitude),
            }}
            icon={destinationIcon}
            title={destination.name}
          />
        )}
        {routePoints.map((point, index) => (
          <Marker
            key={index}
            position={{ lat: point.lat, lng: point.lng }}
            icon={point.isStop ? stopIcon : intermediateIcon}
            title={point.name}
          />
        ))}
        {Array.isArray(directions) && directions.map((direction, index) => (
          <DirectionsRenderer
            key={index}
            directions={direction}
            options={{
              suppressMarkers: true,
              preserveViewport: true,
            }}
          />
        ))}
      </GoogleMap>
    </>
  );
}

export default React.memo(AddRouteMap);