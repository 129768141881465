import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config.json";
import DashboardHeader from "../../components/header/DashboardHeader";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import MailIcon from "@mui/icons-material/Mail";
import Checkbox from "@mui/material/Checkbox";
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";


// Functions to fetch alerts
async function getUserAlerts() {
  const response = await axios.get(`${config.baseURL}/alert/organization/user`, {
    withCredentials: true,
  });
  return response.data;
}

async function getVehicleAlerts() {
  const response = await axios.get(`${config.baseURL}/alert/organization/vehicle`, {
    withCredentials: true,
  });
  return response.data;
}

async function getExpiryAlerts() {
  const response = await axios.get(`${config.baseURL}/alert/organization/expiry`, {
    withCredentials: true,
  });
  return response.data;
}

const Alert = () => {
  const [userAlerts, setUserAlerts] = useState([]);
  const [vehicleAlerts, setVehicleAlerts] = useState([]);
  const [expiryAlerts, setExpiryAlerts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [routeData, setRouteData] = useState({ routeType: "BI_DIRECTIONAL" });
  const [searchTerm, setSearchTerm] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteAlertId, setDeleteAlertId] = useState(null);
  const [selectedAlerts, setSelectedAlerts] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    vehicle: "all",
    status: "all",
    startDate: "",
    endDate: "",
    sortBy: "dateAsc",
  });

  const toggleSortDirection = () => {
    setSelectedFilters((prev) => ({
      ...prev,
      sortBy: prev.sortBy === "asc" ? "desc" : "asc",
    }));
  };

  const getFilteredVehicleAlerts = () => {
    const filtered = vehicleAlerts
      .filter((alert) =>
        selectedFilters.vehicle === "all"
          ? true
          : alert.vehicleId === selectedFilters.vehicle
      )
      .filter((alert) =>
        selectedFilters.status === "all" ? true : alert.status === selectedFilters.status
      )
      .filter((alert) =>
        selectedFilters.startDate
          ? new Date(alert.date) >= new Date(selectedFilters.startDate)
          : true
      )
      .filter((alert) =>
        selectedFilters.endDate
          ? new Date(alert.date) <= new Date(selectedFilters.endDate)
          : true
      );
    return selectedFilters.sortBy === "dateAsc"
      ? filtered.sort((a, b) => new Date(a.date) - new Date(b.date))
      : filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
  };


  useEffect(() => {
    const fetchAlerts = async () => {
      const [user, vehicle, expiry] = await Promise.all([
        getUserAlerts(),
        getVehicleAlerts(),
        getExpiryAlerts()
      ]);
      setUserAlerts(user);
      setVehicleAlerts(vehicle);
      setExpiryAlerts(expiry);
    };
    fetchAlerts();
    const intervalId = setInterval(fetchAlerts, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const handleRouteTypeChange = (event) => {
    setRouteData({ ...routeData, routeType: event.target.value });
  };

  const getVisibleAlerts = () => {
    const alertsByCategory = (() => {
      switch (selectedCategory) {
        case "Users":
          return userAlerts;
        case "Vehicles":
          return vehicleAlerts;
        case "Due-date Alerts ":
          return expiryAlerts;
        case "All":
        default:
          // Combine all alerts and filter out duplicates by ID
          const combinedAlerts = [...userAlerts, ...vehicleAlerts, ...expiryAlerts];
          return combinedAlerts.filter((alert, index, self) =>
            index === self.findIndex((a) => a.id === alert.id)
          );
      }
    })();

    // Filter alerts based on search term
    return alertsByCategory.filter(alert =>
      alert.subject.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  

  const openModal = (alert) => {
    setSelectedAlert(alert);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedAlert(null);
  };

  const getCount = (category) => {
    switch (category) {
      case "All":
        return userAlerts.length + vehicleAlerts.length + expiryAlerts.length;
      case "Vehicles":
        return vehicleAlerts.length;
      case "Users":
        return userAlerts.length;
      case "Due-date Alerts ":
        return expiryAlerts.length;
      default:
        return 0;
    }
  };

  
  // Open the delete modal
  const openDeleteModal = (alertId) => {
    setDeleteAlertId(alertId);  // Store the alert ID to delete
    setIsDeleteModalOpen(true);
  };

  // Close the delete modal
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeleteAlertId(null);  // Reset the alert ID
  };

  // Handle the delete confirmation
  const handleDeleteConfirm = () => {
    // You can call the API to delete the alert using the deleteAlertId
    console.log(`Deleting alert with ID: ${deleteAlertId}`);

    // Close the modal after deletion
    closeDeleteModal();
  };

  // Handle the delete cancellation
  const handleDeleteCancel = () => {
    closeDeleteModal();
  };
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    // Update individual checkbox states based on "select all"
    const updatedSelections = {};
    getVisibleAlerts().forEach(alert => {
      updatedSelections[alert.id] = newSelectAll;
    });
    setSelectedAlerts(updatedSelections);
  };

  const handleCheckboxChange = (alertId) => {
    setSelectedAlerts(prev => ({
      ...prev,
      [alertId]: !prev[alertId]
    }));
  };

  


  return (
    <>
      <DashboardHeader title="Alerts" />
      <div className="page_container">
        <div className="search-filter-wrapper" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center", background: "#f0f0f0", borderRadius: "5px", padding: "5px 10px" }}>
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ border: "none", outline: "none", marginLeft: "5px", background: 'rgba(242, 242, 243, 1)' }}
            />
            <SearchIcon style={{ color: "rgba(24, 48, 89, 1)" }} />
          </div>
          <FormControl component="fieldset">
            <RadioGroup
              row
              value={routeData.routeType}
              onChange={handleRouteTypeChange}
              size="larger"
            >
              <FormControlLabel
                value="ALL"
                control={<Radio style={{ color: routeData.routeType === "ALL" ? "rgba(249, 231, 25, 1)" : "rgba(242, 242, 243, 1)" }} />}
                label={<Typography sx={{ fontWeight: "bolder", color: routeData.routeType === "ALL" ? "rgba(249, 231, 25, 1)" : "rgba(242, 242, 243, 1)" }}>All</Typography>}
              />
              <FormControlLabel
                value="BI_DIRECTIONAL"
                control={<Radio style={{ color: routeData.routeType === "BI_DIRECTIONAL" ? "rgba(249, 231, 25, 1)" : "rgba(242, 242, 243, 1)" }} />}
                label={<Typography sx={{ fontWeight: "bolder", color: routeData.routeType === "BI_DIRECTIONAL" ? "rgba(249, 231, 25, 1)" : "rgba(242, 242, 243, 1)" }}>Read</Typography>}
              />
              <FormControlLabel
                value="SINGLE_DIRECTIONAL"
                control={<Radio style={{ color: routeData.routeType === "SINGLE_DIRECTIONAL" ? "rgba(249, 231, 25, 1)" : "rgba(242, 242, 243, 1)" }} />}
                label={<Typography sx={{ fontWeight: "bolder", color: routeData.routeType === "SINGLE_DIRECTIONAL" ? "rgba(249, 231, 25, 1)" : "rgba(242, 242, 243, 1)" }}>Unread</Typography>}
              />

            </RadioGroup>
          </FormControl>
        </div>

        {/* Category Buttons with Counts */}
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', marginTop: '10px',border:'2px solid rgba(183, 191, 204, 1)',borderTop:'none',borderRadius:'8px'}}>
  {["All", "Vehicles", "Users", "Due-date Alerts "].map((category) => (
    <button
      key={category}
      onClick={() => setSelectedCategory(category)}
      style={{
        flexGrow: 1, 
        padding: '10px 20px',
        backgroundColor: selectedCategory === category ? '#00a0df' : 'white',
        color: selectedCategory === category ? '#fff' : '#333',
        border: 'none',
        cursor: 'pointer',
        fontWeight: 'bold',
        borderRadius: '5px',
        minWidth: '100px', 
        borderRight: '2px solid rgba(183, 191, 204, 1)'
      }}
    >
      {category.charAt(0).toUpperCase() + category.slice(1)} ({getCount(category)})
    </button>
  ))}
</div>

 {/* Filters for Vehicles */}

 {selectedCategory === "Vehicles" && (
          <div
            style={{
              marginBottom: "20px",
              padding: "20px",
              background: "rgba(100, 116, 144, 1)",
              borderRadius: "8px",
              border: "1px solid #ccc",
              
            }}
          >
          <div
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "4px",
    color: "white",
  }}
>
  {/* Left Section: Dropdowns */}
  <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
    {/* Vehicle Dropdown */}
    <select
      value={selectedFilters.vehicle}
      onChange={(e) =>
        setSelectedFilters((prev) => ({ ...prev, vehicle: e.target.value }))
      }
      style={{
        padding: "8px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        background: "rgba(242, 242, 243, 1)",
        color: "rgba(31, 36, 46, 1)",
        fontSize: "14px",
        minWidth: "120px",
      }}
    >
      <option value="all">Vehicle</option>
      <option value="vehicle1">Vehicle 1</option>
      <option value="vehicle2">Vehicle 2</option>
    </select>

    {/* Status Dropdown */}
    <select
      value={selectedFilters.status}
      onChange={(e) =>
        setSelectedFilters((prev) => ({ ...prev, status: e.target.value }))
      }
      style={{
        padding: "8px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        background: "rgba(242, 242, 243, 1)",
        color: "rgba(31, 36, 46, 1)",
        fontSize: "14px",
        minWidth: "120px",
      }}
    >
      <option value="all">Status</option>
      <option value="active">Active</option>
      <option value="inactive">Inactive</option>
      <option value="Dead">Dead</option>
    </select>

    {/* Date Range Picker */}
    <div
      style={{
        padding: "8px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        background: "rgba(242, 242, 243, 1)",
        color: "rgba(31, 36, 46, 1)",
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
        gap: "5px",
      }}
    >
      <input
        type="date"
        value={selectedFilters.startDate}
        onChange={(e) =>
          setSelectedFilters((prev) => ({ ...prev, startDate: e.target.value }))
        }
        style={{
          border: "none",
          background: "transparent",
          fontSize: "14px",
          color: "rgba(31, 36, 46, 1)",
        }}
      />
      <span>-</span>
      <input
        type="date"
        value={selectedFilters.endDate}
        onChange={(e) =>
          setSelectedFilters((prev) => ({ ...prev, endDate: e.target.value }))
        }
        style={{
          border: "none",
          background: "transparent",
          fontSize: "14px",
          color: "rgba(31, 36, 46, 1)",
        }}
      />
    </div>
  </div>

  {/* Right Section: Sortable Date Button */}
  <button
    onClick={toggleSortDirection}
    style={{
      display: "flex",
      alignItems: "center",
      textTransform: "none",
      padding: "8px 12px",
      backgroundColor: "rgba(242, 242, 243, 1)",
      color: "rgba(31, 36, 46, 1)",
      border: "1px solid #ccc",
      borderRadius: "4px",
      boxShadow: "none",
      fontSize: "14px",
      cursor: "pointer",
    }}
  >
    Date
    {selectedFilters.sortBy === "asc" && (
      <ArrowUpwardIcon style={{ fontSize: 16, marginLeft: "5px" }} />
    )}
    {selectedFilters.sortBy === "desc" && (
      <ArrowDownwardIcon style={{ fontSize: 16, marginLeft: "5px" }} />
    )}
  </button>
</div>

          </div>
        )}


<div className="search-filter-wrappers" style={{ background: 'rgba(100, 116, 144, 1)', display: 'flex', justifyContent: "space-between", padding: '16px 24px', marginTop: '10px', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ color: "#fff" }}>Alerts</Typography>
          </div>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginLeft: 'auto', marginRight: '32px' }}>
            <Typography style={{ color: "#fff" }}>Time</Typography>
          </div>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Checkbox
              style={{ color: "#fff", cursor: 'pointer' }}
              checked={selectAll}
              onChange={handleSelectAll}
            />
            <DeleteIcon style={{ color: "#fff", cursor: 'pointer' }} />
            <MailIcon style={{ color: "#fff", cursor: 'pointer' }} />
          </div>
        </div>

        {/* Alerts List with Checkboxes, Update, and Delete Icons */}
        <div className="alerts-list">
          {getVisibleAlerts().map((alert) => (
            <div key={alert.id} className="alert-item" onClick={() => openModal(alert)}>
              <div className="alert-details">
                <p className="alert-subject"><strong></strong> {alert.subject}</p>
              </div>
              <div className="alert-time" onClick={(e) => e.stopPropagation()}>
                <p><strong></strong> {new Date(alert.createdAt).toLocaleString()}</p>
                <Checkbox
                  style={{ cursor: "pointer" }}
                  checked={!!selectedAlerts[alert.id]}
                  onChange={() => handleCheckboxChange(alert.id)}
                />
                <DeleteIcon
                  style={{ cursor: "pointer", color: "red" }}
                  onClick={(e) => { e.stopPropagation(); openDeleteModal(alert.id); }} // Prevent modal from opening on delete click
                />
              </div>
            </div>
          ))}
        </div>
        {/* Modal for Alert Details */}
        {modalIsOpen && selectedAlert && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={closeModal}>&times;</span>
              <h2>{selectedAlert.subject}</h2>
              {/* Add other alert details here */}
            </div>
          </div>
        )}

        <style jsx>{`
          .modal {
            display: block;
            position: fixed;
            z-index: 1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: rgba(0,0,0,0.4);
            padding-top: 60px;
          }
          .modal-content {
            background-color: #fefefe;
            margin: 5% auto;
            padding: 20px;
            border: 1px solid #888;
            width: 80%;
          }
          .close {
            color: #aaa;
            float: right;
            font-size: 28px;
            font-weight: bold;
          }
          .close:hover,
          .close:focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
          }
        `}
        </style>
        <Dialog
          open={isDeleteModalOpen}
          onClose={closeDeleteModal}
          PaperProps={{
            style: {
              backgroundColor: "white",
              width: "594px",
              maxWidth: "600px",
              height: "294px"
            },
          }}
        >
          <DialogTitle style={{ fontWeight: "bolder", height: "80px", marginTop: "5px" }}>
            <span style={{
              color: "#EE3B2B",
              width: "514px",
              fontSize: "32px",
              fontWeight: "600",
            }}>
              Delete Alert!
            </span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{
                color: "#1F242E",
                fontSize: "24px",
                fontWeight: "400",
              }}
            >
              Do you want to proceed ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              onClick={handleDeleteCancel}
              color="primary"
              style={{
                padding: "10px 40px",
                fontWeight: "bold",
                textTransform: "none",
                background: "#F2F2F3",
                marginBottom: '20px'
              }}
              className="customButton_add"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteConfirm}
              color="primary"
              variant="contained"
              autoFocus
              className="customButton_add"
              style={{
                padding: "10px 40px",
                fontWeight: "bold",
                textTransform: "none",
                background: "#EE3B2B",
                color: "white",
                marginBottom: '20px',
                marginRight: '18%',
                marginLeft: '40px'

              }}
            >
              Delete
            </button>
          </DialogActions>
        </Dialog>


        {/* Modal for Alert Details */}
        <Dialog open={modalIsOpen} onClose={closeModal} maxWidth="sm" fullWidth>
          <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <NotificationsIcon style={{ color: 'rgba(100, 116, 144, 1)' }} />
            <DeleteIcon style={{ color: 'rgba(238, 59, 43, 1)', cursor: 'pointer' }} onClick={() => setIsDeleteModalOpen(true)} />
          </DialogTitle>
          <DialogContent style={{ textAlign: 'center' }}>
            <img src="" alt="Vehicle Icon" style={{ width: '80px', marginBottom: '20px' }} />
            <Typography variant="h6" gutterBottom style={{ fontWeight: 600, color: 'rgba(17, 18, 20, 1)' }}>

              Vehicle Alert: Speeding Detected
            </Typography>
            <Typography variant="body2" gutterBottom>
              Your vehicle with license plate ABC-123 has been detected exceeding the speed limit. Please ensure the safety of all road users, and kindly remind your driver to adhere to speed limits at all times.
            </Typography>
            <div style={{ display: 'flex' }}>
              <div style={{ textAlign: 'left', marginTop: '20px' }}>
                <Typography style={{ fontWeight: 600, color: 'rgba(17, 18, 20, 1)' }}><strong>Vehicle Number:</strong> #ABCD13651</Typography>
                <Typography style={{ fontWeight: 600, color: 'rgba(17, 18, 20, 1)' }}><strong>Date:</strong> 23 January 2024</Typography>
                <Typography style={{ fontWeight: 600, color: 'rgba(17, 18, 20, 1)' }}><strong>Time:</strong> 09:54 PM</Typography>
              </div>
              <div style={{ textAlign: 'left', marginTop: '20px', marginLeft: '60px' }}>
                <Typography style={{ fontWeight: 600, color: 'rgba(17, 18, 20, 1)' }}><strong>Route Name:</strong> 18/1, Habib Court</Typography>
                <Typography style={{ fontWeight: 600, color: 'rgba(17, 18, 20, 1)' }}><strong>Driver Name:</strong> Mohan Kalita</Typography>
                <Typography style={{ fontWeight: 600, color: 'rgba(17, 18, 20, 1)' }}><strong>Driver Contact:</strong> +91 7935384891</Typography>
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center', paddingBottom: '20px' }}>
            <Button onClick={closeModal} style={{ padding: '10px 30px', backgroundColor: '#f0f0f0', color: '#333', fontWeight: 'bold' }}>Cancel</Button>
            <Button style={{ padding: '10px 30px', backgroundColor: '#FFCD00', color: '#333', fontWeight: 'bold', marginLeft: '40px' }}>View</Button>
          </DialogActions>
        </Dialog>

      </div>
    </>
  );
};

export default Alert;
