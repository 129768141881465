import React, { useEffect, useState, Suspense } from 'react'
import { Button, CircularProgress, Paper } from "@mui/material";
import RoutesHeader from './RoutesHeader';
import Divider from "@mui/material/Divider";
import SearchRoutes from './SearchRoutes';
// import RoutesTable from './RoutesTable';
import DashboardHeader from '../../components/header/DashboardHeader';
import axios from 'axios';
import { route_base_url } from '../stops/utils';
import config from '../../config.json';
import { useNavigate } from 'react-router';

const RoutesTable = React.lazy(() => import("./RoutesTable"))
const Routess = () => {
  const [routesByOrganization, setRoutesByOrganization] = useState([])
  const [searchInput, setSearchInput] = useState("")
  const navigate = useNavigate();
  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
    console.log(searchInput)
  };

  // useEffect(()=>{
  //   async function fetchRoutes(){
  //     try{
  //       const routesResponse=await axios.get(`${route_base_url}/organization/`,{

  //       params:{

  //         organizationId:1
  //       } 
  //       })
  //       console.log("routes by organization",routesResponse.data)
  //       setRoutesByOrganization(routesResponse.data);
  //     } catch (error){
  //       console.log("error in fetching routes by organization",error)
  //     }
  //   }
  //   fetchRoutes();
  // },[])


  // useEffect(() => {
  //   async function fetchStopsByCoordinates() {
  //     try {
  //       // Loop through each route
  //       for (const route of routesByOrganization) {
  //         // Check if the route has coordinates

  //           // Loop through each coordinate in the route's routeCoordinates array
  //           for (const coordinate of route.routeCoordinates) {
  //             try {
  //               // Make API call to get stops by coordinates
  //               const stopsResponse = await axios.get(`http://localhost:3000/api/stop/organization/location`, {
  //                 params: {
  //                   organizationId: 1,
  //                   longitude: coordinate.longitude,
  //                   latitude: coordinate.latitude, 
  //                 }
  //               });
  //               console.log("stops response ",stopsResponse.data)

  //             } catch (error) {
  //               console.log("Error in fetching stops by coordinates", error);
  //               // Continue processing for next coordinate even if error occurs
  //               continue;
  //             }
  //           }

  //       }
  //     } catch (error) {
  //       console.log("Error in fetching stops by coordinates", error);
  //     }
  //   }

  //   fetchStopsByCoordinates();
  // }, [routesByOrganization]);

  useEffect(() => {
    async function fetchRoutes() {
      try {
        const routesResponse = await axios.get(`${config.baseURL}/route/organization`, {
          params: {
            organizationId: 1
          }, withCredentials: true
        });

        if (routesResponse.status === 401) {
          console.log("Unauthorized request");
          navigate("/");
          return;
        }
        console.log("routes by organization", routesResponse.data);
        const routesWithStops = await Promise.all(routesResponse.data.map(async (route) => {
          const stops = await fetchStopsForRoute(route);
          return { ...route, stops };
        }));
        console.log("routes with stops ", routesWithStops)
        console.log("routes with stops ", routesWithStops)
        setRoutesByOrganization(routesWithStops);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log("Unauthorized request");
          navigate("/");
          return;
        }
        console.log("error in fetching routes by organization", error);
      }
    }
    fetchRoutes();
  }, []);

  const fetchStopsForRoute = async (route) => {
    const stops = [];
    for (const coordinate of route.routeCoordinates) {
      try {
        const stopsResponse = await axios.get(`${config.baseURL}/stop/organization/location`, {
          params: {
            organizationId: 17,
            longitude: coordinate.longitude,
            latitude: coordinate.latitude,
          },
          withCredentials: true
        });
        console.log("Stops fetched for coordinate:", stopsResponse.data);
        stops.push(stopsResponse.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log("Unauthorized request");
          navigate("/");
          return;
        }
        console.log("Error in fetching stops by coordinates", error);
      }
    }
    return stops;
  };

  const filteredData = routesByOrganization.filter((route) => {
    const searchInputLowerCase = searchInput ? searchInput.toLowerCase() : '';
    return (
      (searchInput === "" ||
        (route.routeId && route.routeId.toLowerCase().includes(searchInputLowerCase)) ||
        (route.name && route.name.toLowerCase().includes(searchInputLowerCase)) ||
        (route.vehicles && route.vehicles.some(vehicle => vehicle.name.toLowerCase().includes(searchInputLowerCase))) || // Check if any vehicle name matches
        (route.stops && route.stops.some(stop => stop.name.toLowerCase().includes(searchInputLowerCase))) // Check if any stop name matches
      )
    );
  });


  console.log("Filtered Data:", filteredData);
  return (
    <>
      <DashboardHeader title="Routes" />
      <div className='page_container'>

        <RoutesHeader totalRoutes={routesByOrganization.length} />
        <div className="search-filter-wrapper" style={{padding:"20px"}}>
          <SearchRoutes onSearchChange={handleSearchChange} />
        </div>
        <Suspense fallback={<CircularProgress/>}>
          <RoutesTable
            routesWithStops={routesByOrganization}
            setRoutesByOrganization={setRoutesByOrganization}
            filteredData={filteredData}

          />
        </Suspense>
      </div>
    </>
  )
}

export default Routess;
