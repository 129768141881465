import { Box, Button, Divider, Grid, Paper, } from "@mui/material";
import React, { useEffect, useState, Suspense } from "react";
import { useNavigate } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import SearchVehicle from "./SearchVehicle";
import VehicleFilter from "./VehicleFilter";
import { vehicle_base_url } from "../organization/utils";
import useFetch from "../../hooks/useFetch";
import "./Vehicle.css";
import axios from "axios";
import config from "../../config.json";
import DashboardHeader from "../../components/header/DashboardHeader";
const VehicleTable = React.lazy(() => import('./VehicleTable'))

const Vehicle = () => {
  const [vehicleData, setVehicleData] = useState([]);
  console.log(vehicleData)
  const [filters, setFilters] = React.useState({
    route: "",
    status: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [siteList, setSiteList] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const navigate = useNavigate();

  const { data, loading, error, fetchEntities } = useFetch(vehicle_base_url);

  const handleVehicleSearch = (event) => {
    setSearchQuery(event.target.value);
    console.log("Search Query:", event.target.value);
  };

  useEffect(() => {
    axios
      .get(`${config.baseURL}/vehicle/organization`, { withCredentials: true })
      .then((response) => {
        console.log("response", response.data.organizationVehicles);
        setVehicleData(response.data);
      }).catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log("Unauthorized request");
          navigate("/");
          return;
        }
      });
  }, [data]);

  const filteredVehicleData = vehicleData?.filter((vehicle) => {
    console.log(
      "vehicle.status",
      vehicle.status,
      "filters.status",
      filters.status,
      "filters",
      filters
    );
    return (
      (
        (filters.route === "" || vehicle.route?.Id === filters.route) &&
        (filters.status === "" || vehicle.status === filters.status) &&
        (searchQuery === "" ||
          (vehicle.device_id &&
            vehicle.device_id
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (vehicle.name &&
            vehicle.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (vehicle.site?.siteId &&
            vehicle.site?.siteId
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (vehicle.route?.name &&
            vehicle.route?.name
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (vehicle.driver?.first_name &&
            vehicle.driver?.first_name
              .toLowerCase()
              .includes(searchQuery.toLowerCase())))) ||
      (vehicle.status &&
        vehicle.status.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  });

  return (
    <>
      <DashboardHeader title="Vehicles" />
      <div className="page_container">
        <VehicleHeader vehicleData={vehicleData} />
        <div className="o-search-filter-wrapper">
          <SearchVehicle handleVehicleSearch={handleVehicleSearch} />
          <VehicleFilter
            vehicleData={vehicleData}
            filters={filters}
            setFilters={setFilters}
          />
        </div>
        <Suspense fallback={<div className="loader"></div>}>
          <VehicleTable
            vehicleData={vehicleData}
            setVehicleData={setVehicleData}
            filteredVehicleData={filteredVehicleData}
            fetchEntities={fetchEntities}
            setSiteList={setSiteList}
            siteList={siteList}
            setRouteList={setRouteList}
            routeList={routeList}
            setDriverList={setDriverList}
            driverList={driverList}
          />
        </Suspense>
      </div>
    </>
  );
};

const VehicleHeader = ({ vehicleData }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="addbutton_container">
        <button
          style={{
            background: "rgba(32, 168, 224, 1)",
            color: "rgba(255, 255, 255, 1)",
          }}
          variant="contained"
          className="customButton_add"
          onClick={() =>
            navigate("/vehicle/TrackAllVehicle", {
              state: { allVehicleData: vehicleData }
            })
          }
          sx={{ marginRight: "10px" }}
        >
          Track All Vehicle
        </button>
        <button
          variant="contained"
          className="customButton_add"
          onClick={() => navigate("/vehicle/add")}
        >
          + Add Vehicle
        </button>
      </div>

      <Box className="Headercard">
        <Grid container spacing={3}>
          <Grid item xs>
            <Box className="Headercard_Total">
              <div className="Headercard_Total_text">
                <div className="org-para">
                  Total
                  <br />
                  Vehicles
                </div>
                <div>
                  <span className="org-count">
                    {vehicleData?.length} {vehicleData?.length > 1 ? "" : ""}
                  </span>
                </div>
              </div>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="Headercard_Active">
              <div className="Headercard_Total_text">
                <div className="org-para">
                  Active
                  <br />
                  Vehicles
                </div>
                <div>
                  <span className="org-count">
                    {
                      vehicleData?.filter(
                        (vehicle) => vehicle.status === "ACTIVE"
                      ).length
                    }{" "}
                  </span>
                </div>
              </div>
            </Box>
          </Grid>

          <Grid item xs>
            <Box className="Headercard_inactive">
              <div className="Headercard_Total_text">
                <div className="org-para">
                  Inactive
                  <br />
                  Vehicles
                </div>
                <div>
                  <span className="org-count">
                    {
                      vehicleData?.filter(
                        (vehicle) => vehicle.status === "INACTIVE"
                      ).length
                    }{" "}
                  </span>
                </div>
              </div>
            </Box>
          </Grid>

          <Grid item xs>
            <Box className="Headercard_dead">
              <div className="Headercard_Total_text">
                <div className="org-para">
                  Dead
                  <br />
                  Vehicles
                </div>
                <div>
                  <span className="org-count">
                    {vehicleData?.filter((vehicle) => vehicle.status === "DEAD").length}{""}
                  </span>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Vehicle;
