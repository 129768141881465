import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { vehicleStatus } from "../organization/utils";
import { Grid, useMediaQuery } from "@mui/material";

export default function VehicleFilter({ vehicleData, filters, setFilters }) {
  const vehicleRouteOptions = Array.from(
    new Set(
      vehicleData?.map(
        (vehicle) =>
          vehicle.route? {
            id: vehicle.route?.id,
            name: vehicle.route?.name,
          } : null
        ).filter(Boolean)
    )
  );

  const handleFilterRoute = (event) => {
    // let clonedFilters = { ...filters };
    // console.log("clonedFilter", clonedFilters);
    // clonedFilters.route = event.target.value;
    // setFilters(clonedFilters);
    setFilters({ ...filters, route: event.target.value });
  };

  const handleFilterVehicleStatus = (event) => {
    setFilters({ ...filters, status: event.target.value });
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        minWidth: isSmallScreen ? 300 : 500,
        marginTop: isSmallScreen ? "20px" : "0px",
      }}
      display="flex"
      gap={2}
      alignItems="center"
      flexDirection="row"
      justifyContent="space-around"
    >
      {/* Route Filter */}
      <FormControl
        fullWidth
        size="small"
        sx={{
          background: "rgba(242, 242, 243, 1)",
          borderRadius: "8px", // Smooth corners
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none", // Hides the border
            },
            "&:hover fieldset": {
              border: "1px solid rgba(0, 0, 0, 0.23)", // Adds border on hover
            },
            "&.Mui-focused fieldset": {
              border: "1px solid rgba(0, 0, 0, 0.23)", // Shows border on focus
            },
          },
        }}
      >
        <InputLabel
          id="vehicle-route-dropdown"
          sx={{
            background: "rgba(242, 242, 243, 1)",
            padding: "0 4px", // Prevent overlap
          }}
        >
          Route
        </InputLabel>
        <Select
          labelId="vehicle-route-dropdown"
          id="vehicle-route-dropdown"
          value={filters.route}
          label="Vehicles"
          onChange={handleFilterRoute}
        >
          <MenuItem value="">All Routes</MenuItem>
          {vehicleRouteOptions?.map((vehicleroute) => (
            <MenuItem key={vehicleroute?.id} value={vehicleroute?.id}>
              {vehicleroute?.name}
            </MenuItem>
          ))}
          {/* {vehicleRouteOptions?.map((vehicleroute) => (
          <MenuItem key={vehicleroute?.id} value={vehicleroute?.id}>
            {vehicleroute?.name}
          </MenuItem>
        ))} */}
        </Select>
      </FormControl>

      {/* Status Filter */}
      <FormControl
        fullWidth
        size="small"
        sx={{
          background: "rgba(242, 242, 243, 1)",
          borderRadius: "8px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "1px solid rgba(0, 0, 0, 0.23)",
            },
            "&.Mui-focused fieldset": {
              border: "1px solid rgba(0, 0, 0, 0.23)",
            },
          },
        }}
      >
        <InputLabel
          id="vehicle-status-dropdown"
          sx={{
            background: "rgba(242, 242, 243, 1)",
            padding: "0 4px",
          }}
        >
          Status
        </InputLabel>
        <Select
          labelId="vehicle-status-dropdown"
          id="vehicle-status-dropdown"
          value={filters.status}
          label="Status"
          onChange={handleFilterVehicleStatus}
        >
          <MenuItem value="">All Statuses</MenuItem>
          {Object.values(vehicleStatus)?.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
          {/* {Object.values(vehicleStatus)?.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))} */}
        </Select>
      </FormControl>
    </Box>
  );
}
