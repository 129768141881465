import React, { useEffect, useState } from 'react'
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { useTheme } from "@mui/material/styles";
import Pagination from '../../../components/pagination/Pagination';
import { getSortedData } from '../../organization/utils';
import { styled } from '@material-ui/core';


// Function to create data rows
function createData(name, description, startTime, endTime, repitation,) {
  return { name, description, startTime, endTime, repitation };
}

// Define columns for the table
const columns = [
  { label: "Schedules", sortable: false, id: "schedules" },
  { label: "Description", sortable: false, id: "description" },
  { label: "Start Time", sortable: true, id: "startTime" },
  { label: "End Time", sortable: false, id: "endTime" },
  { label: "Repetition", sortable: false, id: "repetition" }

];
// Function to format rows from API response
const getRows = (rows) =>
  rows.map((item) =>
    createData(
      item.name,
      item.description,
      item.startTime,
      item.endTime,
      item.repitation
    )
  );

// Component for displaying route schedules table
function RouteSchedulesTable({ routeSchedules }) {
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  // useEffect to initialize itemsPerPage
  useEffect(() => {
    setItemsPerPage(10);
  }, []);

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setItemsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when changing rows per page
  };
  // Function to handle column sorting
  const handleColumnSort = (id) => {
    const newOrder = order === "asc" ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(id);
  };
  // Inline styles for header
  const styles = {
    header: { backgroundColor: theme.palette.primary.main, color: "white" },
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  const totalCount = parseInt(routeSchedules.schedules?.length) || 0;
  const totalPages = Math.ceil(totalCount / itemsPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let sortedData = [];
  if (Array.isArray(routeSchedules.schedules)) {
    sortedData = routeSchedules.schedules.sort((a, b) =>
      getSortedData(a, b, orderBy, order)
    );
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(32, 168, 224, 1)",
      color: "white",
      fontSize: 20,
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 24,
    },
  }));



  const currentData = routeSchedules.schedules ?
    sortedData.slice(startIndex, endIndex) :
    [];

  return (
    <>

      <TableContainer >
        <Table>
          <TableHead>
            <TableRow >

              {columns.map((column) => (   // map over columns to render table headers
                <StyledTableCell
                  key={column.id}
                  onClick={() => handleColumnSort(column.id)}
                >
                  {column.label}{" "}
                  {column.sortable &&
                    (orderBy === column.id ? (
                      order === "asc" ? (
                        <ArrowDropUp
                          style={{ verticalAlign: "middle", cursor: "pointer", color: 'rgba(24, 48, 89, 1)' }}
                        />
                      ) : (
                        <ArrowDropDown
                          style={{ verticalAlign: "middle", cursor: "pointer", color: 'rgba(24, 48, 89, 1)' }}
                        />
                      )
                    ) : (
                      <ArrowDropUp
                        style={{ verticalAlign: "middle", cursor: "pointer", color: 'rgba(24, 48, 89, 1)' }}
                      />
                    ))}
                </StyledTableCell>

              ))}

            </TableRow>
          </TableHead>
          <TableBody >
            {getRows(currentData).map((row) => (    // Map over rows to render table data 
              <TableRow

                style={{ color: "black" }}
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell style={{ fontWeight: "600", fontSize: "16px" }}>{row.name}</TableCell>
                <TableCell >{row.description}</TableCell>
                <TableCell >{row.startTime}</TableCell>
                <TableCell>{row.endTime}</TableCell>
                <TableCell >{row.repitation}</TableCell>


              </TableRow>
            )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination component */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>

  )
}

export default RouteSchedulesTable   // Exporting the RouteSchedulesTable component as default