import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import "./feature.css";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { IconButton, MenuItem, Select, styled, TextField } from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Pagination from "../../components/pagination/Pagination";

function createData(id, name, description, api_path, action) {
  return { id, name, description, api_path, action };
}

const columns = [
  { label: "Access Name", sortable: true, id: "Access Name" },
  { label: "Description", sortable: false, id: "Description" },
  { label: "APIPath", id: "APIPath" },
  { label: "Operations", id: "Operations" },
  { label: "Actions", id: "Actions" },
];

const getRows = (rows) =>
  rows.map((item) =>
    createData(item.id, item.name, item.description, item.api_path, item.action)
  );

function FeaturesTable({
  featuresList,
  onDelete,
  onUpdate,
  onSortChange,
  sortParam,
}) {
  const [editableRowId, setEditableRowId] = useState(null);
  const [editedFeature, setEditedFeature] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage, setitemPerPage] = useState(10);
  const itemsPerPage = 10;
  // calculating pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const totalCount = parseInt(featuresList?.length);
  const totalPages = Math.ceil(totalCount / itemsPerPage) || 1;

  // handle pagination page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const rows = featuresList?.slice(startIndex, endIndex);

  // passing the row id  to delete the perticular row data
  const handleDelete = (id) => {
    console.log("this is id to delete", id);
    onDelete(id);
  };
  //  Handles the click event to make a row editable.
  const handleEditClick = (row) => {
    setEditableRowId(row.id);
    setEditedFeature({ ...row });
  };

  // Handles the click event to cancel editing.
  const handleCancelClick = () => {
    setEditableRowId(null);
    setEditedFeature({});
  };

  // Handles the click event to save the edited feature.
  const handleSaveClick = (id) => {
    onUpdate(editedFeature, id)
      .then(() => {
        setEditableRowId(null);
        setEditedFeature({});
      })
      .catch((error) => console.error("Error updating feature:", error));
  };
  // Handles input changes for the editable fields.
  const handleInputChange = (e, field) => {
    setEditedFeature({ ...editedFeature, [field]: e.target.value });
  };
  // Handles input changes for the action field (if it's a dropdown, for example)
  const handleActionChange = (e) => {
    setEditedFeature({ ...editedFeature, action: e.target.value });
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(32, 168, 224, 1)",
      color: "white",
      fontSize: 20,
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 24,
    },
  }));


return (
  <>

	<TableContainer component={Paper}  >
		<Table>
			<TableHead>
      <TableRow>
            {columns.map((column) => (
              <StyledTableCell
              align='justify'
            
              key={column.id}
               onClick={() => onSortChange(column.id)}>
                
                {column.label}
                {column.sortable && (
                  <>
                    {sortParam === column.id && <ArrowDropUp style={{ verticalAlign: "middle", cursor: "pointer" ,color:'rgba(24, 48, 89, 1)'}} />}
                    {sortParam !== column.id && <ArrowDropDown  style={{color:'rgba(24, 48, 89, 1)'}}/>}
                  </>
                )}
              </StyledTableCell>
            ))}
          </TableRow>
			</TableHead>
			<TableBody style={{padding:"0px",}} className='TableBody-root'>
      {getRows(rows).map((row) => (
					<TableRow 
          style={{ color: "black", padding: '2px', }}
      
           
          className="MuiTableRow-root" 
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          key={row.id}
					>
						<TableCell align='justify'style={{paddingLeft:'20px'}}>
                {editableRowId === row.id ? (
                  <TextField
                    value={editedFeature.name}
                    onChange={(e) => handleInputChange(e, 'name')}
                  />
                ) : (
                  row.name
                )}
              </TableCell >
              <TableCell align='justify'style={{paddingLeft:'20px'}}>
                {editableRowId === row.id ? (
                  <TextField
                    value={editedFeature.description}
                    onChange={(e) => handleInputChange(e, 'description')}
                  />
                ) : (
                  row.description
                )}
              </TableCell>
              <TableCell align='justify'style={{paddingLeft:'20px'}}>
                {editableRowId === row.id ? (
                  <TextField
                    value={editedFeature.api_path}
                    onChange={(e) => handleInputChange(e, 'api_path')}
                  />
                ) : (
                  row.api_path
                )}
              </TableCell>
              <TableCell align='justify' style={{paddingLeft:'20px'}}>
  {editableRowId === row.id ? (
    <Select
      value={editedFeature.action}
      onChange={handleActionChange}
    >
     <MenuItem value="GET">GET</MenuItem>
            <MenuItem value="POST">POST</MenuItem>
            <MenuItem value="PUT">PUT</MenuItem>
            <MenuItem value="DELETE">DELETE</MenuItem> 
            <MenuItem value="PATCH">PATCH</MenuItem>   
    </Select>
  ) : (
    row.action
  )}
</TableCell>
              <TableCell align='justify' >
                {editableRowId === row.id ? (
                  <>
                    <IconButton
                      aria-label="save"
                      onClick={() => handleSaveClick(row.id)}
                    >
                      <SaveIcon />
                    </IconButton>
                    <IconButton
                      aria-label="cancel"
                      onClick={handleCancelClick}
                    >
                      <CancelIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEditClick(row)}
                    >
                     <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.94574 3.10864H2.98794C2.46071 3.10864 1.95507 3.31809 1.58225 3.6909C1.20944 4.06371 1 4.56935 1 5.09658V19.0122C1 19.5394 1.20944 20.045 1.58225 20.4179C1.95507 20.7907 2.46071 21.0001 2.98794 21.0001H16.9035C17.4308 21.0001 17.9364 20.7907 18.3092 20.4179C18.682 20.045 18.8915 19.5394 18.8915 19.0122V12.0544" stroke="#183059" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.4015 1.61757C17.797 1.22215 18.3333 1 18.8925 1C19.4517 1 19.988 1.22215 20.3834 1.61757C20.7789 2.013 21.001 2.54931 21.001 3.10853C21.001 3.66775 20.7789 4.20406 20.3834 4.59949L10.9407 14.0422L6.96484 15.0362L7.95881 11.0603L17.4015 1.61757Z" stroke="#183059" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDelete(row.id)}
                      style={{color:'red'}}
                    >
                      <HighlightOffOutlinedIcon />
                    </IconButton>
                  </>
                )}
              </TableCell>
					</TableRow>
				  )
				)}  
			</TableBody>
		</Table>
	</TableContainer>
  <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
	
	
        
</>
   
  )
}

export default FeaturesTable;
