import React, { useState, useEffect } from "react";
import { Close, Menu } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";
import "./DashboardHeader.css";
import { useUser } from "../../pages/login/UserContextPersistent";

const DashboardHeader = ({ title }) => {
  const navigate = useNavigate();
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [iconSize, setIconSize] = useState(window.innerWidth > 768 ? 40 : 30);
  const { state } = useUser();

  const toggleMobileMenu = () => {
    const nav_header = document.querySelector(".navbar");
    if (nav_header) {
      nav_header.classList.toggle("active");
      setIsMenuActive(!isMenuActive);
    }
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      setIconSize(window.innerWidth > 768 ? 40 : 30);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleNavigateUser = (id) => {
    // id = selectedUserId;
    navigate("/AddUser", { state: { id } });
  };

  return (
    <section className="dashboard-header">
      <header className="dh-container">
        <div className="dh-menu">
          <div 
            className="menu-toggle"
            onClick={toggleMobileMenu}
          >
            {isMenuActive ? (
              <Close className="mobile-icon" aria-label="Close Menu" />
            ) : (
              <Menu className="mobile-icon" aria-label="Open Menu" />
            )}
          </div>
          <div className="dh-page">
            <h1
              style={{
                wordSpacing: "1px",
                color: "white",
              }}
            >
              {title}
            </h1>
          </div>
        </div>

        <div className="dh-profile">
          {/* Person Icon */}
          <div className="dh-image" onClick={togglePopup}>
            <PersonIcon
              style={{
                fontSize: iconSize,
                color: "rgba(24, 48, 89, 1)",
                background: "rgba(232, 234, 238, 1)",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            />
          </div>

          {/* Popup */}
          {isPopupVisible && (
            <div className="profile-popup">
              <div className="popup-header">
                <PersonIcon
                  style={{
                    fontSize: "48px",
                    color: "rgba(24, 48, 89, 1)",
                    background: "rgba(232, 234, 238, 1)",
                    borderRadius: "50%",
                    padding: "8px",
                  }}
                />
                <div className="profile-info">
                  <div className="profile-name">{state.user.name}</div>
                  <div className="profile-username">{state.user.role}</div>
                </div>
              </div>
              <div className="popup-menu">
                <div className="menu-item" onClick={() =>  handleNavigateUser(state.user.id)}>
                <PersonIcon /> My Profile
                </div>
                {/* <div className="menu-item">
                  <BusinessIcon /> My Organization
                </div> */}
                <div className="menu-item" onClick={() => navigate("/")}>
                  <ExitToAppIcon /> Logout
                </div>
              </div>
            </div>
          )}

          {title === "Organizations > Register" ? (
            <button className="dh-logout" onClick={() => navigate("/")}>
              Login
            </button>
          ) : (
            <div style={{ cursor: "pointer" }}>
              <svg
                width="18"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L9 9L17 1"
                  stroke="#F9E719"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </div>
      </header>
    </section>
  );
};

export default DashboardHeader;
