import React from 'react'
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Divider } from '@mui/material';
import './routeSchedules.css'  // Importing styles for the component

// Functional component for the header section of route schedules
function RouteSchedulesHeader({ routeSchedules }) {
	return (
		<>
			<div className='route_schedules_header'>   {/* Container for the header section */}
				<div className='route_details'>    {/* Container for route details */}
					<div className='name_details'>  {/* Container for route name */}
						<span style={{ fontWeight: "bold" }}> Route Name : </span>
						<span> {routeSchedules.name} </span>
					</div>
					<div className='id_details'>  {/* Container for route ID */}
						<span style={{ fontWeight: "bold" }}> Route ID : </span>
						<span> {routeSchedules.routeId} </span>
					</div>

				</div>

				<div className='search-filter-wrapper'>  {/* Container for filter section */}
					<Box
						sx={{
							width: "202px", 
							display: 'flex',
							float: 'right',
							marginLeft: '80%',
							background: 'rgba(242, 242, 243, 1)',
							"@media (max-width: 600px)": {
								width: "150px", // Reduced width for smaller screens
								marginLeft: "auto", // Center align for small screens
								float: 'none', // Prevent floating on small screens
							}
						}}
					>

						{/* Dropdown for filtering by repetition */}
						<FormControl fullWidth size="small">
							<InputLabel id="repetition-dropdown">Repetition</InputLabel>
							<Select
								labelId="repetition-dropdown"
								className='repetition_dropdwon'
								id="repetiton-dropdown"
								label="Repetition"
							//   value={selectedRouteFilter.name}
							//   onChange={onRouteSelectChange}
							>
								<MenuItem >All</MenuItem>
								<MenuItem >Daily</MenuItem>
								<MenuItem >Weekly</MenuItem>
								<MenuItem >Monthly</MenuItem>

							</Select>
						</FormControl>
					</Box>

				</div>
			</div>
		</>
	)
}

export default RouteSchedulesHeader   // Exporting the RouteSchedulesHeader component as default