import {
  AssignmentInd,
  CorporateFare,
  DirectionsBus,
  FormatListBulleted,
  Hail,
  //Help,
  CalendarToday,
  Map,
  Person,
  Route,
  //Settings,
  Window,
  NotificationsNone,
  ImportExport,
  TextSnippet,
  PolylineOutlined,
  Group,
} from "@mui/icons-material";
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra";

import { useUser } from "../../pages/login/UserContextPersistent";

export const navData = [
  {
    label: "Dashboard",
    path: "/overview",
    icon: <Window />,
    requiredFeature: "",
  },
  {
    label: "Vehicles",
    path: "/Vehicle",
    icon: <DirectionsBus />,
    requiredFeature: "/api/vehicle",
  },
  {
    label: "Schedules",
    path: "/schedule",
    icon: <CalendarToday/>,
    requiredFeature: "/api/schedule",
  },
  {
    label: "Stops",
    path: "/Stops",
    icon: <Hail />,
    requiredFeature: "/api/stop",
  },
  {
    label: "Routes",
    path: "/Route",
    icon: <Route />,
    requiredFeature: "/api/route",
  },
  {
    label: "Organizations",
    path: "/Organization",
    icon: <CorporateFare />,
    requiredFeature: "/api/organization",
  },
  // {
  //   label: "Driver",
  //   path: "/Driver",
  //   icon: <Group />,
  //   requiredFeature: "",
  // },
  // {
  //   label: "Sites",
  //   path: "/site",
  //   icon:    <PolylineOutlined style={{ transform: 'rotate(270deg)' }} />,
  //   requiredFeature: "",
  // },
  {
    label: "Users",
    path: "/Users",
    icon: <Person />,
    requiredFeature: "/api/user",
  },
  {
    label: "Manage Data",
    path: "/Managedata",
    icon: <TextSnippet />,
    requiredFeature: "",
  },
  {
    label: "Features",
    path: "/Feature",
    icon: <FormatListBulleted />,
    requiredFeature: "/api/feature",
  },
  
  // {
  //   label: "Drivers",
  //   path: "/Driver",
  //   icon:  <Group />,
  //   requiredFeature: "/api/driver",
  // },
  {
    label: "Alerts",
    path: "/Alerts",
    icon: <NotificationsNone />,
    requiredFeature: "/api/alert",
  },
  // {
  //   label: "Import/Export",
  //   path: "/Import/Export",
  //   icon: <ImportExport />,
  //   requiredFeature: "",
  // },

  
  
];

export const useFilteredNavData = (pathname) => {
  const { state } = useUser();
  console.log("User State:", state);

  const hasPermission = (requiredFeature) => {
    if (!state?.features) {
      console.log("No features found in state");
      return true;
    }

    console.log("Checking permission for:", requiredFeature);
    return (
      requiredFeature === "" ||
      state.features.some((feature) => feature.api_path.includes(requiredFeature))
    );
  };

  const filterNavData = () => {
    console.log("Current pathname:", pathname);
    
    const isOrganizationPath = [
      "/organization/add",
      "/AddOrganization",
      "/Organization"
    ].includes(pathname);

    const filteredData = isOrganizationPath
      ? navData.filter(
          (item) =>
            [
              "Dashboard",
              "Organizations",
              "Users",
              "User Roles",
              "Features",
              "Alerts",
              "Manage Data",
            ].includes(item.label) && hasPermission(item.requiredFeature)
        )
      : navData.filter(
          (item) =>
            !["Organizations", "Features"].includes(item.label) &&
            hasPermission(item.requiredFeature)
        );

    console.log("Filtered Nav Data:", filteredData);
    return filteredData;
  };

  return filterNavData();
};
