import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useMediaQuery from "@mui/material/useMediaQuery";  // Import the hook
import './stops.css';

const StopsFilter = ({
  stopsData,
  selectedRouteFilter,
  onRouteSelectChange,
  onStopNameSelectChange,
  selectedStopNameFilter,
  routesData,
}) => {

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Box
      sx={{ minWidth: 500 }}
      display="flex"
      gap={2}
      justifyContent="space-around"
      className="stops-filter-container"
    >
      {/* Dropdown for filtering by routes */}
      <FormControl 
      fullWidth 
      size="small"
      sx={{ maxWidth: isSmallScreen ? "322px" : "auto" ,
      }}>
        <InputLabel id="routes-dropdown"  sx={{
          background: "rgba(242, 242, 243, 1)",
          padding: "0 4px",
        }}>Routes</InputLabel>
        <Select
          style={{ background: "rgba(242, 242, 243, 1)" }}
          labelId="routes-dropdown"
          id="routes-dropdown"
          label="Routes"
          value={selectedRouteFilter.name}
          onChange={onRouteSelectChange}
        >
          <MenuItem value={"All"}>All</MenuItem>
          {routesData.map((item) => (
            <MenuItem key={item.id} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Dropdown for filtering by stop names */}
      <FormControl
        fullWidth
        size="small"
        sx={{ maxWidth: isSmallScreen ? "322px" : "auto" }}
      >
        <InputLabel id="stops-dropdown"  sx={{
          background: "rgba(242, 242, 243, 1)",
          padding: "0 4px",
        }}>Stops</InputLabel>
        <Select
          style={{ background: "rgba(242, 242, 243, 1)" }}
          labelId="stops-dropdown"
          id="name"
          label="Stops"
          value={selectedStopNameFilter.name}
          onChange={onStopNameSelectChange}
        >
          <MenuItem value={"All"}>All</MenuItem>
          {[...new Set(stopsData.map((stop) => stop.name))].map((stopName) => (
            <MenuItem key={stopName} value={stopName}>
              {stopName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default StopsFilter;

