import { Box, Grid, Paper } from "@mui/material";
import React, { useEffect, useMemo, useState, Suspense } from "react";
import SearchStops from "./SearchStops";
import "./stops.css";
import StopsFilter from "./StopsFilter";
import axios from "axios";
// import StopsTable from "./StopsTable";
import AddUpdateStops from "./AddUpdateStops/AddUpdateStops";
import DashboardHeader from "../../components/header/DashboardHeader";
import config from "../../config.json";
import { useNavigate } from "react-router";
import { usePermission } from "../../hooks/usePermission";

const StopsTable = React.lazy(() => import('./StopsTable'));
const Stops = () => {
  const [stopsData, setStopsData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isAddUpdateDialogOpen, setIsAddUpdateDialogOpen] = useState(false);
  const [routesData, setRoutesData] = useState([]);
  const [routesByCoordinates, setRoutesByCoordinates] = useState([]);
  const [updatedStopsData, setUpdatedStopsData] = useState([]);
  const [selectedRouteFilter, setSelectedRouteFilter] = useState({ name: "" });
  const [selectedStopForUpdate, setSelectedStopForUpdate] = useState(null);
  const [selectedStopNameFilter, setSelectedStopNameFilter] = useState({ name: "" });
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { hasPermission } = usePermission();

  // Fetch routes by coordinates for a single stop
  const getRouteByStopCoordinates = async (lat, lng, orgId) => {
    try {
      const response = await axios.get(`${config.baseURL}/route/coordinates/`, {
        params: {
          longitude: lng,
          latitude: lat,
          organizationId: orgId,
        }
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching routes by coordinates:", error);
      return [];
    }
  };

  // Initial data fetching
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);

        // Fetch stops
        const stopsResponse = await axios.get(
          `${config.baseURL}/stop/organization`,
          { withCredentials: true }
        );
        const stops = stopsResponse.data;

        // Fetch routes for each stop
        const stopsWithRoutes = await Promise.all(
          stops.map(async (stop) => {
            const routes = await getRouteByStopCoordinates(
              stop.latitude,
              stop.longitude,
              stop.organizationId
            );
            return { ...stop, routes };
          })
        );

        setStopsData(stopsWithRoutes);

        // Update stops data with route names
        const updatedStops = stopsWithRoutes.map(stop => ({
          ...stop,
          routeName: stop.routes?.[0]?.name || ""
        }));

        setUpdatedStopsData(updatedStops);
        setIsLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log("Unauthorized request");
          navigate("/");
          return;
        }
        console.error("Error fetching initial data:", error.response);
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []); // Empty dependency array - runs once on mount

  // Filtered data computation
  const filteredStopsData = useMemo(() => {
    let filteredData = updatedStopsData;

    // Search filter
    if (searchInput) {
      filteredData = filteredData.filter(stop =>
        stop.stopId.toLowerCase().includes(searchInput.toLowerCase()) ||
        stop.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        stop.routeName.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    // Stop name filter
    if (selectedStopNameFilter.name && selectedStopNameFilter.name !== "All") {
      filteredData = filteredData.filter(stop =>
        stop.name === selectedStopNameFilter.name
      );
    }

    // Route filter
    if (selectedRouteFilter.name && selectedRouteFilter.name !== "All") {
      filteredData = filteredData.filter(stop =>
        stop.routeName === selectedRouteFilter.name
      );
    }

    return filteredData;
  }, [updatedStopsData, searchInput, selectedStopNameFilter, selectedRouteFilter]);

  // Event handlers
  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRouteSelectChange = (event) => {
    setSelectedRouteFilter({ name: event.target.value });
  };

  const handleStopNameSelectChange = (event) => {
    setSelectedStopNameFilter({ name: event.target.value });
  };

  const handleAddUpdateDialogOpen = () => {
    navigate("/stops/add");
  };

  const handleAddUpdateDialogClose = () => {
    setIsAddUpdateDialogOpen(false);
  };

  const handleSelectedStopChange = (data) => {
    setSelectedStopForUpdate(data);
  };




  return (
    <>
      <DashboardHeader title="Stops" />
      {/* <div className="contact-content">
        <h2>Contact Us</h2>
        <p>Feel free to get in touch with us through...</p>
      </div> */}
      <div className="page_container">

        <StopsHeader stopCount={stopsData.length}

          onAddUpdateDialogOpen={handleAddUpdateDialogOpen}
          updatedStopsData={updatedStopsData}
          navigate={navigate}
        />
        <div className="search-filter-wrapper" style={{ padding:"20px" }}>
          <SearchStops onSearchChange={handleSearchChange} />
          <StopsFilter
            stopsData={stopsData}
            routesData={routesData}
            selectedRouteFilter={selectedRouteFilter}
            selectedStopNameFilter={selectedStopNameFilter}
            onRouteSelectChange={handleRouteSelectChange}
            onStopNameSelectChange={handleStopNameSelectChange}
          />
        </div>
        <Suspense fallback={<div className="loader"></div>}>
          <StopsTable
            stopsData={stopsData}
            setStopsData={setStopsData}
            filteredStopsData={filteredStopsData}
            onAddUpdateDialogOpen={handleAddUpdateDialogOpen}
            onSelectedStopDataChange={handleSelectedStopChange} // Pass callback
            routesByCoordinates={routesByCoordinates}
            updatedStopsData={updatedStopsData}
            searchInput={searchInput}
            selectedRouteFilter={selectedRouteFilter}
          />
        </Suspense>
        <AddUpdateStops
          open={isAddUpdateDialogOpen}
          onClose={handleAddUpdateDialogClose}
          selectedStopForUpdate={selectedStopForUpdate}
          routesData={routesData}
        />
      </div>
    </>
  );
};

const StopsHeader = ({
  stopCount,
  onAddUpdateDialogOpen,
  updatedStopsData,
  navigate,
}) => {
  const { hasPermission } = usePermission();

  return (
    <>
      <div className="addbutton_container">
        <button
          className="customButton_add responsive-button"
          style={{
            backgroundColor: "rgba(32, 168, 224, 1)",
            color: "rgba(255, 255, 255, 1)",
            width:"500px"
          }}
          onClick={() =>
            navigate("/stops/all/map", {
              state: { allStopsData: updatedStopsData },
            })
          }
        >
          View All Stops On Map
        </button>
        {hasPermission("/api/stop", "POST") && (
          <button
            className="customButton_add"
            style={{
              background: "rgba(249, 231, 25, 1)",
              color: "rgba(31, 36, 46, 1)",
              border: "none",
            }}
            onClick={onAddUpdateDialogOpen}
          >
            + Add Stop
          </button>
        )}
      </div>


      <Box className="Headercard">
        <Grid container spacing={3}>
          <Grid item xs>
            <Box className="Headercard_Total">
              <div className="Headercard_Total_text">
                <div className="org-para">
                  Total <br /> Stops
                </div>
                <div>
                  <span className="org-count">{stopCount}</span>
                </div>
              </div>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="Headercard_Active">
              <div className="Headercard_Total_text">
                <div className="org-para">
                  Connected <br /> Routes
                </div>
                <div>
                  <span className="org-count">{stopCount}</span>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Stops;
