import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config.json";
import { useParams, useNavigate } from "react-router-dom";
import StarIcon from "../../components/starIcon/StarIcon"
import Navbar from "../../components/navbar/Navbar"
import DashboardHeader from "../../components/header/DashboardHeader"

async function createSchedule(schedule) {
  const response = await axios.post(`${config.baseURL}/schedule`, schedule, {
    withCredentials: true,
  });
  return response.data;
}

async function createStopSchedules(scheduleId, stopSchedules) {
  const response = await axios.post(
    `${config.baseURL}/schedule/stop`,
    {
      scheduleId,
      stopSchedules,
    },
    {
      withCredentials: true,
    }
  );
  return response.data;
}

async function updateSchedule(schedule) {
  try {
    console.log("this is schedule", schedule);
    const response = await axios.put(`${config.baseURL}/schedule`, schedule, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to update schedule", error);
  }
}

async function updateStopSchedules(stopSchedules) {
  try {
    console.log("this is stopSchedules", stopSchedules);
    const response = await axios.put(
      `${config.baseURL}/schedule/stop`,
      stopSchedules,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to update stop schedules", error);
  }
}

async function getScheduleById(id) {
  try {
    const response = await axios.get(
      `${config.baseURL}/schedule/id?scheduleId=${id}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch schedules", error);
  }
}

async function getAllRoutes() {
  try {
    const response = await axios.get(`${config.baseURL}/route/organization`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch routes", error);
  }
}

async function getAllVehicles() {
  try {
    const response = await axios.get(`${config.baseURL}/vehicle/organization`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch vehicles", error);
  }
}

async function getStopByRouteCoordinate(longitude, latitude, organizationId) {
  try {
    const response = await axios.get(
      `${config.baseURL}/stop/organization/location`,
      {
        params: { longitude, latitude, organizationId },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch organization locations", error);
  }
}

async function getStopById(id) {
  try {
    const response = await axios.get(`${config.baseURL}/stop/id?id=${id}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch organization locations", error);
  }
}

function getFormattedDate(stopSchedules) {
  let formattedStopSchedulesPromises = stopSchedules.map((stopSchedule) => {
    // Return a promise for each stopSchedule
    return getStopById(stopSchedule.stopId).then((stop) => {
      return {
        ...stopSchedule,
        stopName: stop.name,
      };
    });
  });

  // Use Promise.all to wait for all promises to resolve and return a promise
  return Promise.all(formattedStopSchedulesPromises)
    .then((formattedStopSchedules) => {
      console.log(formattedStopSchedules, "formattedStopSchedules");
      return formattedStopSchedules;
    })
    .catch((err) => {
      console.error("Error in getting formatted stop schedules:", err);
      return [];
    });
}

const AddSchedule = () => {
  const { scheduleId } = useParams();
  const navigate = useNavigate();
  const [schedule, setSchedule] = useState({
    name: "",
    description: "",
    startTime: new Date().toISOString(),
    endTime: new Date().toISOString(),
    repitation: "",
    organizationId: 17,
    vehicleIds: [],
    routeId: "",
  });
  const [stopSchedules, setStopSchedules] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [routesData, vehiclesData] = await Promise.all([
          getAllRoutes(),
          getAllVehicles(),
        ]);
        setRoutes(routesData);
        setVehicles(vehiclesData);

        if (scheduleId) {
          const scheduleData = await getScheduleById(scheduleId);
          setSchedule({
            ...scheduleData,
            vehicleIds: scheduleData.vehicleSchedule.map((vs) => vs.vehicle.id),
          });
          const formattedStopSchedules = await getFormattedDate(
            scheduleData.stopSchedules
          );
          setStopSchedules(formattedStopSchedules);
          setSelectedRoute(
            routesData.find((r) => r.id === scheduleData.routeId)
          );
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [scheduleId]);
  useEffect(() => {
    if (routes.length > 0 && schedule) {
      const foundRoute = routes.find((r) => r.id === schedule.routeId);
      setSelectedRoute(foundRoute);
    }
  }, [routes, schedule]);

  const handleChange = async (e) => {
    let { name, value } = e.target;
    
    // Handle date-time inputs
    if (name === "startTime" || name === "endTime") {
      const dateObj = new Date(value);
      value = dateObj.toISOString();
    }
    
    // For routeId, ensure it's converted to a number
    if (name === "routeId") {
      value = parseInt(value);
    }
    
    // Update the schedule state
    setSchedule(prevSchedule => ({ ...prevSchedule, [name]: value }));

    // Handle route selection and stop schedules
    if (name === "routeId" && value) {
      const selectedRoute = routes.find(r => r.id === value);
      console.log("Selected Route:", selectedRoute); // Debug log
      
      if (selectedRoute && selectedRoute.routeCoordinates) {
        setSelectedRoute(selectedRoute);
        
        try {
          const stopPromises = selectedRoute.routeCoordinates.map(
            async (coord, index) => {
              const stopInfo = await getStopByRouteCoordinate(
                coord.longitude,
                coord.latitude,
                selectedRoute.organizationId
              );
              
              if (!stopInfo) {
                console.log("Stop not found for coordinates:", coord);
                return null;
              }
              
              return {
                order: index + 1,
                longitude: coord.longitude,
                latitude: coord.latitude,
                arrivalTime: "",
                departureTime: "",
                stopId: stopInfo.id,
                stopName: stopInfo.name,
              };
            }
          );

          const resolvedStops = await Promise.all(stopPromises);
          const filteredStops = resolvedStops.filter(stop => stop !== null);
          console.log("Fetched stops:", filteredStops); // Debug log
          setStopSchedules(filteredStops);
        } catch (error) {
          console.error("Error fetching stops:", error);
        }
      }
    }
  };
  const handleVehicleChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) =>
      parseInt(option.value)
    );
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      vehicleIds: selectedOptions,
    }));
  };

  const handleStopChange = (index, e) => {
    const { name, value } = e.target;
    const updatedStops = [...stopSchedules];
    updatedStops[index] = { ...updatedStops[index], [name]: value };
    setStopSchedules(updatedStops);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("submitted")

    const formattedSchedule = {
      ...schedule,
      startTime: new Date(schedule.startTime).toISOString(),
      endTime: new Date(schedule.endTime).toISOString(),
    };
    // Add console logs to debug
    console.log("Formatted ISO Start Time:", formattedSchedule.startTime);
    console.log("Formatted ISO End Time:", formattedSchedule.endTime);
    try {
      let createdSchedule;
      if (scheduleId) {
        console.log(schedule, "schedule");
        createdSchedule = await updateSchedule(formattedSchedule);
      } else {
        createdSchedule = await createSchedule(formattedSchedule);
      }

      if (stopSchedules.length > 0 && stopSchedules[0].id) {
        console.log(stopSchedules, "stopSchedules");
        const formattedStopSchedules = stopSchedules.map((stopSchedule) => ({
          id: stopSchedule.id,
          arrivalTime: stopSchedule.arrivalTime,
          departureTime: stopSchedule.departureTime,
          stopId: stopSchedule.stopId,
        }));
        await updateStopSchedules(formattedStopSchedules);
      } else if (stopSchedules.length > 0) {
        const formattedStopSchedules = stopSchedules.map((stopSchedule) => ({
          arrivalTime: stopSchedule.arrivalTime,
          departureTime: stopSchedule.departureTime,
          stopId: stopSchedule.stopId,
        }));
        await createStopSchedules(createdSchedule.id, formattedStopSchedules);
      }

      navigate("/schedule");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      {/* Navbar Fixed */}
      <div style={{ display: "flex", backgroundColor: "#f5f5f5" }}>
        {/* Main Content Area */}
        <div style={{ width: "100%" }}>
          <DashboardHeader
            title={<div style={{ fontSize: "32px", fontWeight: 400 }}>{`Schedules > ${scheduleId ? "Update" : "Add"}`}</div>}
            style={{
              padding: "16px",
              backgroundColor: "#fff",
            }}
          />
          {/* Content and Form */}
          <div className="page_container" style={{ background: "white", borderRadius: "8px" }}>
            <form onSubmit={handleSubmit}>
              <div className="form_header" style={{ marginTop: '0px', marginBottom:"18px" }}>
                <p>Schedule Details</p>
              </div>

              {/* Schedule Name and Repetition */}
              <div style={{ display: "flex", gap: "32px", marginBottom: "16px" }}>
                <div style={{ flex: 1 }}>
                  <label>Schedule Name<StarIcon /></label>
                  <input
                    type="text"
                    placeholder="Schedule Name"
                    name="name"
                    value={schedule.name}
                    onChange={handleChange}
                    required
                    style={{
                      width: "100%",
                      padding: "16px 24px",
                      borderRadius: "4px",
                      border: "2px solid rgba(24, 48, 89, 1)",
                      marginTop: "8px",
                      borderRadius: '16px'
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <label>Repetition<StarIcon /></label>
                  <select
                    name="repitation"
                    value={schedule.repitation}
                    onChange={handleChange}
                    required
                    style={{
                      width: "100%",
                      padding: "16px 24px",
                      borderRadius: "4px",
                      border: "2px solid rgba(24, 48, 89, 1)",
                      marginTop: "8px",
                      borderRadius: '16px'
                    }}
                  >
                    <option value="">Select Repetition</option>
                    <option value="DAILY">Daily</option>
                    <option value="WEEKLY">Weekly</option>
                    <option value="MONTHLY">Monthly</option>
                  </select>
                </div>
              </div>


              {/* Route and Vehicle Assignment */}
              <div style={{ display: "flex", gap: "32px", marginBottom: "16px" }}>
                <div style={{ flex: 1 }}>
                  <label>Assign Route<StarIcon /></label>
                  <select
                    name="routeId"
                    value={schedule.routeId}
                    onChange={handleChange}
                    required
                    style={{
                      width: "100%",
                      padding: "16px 24px",
                      borderRadius: "4px",
                      border: "2px solid rgba(24, 48, 89, 1)",
                      marginTop: "8px",
                      borderRadius: '16px'
                    }}
                  >
                    <option value="">Select Route</option>
                    {routes?.map((route) => (
                      <option key={route.id} value={route.id}>
                        {route.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ flex: 1 }}>
                  <label>Assign Vehicle</label>
                  <select
                    name="vehicleId"
                    value={schedule.vehicleId}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      padding: "16px 24px",
                      borderRadius: "4px",
                      border: "2px solid rgba(24, 48, 89, 1)",
                      marginTop: "8px",
                      borderRadius: '16px'
                    }}
                  >
                    <option value="">Select Vehicle</option>
                    {vehicles?.map((vehicle) => (
                      <option key={vehicle.id} value={vehicle.id}>
                        {vehicle.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Schedule Start Time and End Time */}
              <div style={{
                display: "flex",
                gap: "32px",
                marginBottom: "16px",
                flexWrap: "wrap",
              }}>
                <div style={{ flex: 1, minWidth: "300px", }}>
                  <label>Schedule Start Time<StarIcon /></label>
                  <input
                    type="datetime-local"
                    name="startTime"
                    value={schedule.startTime ? schedule.startTime.slice(0, 16) : ''}
                    onChange={handleChange}
                    required
                    style={{
                      width: "100%",
                      padding: "16px 24px",
                      borderRadius: "16px",
                      border: "2px solid rgba(24, 48, 89, 1)",
                      marginTop: "8px",
                    }}
                    pattern="^(0[1-9]|1[0-2]):[0-5][0-9] [AP]M$"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <label>Schedule End Time<StarIcon /></label>
                  <input
                    type="datetime-local"
                    name="endTime"
                    value={schedule.endTime ? schedule.endTime.slice(0, 16) : ''}
                    onChange={handleChange}
                    placeholder="00:00 AM/PM"
                    required
                    style={{
                      width: "100%",
                      padding: "16px 24px",
                      borderRadius: "16px",
                      border: "2px solid rgba(24, 48, 89, 1)",
                      marginTop: "8px",
                    }}
                    pattern="^(0[1-9]|1[0-2]):[0-5][0-9] [AP]M$"
                  />
                </div>
              </div>




              {/* Schedule Description */}
              <div style={{ marginBottom: "16px" }}>
                <label>Schedule Description</label>
                <textarea
                  name="description"
                  value={schedule.description}
                  onChange={handleChange}
                  required
                  placeholder="Enter description here"
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "2px solid rgba(24, 48, 89, 1)",
                    marginTop: "8px",
                    minHeight: "100px",
                    borderRadius: '16px'
                  }}
                ></textarea>
              </div>
              <div className="form_header">Route Schedule</div>
              {selectedRoute && stopSchedules.length > 0 && (
                <div style={{ marginTop: "32px" }}>
                  {/* Stop Strip Header */}
                  <div className="stop_strip" style={{ display: "flex", backgroundColor: "#009FDA", padding: "10px", color: "#fff", fontWeight: "bold", fontSize: "16px" }}>
                    <div style={{ flex: 1 }}>Stops</div>
                    <div style={{ flex: 1 }}>Arrival Time<StarIcon /></div>
                    <div style={{ flex: 1 }}>Departure Time<StarIcon /></div>
                  </div>

                  {/* Stop Schedules */}
                  {stopSchedules.map((stop, index) => (
                    <div key={index} style={{ display: "flex", padding: "16px 0", fontSize: "24px", color: "#000", borderBottom: "1px solid #ddd", fontSize: "16px" }}>
                      {/* Stop Name */}
                      <div style={{ flex: 1, padding: "0 10px" }}>
                        {stop.stopName}
                      </div>

                      {/* Arrival Time Input */}
                      <div style={{ flex: 1, padding: "0 10px" }}>
                        <input
                          type="time"
                          name="arrivalTime"
                          value={stop.arrivalTime}
                          onChange={(e) => handleStopChange(index, e)}
                          placeholder="00:00 AM/PM"
                          required
                          style={{
                            width: "100%",
                            padding: "8px",
                            borderRadius: "8px",
                            border: "1px solid #182F59",
                            fontSize: "16px",
                          }}
                        />
                      </div>

                      {/* Departure Time Input */}
                      <div style={{ flex: 1, padding: "0 10px" }}>
                        <input
                          type="time"
                          name="departureTime"
                          value={stop.departureTime}
                          placeholder="00:00 AM/PM"
                          onChange={(e) => handleStopChange(index, e)}
                          required
                          style={{
                            width: "100%",
                            padding: "8px",
                            borderRadius: "8px",
                            border: "1px solid #182F59",
                            fontSize: "16px",
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}


              <div className="stop_strip" style={{ color: 'rgba(32, 168, 224, 1)' }}>.</div>
              <div className="addbutton_container">
                <button
                  type="button"
                  onClick={() => navigate("/schedule")}
                  className="customButton_add"
                  style={{
                    padding: "12px 24px",
                    border: "none",
                    borderRadius: "4px",
                    marginTop: "16px",
                    marginLeft: "8px",
                    backgroundColor: "rgba(242, 242, 243, 1)",

                    cursor: "pointer",
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="customButton_add"
                  style={{
                    padding: "12px 24px",
                    border: "none",
                    borderRadius: "4px",
                    marginTop: "16px",
                    cursor: "pointer",
                  }}
                >
                  {scheduleId ? "Submit" : "Submit"}
                </button>

              </div>
            </form>
          </div>
        </div>
      </div>
    </>

  );
};

export default AddSchedule;
