import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./homeHeader.css";
import { AddComponent } from "../../../config/Pageitems";
import { Add } from "@mui/icons-material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import MenuIcon from '@mui/icons-material/Menu';
import { Navigate, useNavigate } from "react-router-dom";
import { Box, Button, Menu, MenuItem } from "@mui/material";

const HomeHeader = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const isMenuOpen = Boolean(anchorEl);
  // const HomeNav = [
  //     { lable: 'Features', path: '/' },
  //     { lable: 'Pricing', path: '/' },
  //     { lable: 'Support', path: '/' },
  //     { lable: 'Resources', path: '/' },
  //     { lable: 'Contact Us', path: '/' },
  // ]

  return (
    <Box sx={{ width: "100%" }}>
      <section className='home-header'>
        <div className="hh-container">
          <div className="hh-navlink">
            <div className="hh-logo">
              <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" />
              <h1
                style={{
                  marginLeft: "12px",
                  fontWeight: "600",
                  marginTop: "7px",
                  fontSize: '32px'
                }}>
                RouteEye
              </h1>
            </div>
            {/* <ul className="hh-navdata">
  return (
    <Box sx={{ width: "100%" }}>
      <section className="home-header">
        <div className="hh-container">
          <div className="hh-navlink">
            <div className="hh-logo">
              <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" />

              <h1
                style={{
                  marginLeft: "12px",
                  fontWeight: "600",
                  marginTop: "7px",
                }}
              >
                RouteEye
              </h1>
            </div>
            {/* <ul className="hh-navdata">
                        {
                            HomeNav?.map((item, index) => {
                                return (
                                    <li key={index}><Link to={item.path} className="hh-link">{item.lable}</Link></li>
                                )
                            })
                        }
                    </ul> */}
            <MenuIcon
              className="hh-menuIcon"
              style={{
                color: '#183059'
              }}
              onClick={handleMenuOpen}
            />
            {/* Dropdown menu */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {/* Register Button */}
              <MenuItem
                onClick={() => {
                  navigate("/register");
                  handleMenuClose();
                }}
              >
                <Button
                  style={{
                    fontSize: "20px",
                    width: "212px",
                    padding: "12px 24px",
                    borderRadius: "8px",
                    textTransform: "none",
                    background: "#20A8E0",
                    boxShadow: "none",
                    color: "white",
                  }}
                >
                  Register
                </Button>
              </MenuItem>

              {/* Contact Us Button */}
              <MenuItem
                onClick={() => {
                  window.open("https://routeeye.io/contact", "_blank");
                  handleMenuClose();
                }}
              >
                <Button
                  style={{
                    fontSize: "20px",
                    width: "212px",
                    marginTop: "10px",
                    padding: "12px 24px",
                    borderRadius: "8px",
                    textTransform: "none",
                    background: "white",
                    color: "rgba(31, 36, 46, 1)",
                  }}
                >
                  Contact Us
                </Button>
              </MenuItem>
            </Menu>

          </div>
          <div className="hh-buttons">
            <QuestionMarkIcon
              style={{
                background: "rgba(24, 48, 89, 1)",
                borderRadius: "50px",
                color: "white",
              }}
            />
            <button
              variant="contained"
              className="customButton_add"
              style={{
                background: "rgba(32, 168, 224, 1)",
                color: "rgba(255, 255, 255, 1)",
                fontSize: "20px",
                fontWeight: "400",
                marginRight: "40px",
              }}
              onClick={() => {
                navigate("/register");
              }}
            >

              Register Organization
            </button>

          </div>


        </div>
      </section>
    </Box>
  );
};

export default HomeHeader;
