import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useNavigate, useLocation } from "react-router-dom";
import { loadOptions } from "./mapConfig";
import DashboardHeader from '../../components/header/DashboardHeader'

const AllStopsMapPage = () => {
  const location = useLocation();
  const allStopsData = location.state?.allStopsData || [];
  console.log(allStopsData)

  const [selectedStop, setSelectedStop] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const navigate = useNavigate();

  const { isLoaded, loadError } = useJsApiLoader(loadOptions);

  useEffect(() => {
    if (allStopsData.length > 0) {
      // Set the map center to the first stop's location
      setMapCenter({
        lat: parseFloat(allStopsData[0].latitude),
        lng: parseFloat(allStopsData[0].longitude),
      });
    }
  }, [allStopsData]);

  const handleStopClick = (stop) => {
    setSelectedStop(stop);
  };

  const handleInfoWindowClose = () => {
    setSelectedStop(null);
  };

  const handleViewDetails = () => {
    if (selectedStop) {
      navigate(`/Stop/track`, { state: { selectedStopData: selectedStop } });
    }
  };

  const mapContainerStyle = {
    width: "100%",
    height: "600px",
  };

  const renderMap = () => (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={mapCenter}
      zoom={12}
    >
      {allStopsData.map((stop) => (
        <Marker
          key={stop.id}
          position={{
            lat: parseFloat(stop.latitude),
            lng: parseFloat(stop.longitude),
          }}
          onClick={() => handleStopClick(stop)}
          // The default marker will be used automatically when no icon is specified
        />
      ))}

      {selectedStop && (
        <InfoWindow
          position={{
            lat: parseFloat(selectedStop.latitude),
            lng: parseFloat(selectedStop.longitude),
          }}
          onCloseClick={handleInfoWindowClose}
        >
          <div>
            <h3>{selectedStop.name}</h3>
            <p>Stop ID: {selectedStop.stopId}</p>
            <button onClick={handleViewDetails}>View Details</button>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;
  if (allStopsData.length === 0) return <div>No stops data available</div>;

  return (
    <>
    <DashboardHeader title="Stops > View All"/> 
 
    <div className="page_container"style={{border:'none'}}>
    
      {renderMap()}
    </div>
    
    </>
  );
};

export default AllStopsMapPage;