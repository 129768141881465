import React, { useState, useEffect } from "react";
import { Pagination as MuiPagination, Select, MenuItem, Button } from "@mui/material";
import "./pagination.css";

const Pagination = ({ currentPage, totalPages, onPageChange, onRowsPerPageChange }) => {
  const [page, setPage] = useState(currentPage);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Hook to handle screen size changes
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    // Check screen size on mount
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage); // Update local state
    onRowsPerPageChange(newRowsPerPage); // Notify parent component
  };

  return (
    <div
      className="pagination"
      style={{
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        gap: "16px",
        alignItems: "center",
      }}
    >
      {/* Rows per page selector */}
      <Select
        value={rowsPerPage} // Bind the selected value
        onChange={handleRowsPerPageChange}
        size="small"
        style={{
          fontSize: isSmallScreen ? "14px" : "16px",
          background: "rgba(242, 242, 243, 1)",
          border: "1px solid rgba(24, 48, 89, 1)",
          borderRadius: "4px",
          width: "100px",
        }}
      >
        <MenuItem value={10}>10 Rows</MenuItem>
        <MenuItem value={30}>30 Rows</MenuItem>
        <MenuItem value={50}>50 Rows</MenuItem>
        <MenuItem value={70}>70 Rows</MenuItem>
        <MenuItem value={100}>100 Rows</MenuItem>
      </Select>

      {/* Pagination */}
      <MuiPagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        variant="outlined"
        color="primary"
        size={isSmallScreen ? "small" : "medium"}
        sx={{
          "& .MuiPaginationItem-root": {
            minWidth: "32px",
            height: "32px",
            fontSize: isSmallScreen ? "12px" : "14px",
          },
        }}
      />

      {/* Custom Previous/Next Buttons */}
      <div style={{ display: "flex", gap: "8px" }}>
        <Button
          variant="outlined"
          size="small"
          style={{
            color: page === 1 ? "rgba(31, 36, 46, 1)" : "rgba(255, 255, 255, 1)",
            background: page === 1 ? "rgba(242, 242, 243, 1)" : "rgba(32, 168, 224, 1)",
            textTransform: "none",
            fontSize:"18px",
            cursor:"pointer"

          }}
          onClick={() => handlePageChange(null, page - 1)}
          disabled={page === 1}
        >
          &lt;Previous
        </Button>

        <Button
          variant="outlined"
          size="small"
          style={{
            color: "rgba(255, 255, 255, 1)",
            background: page === totalPages ? "rgba(183, 191, 204, 1)" : "rgba(32, 168, 224, 1)",
            textTransform: "none",
            fontSize:"18px",
            cursor:"pointer"
          }}
          onClick={() => handlePageChange(null, page + 1)}
          disabled={page === totalPages}
        >
          Next&gt;
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
