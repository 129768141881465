import React from 'react';
import './Sidebar.css';  // You can create a new CSS file for the sidebar styles

const Sidebar = () => {
  return (
    <section className="sidebar">
      <nav className="s-container">
        <header className="s-logo">
            <div style={{display:'flex'}}>
            <div > 
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" style={{border:"2px slid red"}} />
          </div >
          <div className="s-logo-text" style={{paddingLeft:'22px'}}>RouteEye</div>
          </div>
        </header>
      </nav>
    </section>
  );
};

export default Sidebar;
