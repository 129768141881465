import React, { useState } from "react";
import "./Navbar.css";
import { NavLink, useLocation } from "react-router-dom";
import { useFilteredNavData } from "./NavData";

const Navbar = () => {
  const location = useLocation();
  const filteredNavData = useFilteredNavData(location.pathname);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isActiveRoute = (path) => {
    return location.pathname === path || location.pathname.startsWith(`${path}/`);
  };

  const closeMenu = () => {
    // Optional: If you need any logic here
  };

  return (
    <section className="navbar">
      <nav className="n-container">
        {/* Fixed Logo Section */}
        <header className="n-logo">
          <img
            src={`${process.env.PUBLIC_URL}/Kempegowda_International_Airport_Bengaluru_Logo.svg.png`}
            alt="Logo"
            style={{
              height: "116.2px",
              width: "160.2px",
              marginLeft: "-8px",
            }}
          />
        </header>

        {/* Scrollable Navigation Section */}
        <div className="n-data">
          <div className="n-home">
            <header className="mob-n-logo">
              <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" />
              <h1
                style={{
                  fontFamily: "Roboto",
                  fontSize: "36px",
                  fontWeight: "600",
                }}
              >
                RouteEye
              </h1>
            </header>
            {filteredNavData?.map((item, index) => (
              <NavLink
                to={item.path}
                key={index}
                className={({ isActive }) =>
                  isActive || isActiveRoute(item.path)
                    ? "sub-data active-link"
                    : "sub-data"
                }
                onClick={closeMenu}
              >
                {item.icon}
                <p>{item.label}</p>
              </NavLink>
            ))}
          </div>
        </div>
      </nav>
    </section>
  );
};

export default Navbar;