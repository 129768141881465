import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
import { loadOptions } from "./mapConfig";
import DashboardHeader from "../../components/header/DashboardHeader";
import Pagination from "../../components/pagination/Pagination";
import {
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import config from "../../config.json";
// import { googleMapsLoaderOptions } from "../map/mapConfig";
import "./stops.css"
import busIcon from "../../images/bus-svgrepo-com.svg"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(32, 168, 224, 1)",
    color: "white",
    fontSize: 20,
    fontWeight: 600,
    width: "20%",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 24,
  },
}));

const LoadingScreen = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      flexDirection: "column",
      gap: "20px",
    }}
  >
    <CircularProgress size={60} />
    <div>Loading map data...</div>
  </div>
);

const MapPage = () => {
  const location = useLocation();
  const [stopData, setStopData] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [directions, setDirections] = useState([]);
  const [vehicleData, setVehicleData] = useState({});
  const [error, setError] = useState(null);
  const routePathsRef = useRef({});
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
  // const memoloadOptions = useMemo(() => loadOptions, []);
  const { isLoaded, loadError } = useJsApiLoader(
    {
      ...loadOptions,
      libraries: [...(loadOptions.libraries || []), "geometry"],
    }
  );

  // const { isLoaded, loadError } = useJsApiLoader({
  //   ...loadOptions,
  //   libraries: loadOptions.libraries ? [...loadOptions.libraries, "geometry"] : ["geometry"],
  // });

  // const loadOptionsMemo = useMemo(() => {
  //   return {
  //     ...loadOptions,
  //     libraries: [...loadOptions.libraries, "geometry"], 
  //   };
  // }, []);

  // const { isLoaded, loadError } = useJsApiLoader(loadOptionsMemo);

  const [userState, setUserState] = useState(null);
  const [hasMapPermission, setHasMapPermission] = useState(false);

  useEffect(() => {
    // Fetch userState from localStorage
    const user = JSON.parse(localStorage.getItem('userState'));
    if (user) {
      setUserState(user);
      // Check if the user has permission to access the map (check if "GET /api/stop/organization" exists in features)
      const mapPermission = user.features.some(
        (feature) => feature.api_path === '/api/stop/organization' && feature.action === 'GET'
      );
      setHasMapPermission(mapPermission);
    }
  }, []);

  // Fetch stop data and handle no access condition
  useEffect(() => {
    const locationStopData = location.state?.selectedStopData;
    if (!locationStopData) {
      setError("No stop data available");
      return;
    }
    setStopData(locationStopData);
  }, [location.state]);

  // Fetch directions when stop data is available
  useEffect(() => {
    if (stopData && isLoaded) {
      fetchDirections(stopData.routes);
    }
  }, [stopData, isLoaded]);

  // Check if map data is loaded
  const isMapAccessible = isLoaded && stopData && isInitialized;

  useEffect(() => {
    const handleResize = () => setIsLargeScreen(window.innerWidth > 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Initialize stop data
  useEffect(() => {
    const locationStopData = location.state?.selectedStopData;
    if (!locationStopData) {
      setError("No stop data available");
      return;
    }
    setStopData(locationStopData);

  }, [location.state]);

  // Fetch directions when stop data is available
  useEffect(() => {
    if (stopData && isLoaded) {
      fetchDirections(stopData.routes);
    }
  }, [stopData, isLoaded]);

  const fetchDirections = async (routes) => {
    if (!routes?.length) {

      setError("No routes available");
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();
    const directionsPromises = routes.map((route) => {
      return new Promise((resolve, reject) => {
        const waypoints = route.routeCoordinates.slice(1, -1).map((coord) => ({
          location: new window.google.maps.LatLng(
            parseFloat(coord.latitude),
            parseFloat(coord.longitude)
          ),
          stopover: true,
        }));

        directionsService.route(
          {
            origin: new window.google.maps.LatLng(
              parseFloat(route.routeCoordinates[0].latitude),
              parseFloat(route.routeCoordinates[0].longitude)
            ),
            destination: new window.google.maps.LatLng(
              parseFloat(
                route.routeCoordinates[route.routeCoordinates.length - 1]
                  .latitude
              ),
              parseFloat(
                route.routeCoordinates[route.routeCoordinates.length - 1]
                  .longitude
              )
            ),
            waypoints: waypoints,
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              routePathsRef.current[route.id] = result.routes[0].overview_path;
              resolve(result);
            } else {
              reject(`error fetching directions ${result}`);
            }
          }
        );
      });
    });

    try {
      const results = await Promise.all(directionsPromises);
      setDirections(results);
      setIsInitialized(true);
    } catch (error) {
      console.error("Error fetching directions:", error);
      setError("Failed to fetch route directions");
    }
  };

  const snapToRoute = useCallback((routeId, position) => {
    const routePath = routePathsRef.current[routeId];
    if (!routePath) return position;

    const point = new window.google.maps.LatLng(position.lat, position.lng);
    let closestPoint = point;
    let minDistance = Infinity;

    for (let i = 0; i < routePath.length - 1; i++) {
      const start = routePath[i];
      const end = routePath[i + 1];
      const projectedPoint = window.google.maps.geometry.spherical.interpolate(
        start,
        end,
        0.5
      );
      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          point,
          projectedPoint
        );

      if (distance < minDistance) {
        closestPoint = projectedPoint;
        minDistance = distance;
      }
    }

    return { lat: closestPoint.lat(), lng: closestPoint.lng() };
  }, []);

  const updateVehicleData = useCallback(async () => {
    if (!stopData || !isInitialized) return;
    
    const newData = {};
    for (const route of stopData.routes) {
      for (const vehicle of route.vehicles) {
        try {
          const response = await fetch(
            `${config.baseURL}/location/latest/${vehicle.device_id}`
          );
          const data = await response.json();
          const position = {
            lat: parseFloat(data.latitude),
            lng: parseFloat(data.longitude),
          };

          // Check if current stop is origin or destination for this route
          const isExtreme = route.routeCoordinates[0].stopId === stopData.stopId || 
                           route.routeCoordinates[route.routeCoordinates.length - 1].stopId === stopData.stopId;

          const disTimeRes = await fetch(
            `${config.baseURL}/distance/time/stop`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                vehicleId: vehicle.device_id,
                longitude: stopData.longitude,
                latitude: stopData.latitude,
                isExtremePoint: isExtreme
              }),
            }
          );
          const disTimeData = await disTimeRes.json();
          const snappedPosition = snapToRoute(route.id, position);
          newData[vehicle.device_id] = {
            position: snappedPosition,
            routeId: route.routeId,
            speed: parseFloat(data.Speed),
            distance: disTimeData.distance,
            eta: disTimeData.eta,
          };
        } catch (error) {
          console.error(`Error fetching data for vehicle ${vehicle.device_id}:`, error);
        }
      }
    }
    setVehicleData(newData);
  }, [stopData, snapToRoute, isInitialized]);

  const formatTime = (seconds) => {
    if (seconds < 60) {
      return `${seconds} sec`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes} min`;
    } else {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      return `${hours} hr ${minutes > 0 ? `${minutes} min` : ''}`;
    }
  };



  useEffect(() => {
    let intervalId;

    const initializeVehicleUpdates = async () => {
      if (stopData && isInitialized) {
        // Initial call
        await updateVehicleData();
        
        // Set up interval
        intervalId = setInterval(updateVehicleData, 10000);
      }
    };

    initializeVehicleUpdates();

    // Cleanup function
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [stopData, isInitialized, updateVehicleData]); // Added proper dependencies

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  // Early return checks
  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (error) {
    return (
      <div style={{ padding: "20px" }}>
        <h2>Error</h2>
        <p>{error}</p>
      </div>
    );
  }

  if (!isLoaded || !stopData || !isInitialized) {
    return <>
      <DashboardHeader />
      <LoadingScreen />
    </>
  }

  const renderMap = () => {
    const center = {
      lat: parseFloat(stopData.latitude),
      lng: parseFloat(stopData.longitude),
    };

    return (
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={14}
      >
        <Marker
          position={center}
          title={stopData.name}
          icon={{
            url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
            scaledSize: new window.google.maps.Size(40, 40),
          }}
        />
        {directions.map((direction, index) => (
          <DirectionsRenderer
            key={index}
            directions={direction}
            options={{
              suppressMarkers: true,
              polylineOptions: {
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 3,
              },
            }}
          />
        ))}
        {Object.entries(vehicleData).map(([deviceId, data]) => (
          <Marker
            key={deviceId}
            position={data.position}
            title={`Vehicle ${deviceId}`}
            icon={{
              url: "https://maps.google.com/mapfiles/ms/icons/bus.png",
              scaledSize: new window.google.maps.Size(32, 32),
            }}
          />
        ))}
      </GoogleMap>
    );
  };

  const renderTable = () => {
    // Sort vehicle data entries
    const sortedVehicles = Object.entries(vehicleData).sort((a, b) => {
      const distA = parseFloat(a[1].distance);
      const distB = parseFloat(b[1].distance);
      
      // If either value is NaN, move it to the end
      if (isNaN(distA)) return 1;
      if (isNaN(distB)) return -1;
      
      // Normal numeric comparison
      return distA - distB;
    });

    return (
      <>
        <div className="form_header" style={{ marginBottom: '-20px' }}>Vehicles on this Stop</div>
        <div>
          <Table style={{ width: "100%", marginTop: "20px", borderCollapse: "collapse" }}>
            <TableHead>
              <TableRow style={{ backgroundColor: "#f2f2f2" }}>
                <StyledTableCell
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    paddingLeft: '24px'
                  }}
                >
                  Vehicle ID
                </StyledTableCell>
                <StyledTableCell
                  style={{

                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  Route ID
                </StyledTableCell>
                <StyledTableCell
                  style={{

                  padding: "8px",
                  textAlign: "left",
                }}
              >
                ETA
              </StyledTableCell>
              <StyledTableCell
                style={{

                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Distance
              </StyledTableCell>
              <StyledTableCell
                style={{
                  textAlign: "right",
                  padding: '8px',
                  alignContent: 'flex-end'
                }}
              >
                Speed (km/h)
              </StyledTableCell>
            </TableRow>
          </TableHead>

            <TableBody>
              {sortedVehicles.length > 0 ? (
                sortedVehicles.map(([deviceId, data]) => (
                  <TableRow key={deviceId}>
                    <TableCell style={{ paddingLeft: "24px", fontWeight: '400', fontSize: '16px' }}>
                      {deviceId}
                    </TableCell>
                    <TableCell style={{ padding: "8px" }}>
                      {data.routeId}
                    </TableCell>
                    <TableCell style={{ padding: "8px" }}>
                      {formatTime(data.eta)}
                    </TableCell>
                    <TableCell style={{ padding: "8px" }}>
                      {parseFloat(data.distance).toFixed(1)} km
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {data.speed} km/h
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                    No vehicles currently available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </>
    );
  };

  const renderRouteTable = () => (
    <>
      <div
        style={{
          marginLeft: "-20px",
          width: "110%"
        }}
      >
        <DashboardHeader title={`Stops > ${stopData.name}`} />
      </div>
      <div
        style={{
          paddingTop: '32px',
          display: 'flex',
          marginBottom: '32px',
          flexDirection: isLargeScreen ? "row" : "column",
        }}>
        <div style={{ flex: 3 }}> {/* 30% width */}
          {renderStopDetails()}
        </div>
        <div style={{ flex: 7, marginRight: '20px' }}> {/* 70% width */}
          <div style={{ flex: 1, marginRight: '20px' }}>
            <div className="form_header form_title" style={{ marginTop: isLargeScreen ? "0px" : "30px", }}>
              Assigned Routes
            </div>
            <div>
              <table style={{ width: "100%", borderCollapse: "collapse", marginTop: '-20px' }}>
                <thead>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <th
                      style={{
                        padding: "24px",
                        textAlign: "left",
                        background: "rgba(32, 168, 224, 1)",
                        color: "white",
                        fontWeight: '600'
                      }}
                    >
                      Route ID
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        background: 'rgba(32, 168, 224, 1)',
                        textAlign: "right",
                        color: "white",
                        paddingRight: '24px',
                        fontWeight: '600'
                      }}
                    >
                      Route Name
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {stopData.routes.map((route) => (
                    <tr key={route.routeId}>
                      <td style={{ padding: "8px", paddingLeft: '24px' }}>
                        {route.routeId}
                      </td>
                      <td style={{ paddingRight: "20px", textAlign: "right" }}>
                        {route.name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              // totalItems={sortedTableData.length}
              itemsPerPage={10}
              currentPage={1}
              onPageChange={(page) => console.log(page)}
            />
          </div>
        </div>


      </div>
    </>
  );

  const renderStopDetails = () => (

    <div style={{ marginRight: '24px' }}>
      <div className="form_header form_title" style={{ marginTop: '0px' }}>
        Stop Details
      </div>

      <div style={{ marginTop: '32px', lineHeight: '40px' }}>
        <span style={{ fontWeight: "600" }}>Stop ID:</span>
        <span style={{ fontWeight: "400" }}> {stopData.stopId}</span>
        <br />
        <span style={{ fontWeight: "600" }}>Total Routes:</span>
        <span style={{ fontWeight: "400" }}> {stopData.routes.length}</span>
        <br />
        <span style={{ fontWeight: "600" }}>Stop Name:</span>
        <span style={{ fontWeight: "400" }}> {stopData.name}</span>
        <br />
        <span style={{ fontWeight: "600" }}>Longitude:</span>
        <span style={{ fontWeight: "400" }}> {stopData.longitude}</span>
        <br />
        <span style={{ fontWeight: "600" }}>Latitude:</span>
        <span style={{ fontWeight: "400" }}> {stopData.latitude}</span>
      </div>
      <div
        style={{
          display: "flex",
          gap: "2px",
          flexDirection: isLargeScreen ? "row" : "column",
        }}
      >
        <div
          className="customButton_add"
          style={{
            background: "#F9E719",
            color: "black",
            fontSize: "19px",
            whiteSpace: isLargeScreen ? "nowrap" : "normal",
          }}
        >
          View Stop Schedules
        </div>
        <div
          className="customButton_add"
          style={{
            marginLeft: isLargeScreen ? "10px" : "0px",
            background: "rgba(32, 168, 224, 1)",
            color: "white",
            whiteSpace: isLargeScreen ? "nowrap" : "normal",
          }}
        >
          User View
        </div>
      </div>

    </div>

  );

  if (error) {
    return (
      <div style={{ padding: "20px" }}>
        <h2>Error</h2>
        <p>{error}</p>
      </div>
    );
  }



  // Main render
  return (
    <div style={{ marginLeft: '20px', marginRight: '20px' }}>
      {renderRouteTable()}
      {hasMapPermission && renderMap()}
      {renderTable()}
    </div>
  );
};

export default MapPage;
