import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Typography,
  CircularProgress,
  styled,
  tableCellClasses,
} from "@mui/material";
import Organization from "../organization/Organization";
import config from "../../config.json";
import DashboardHeader from "../../components/header/DashboardHeader";
import Pagination from "../../components/pagination/Pagination";
import Navbar from "../../components/navbar/Navbar";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(32, 168, 224, 1)",
    color: "white",
    fontSize: 20,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 24,
  },
}));


const ScheduleSelectionComponent = ({
  vehicleId,
  onSchedulesSelected,
  closeWindow,
  selectedSchedules: parentSelectedSchedules,
}) => {
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedules, setSelectedSchedules] = useState(parentSelectedSchedules || {});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [schedulesResponse] = await Promise.all([
          axios.get(`${config.baseURL}/schedule/organization`, {
            withCredentials: true,
          }),
          ...(vehicleId
            ? [
              axios.get(`${config.baseURL}/vehicle/id`, {
                params: {
                  vehicleId,
                  OrganizationId: 17,
                },
                withCredentials: true,
              }),
            ]
            : []),
        ]);
        console.log(schedulesResponse.data, "-------------------");

        setSchedules(schedulesResponse.data);

        if (vehicleId) {
          const vehicleResponse = await axios.get(
            `${config.baseURL}/vehicle/id`,
            {
              params: {
                vehicleId,
                OrganizationId: 17,
              },
              withCredentials: true,
            }
          );
          const preSelectedSchedules = {};
          vehicleResponse.data.vehicle.vehicleSchedule.forEach((vs) => {
            preSelectedSchedules[vs.scheduleId] = true;
          });
          setSelectedSchedules(preSelectedSchedules);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load schedules. Please try again.");
        setLoading(false);
      }
    };

    fetchData();
  }, [vehicleId]);

  const handleCheckboxChange = (scheduleId) => {
    setSelectedSchedules((prev) => ({
      ...prev,
      [scheduleId]: !prev[scheduleId],
    }));
  };
  const [sortedTableData, setSortedTableData] = useState([]);
  // const handleSubmit = () => {
  //   const selectedScheduleIds = Object.keys(selectedSchedules)
  //     .filter((id) => selectedSchedules[id])
  //     .map((id) => parseInt(id));
  //   onSchedulesSelected(selectedScheduleIds);
  //   closeWindow();
  // };
  const handleSubmit = () => {
    const selectedIds = Object.keys(selectedSchedules).filter((id) => selectedSchedules[id]);
    onSchedulesSelected(selectedIds);
    closeWindow();
  };
  useEffect(() => {
    console.log(selectedSchedules, 'selectedSchedules');
  }, [selectedSchedules]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  return (

    <>
      <div className="Assign_schedule_fullscreen_container">
        <Navbar />
        <div className="Assign_schedule_fullscreen_content">
          <DashboardHeader title="Vehicles > Assign Schedules" />
          <div className="page_container">
            {/* <Typography variant="h4" className="page_title">
        Select Schedules
      </Typography> */}
            <div style={{ overflowX: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell>Start Time</StyledTableCell>
                    <StyledTableCell>End Time</StyledTableCell>
                    <StyledTableCell>Repetition</StyledTableCell>
                    <StyledTableCell>Assign</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {schedules.map((schedule) => (
                    <TableRow key={schedule.id}>
                      <TableCell>{schedule.name}</TableCell>
                      <TableCell>{schedule.description}</TableCell>
                      <TableCell>{new Date(schedule.startTime).toLocaleString()}</TableCell>
                      <TableCell>{new Date(schedule.endTime).toLocaleString()}</TableCell>
                      <TableCell>{schedule.repitation}</TableCell>
                      <TableCell>
                        <Checkbox style={{ color: 'rgba(32, 168, 224, 1)' }}
                          checked={selectedSchedules[schedule.id] || false}
                          onChange={() => handleCheckboxChange(schedule.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

            </div>
            <div className="pagination_container">
              <Pagination totalItems={sortedTableData.length}
                itemsPerPage={10}
                currentPage={1}
                onPageChange={(page) => console.log(page)}
              />
            </div>
            <div className="addbutton_container"
              style={{
                // marginTop: '40px',
                marginBottom: '30px'
              }}>
              <button
                variant="contained"
                color="primary"
                className="customButton_add "
                onClick={handleSubmit}
                style={{ background: 'rgba(242, 242, 243, 1)' }}
              >
                Cancel
              </button>

              <button
                variant="contained"
                color="primary"
                className="customButton_add "
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>

        </div>

      </div>

    </>
  );
};

export default ScheduleSelectionComponent;
