import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import config from '../../config.json';
import DashboardHeader from '../../components/header/DashboardHeader';
import StarIcon from '../../components/starIcon/StarIcon';
import Map from '../../components/map/Map';

async function getSiteById(id) {
  try {
    const response = await axios.get(`${config.baseURL}/site/id?id=${id}`, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.log("Error in fetching site data: ", error);
    throw error;
  }
}

async function createSite(site) {
  try {
    const response = await axios.post(`${config.baseURL}/site`, site, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.log("Error in creating site: ", error);
    throw error;
  }
}

async function updateSite(site) {
  try {
    const response = await axios.put(`${config.baseURL}/site`, site, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.log("Error in updating site: ", error);
    throw error;
  }
}

async function assignVehiclesToSite(id, vehicleIds) {
  try {
    const response = await axios.put(`${config.baseURL}/site/vehicles`, { siteId: id, vehicleIds }, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.log("Error in assigning vehicles to site: ", error);
    throw error;
  }
}

async function getAllTheVehicles() {
  try {
    const response = await axios.get(`${config.baseURL}/vehicle`, {
      withCredentials: true
    });
    return response.data.vehicles;
  } catch (error) {
    console.log("Error in fetching vehicles: ", error);
    throw error;
  }
}

const AddSite = () => {
  const { siteId } = useParams();
  const navigate = useNavigate();
  const [site, setSite] = useState({
    siteId: '',
    name: '',
    description: '',
    organizationId: 1,
    sitCoordinates: [
      { longitude: 0, latitude: 0 }
    ],
    radius: ''
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [stops, setStops] = useState([]);
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    if (siteId) {
      setIsUpdating(true);
      getSiteById(siteId)
        .then((data) => setSite(data))
        .catch((err) => console.log(err));
    }
    getAllTheVehicles()
      .then((data) => setVehicles(data))
      .catch((err) => console.log(err));
  }, [siteId]);

  

  const handleSubmit = (event) => {
    event.preventDefault();
    const action = isUpdating ? updateSite : createSite;
    action(site)
      .then((res) => {
        if (selectedVehicles.length > 0) {
          return assignVehiclesToSite(res.id, selectedVehicles);
        }
        return res;
      })
      .then((res) => {
        console.log(res);
        navigate('/path-to-redirect');  // Change this to the appropriate path
      })
      .catch((err) => console.log(err));
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSite((prevSite) => ({
      ...prevSite,
      [name]: value
    }));
  }

  const handleCoordinatesChange = (index, key, value) => {
    const newCoordinates = [...site.sitCoordinates];
    newCoordinates[index][key] = value;
    setSite((prevSite) => ({
      ...prevSite,
      sitCoordinates: newCoordinates
    }));
  }

  const addCoordinate = () => {
    setSite((prevSite) => ({
      ...prevSite,
      sitCoordinates: [...prevSite.sitCoordinates, { longitude: 0, latitude: 0 }]
    }));
  }

  const handleVehicleSelection = (event) => {
    const { options } = event.target;
    const selected = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    setSelectedVehicles(selected);
  }

  return (
    <>
    <DashboardHeader
  title={<h1>{isUpdating ? 'Update Site' : 'Add Site'}</h1>}/>

    <div className='page_container'>
     <div className='form_header'>Site Details</div>

     
      <form onSubmit={handleSubmit}>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px',marginTop:'24px' }}>
  <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
    <label style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        Site ID <StarIcon style={{ fontSize: '16px', color: 'red' }} />
      </span>
      <input
        type="text"
        name="siteId"
        value={site.siteId}
        onChange={handleInputChange}
        disabled={isUpdating}
        placeholder="Type Site ID"
        style={{
          width: '100%',
          padding: '8px',
          borderRadius: '8px',
          border: '1px solid #000',
        }}
      />
    </label>
    <label style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        Site Name <StarIcon style={{ fontSize: '16px', color: 'red' }} />
      </span>
      <input
        type="text"
        name="name"
        value={site.name}
        onChange={handleInputChange}
        placeholder="Type Site Name"
        style={{
          width: '100%',
          padding: '8px',
          borderRadius: '8px',
          border: '1px solid #000',
        }}
      />
    </label>
  </div>

  {/* Row 2: Site Description */}
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <label>
      <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        Site Description
      </span>
      <textarea
        name="description"
        value={site.description}
        onChange={handleInputChange}
        placeholder="Write Description"
        rows="4"
        style={{
          width: '100%',
          padding: '8px',
          borderRadius: '8px',
          border: '1px solid #000',
        }}
      />
    </label>
  </div>
</div>
<div className='form_header'>Site Boundary</div>

<div className='stop_strip' style={{
  padding: '16px 24px',
  display: 'flex',
  justifyContent: 'flex-start', 
  gap: '16px', 
}}>
  <label className="radio-option" style={{
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
  }}>
    <input
      type="radio"
      name="boundaryType"
      value="circular"
      style={{
        width: '16px',
        height: '16px',
        accentColor: '#000',
      }}
    />
    Circular
  </label>
  <label className="radio-option" style={{
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
  }}>
    <input
      type="radio"
      name="boundaryType"
      value="custom"
      style={{
        width: '16px',
        height: '16px',
        accentColor: '#000',
      }}
    />
    Custom
  </label>
</div>



        {/* <label>
          Organization Id:
          <input type="number" name="organizationId" value={site.organizationId} onChange={handleInputChange} />
        </label> */}
        


        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px',marginTop:'24px' }}>
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}>
    <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
      <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        Central Coordinates <StarIcon style={{ fontSize: '16px', color: 'red' }} />
      </span>
      <div style={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
        <input
          type="number"
          placeholder="Longitude"
          value={site.sitCoordinates[0]?.longitude || ''}
          onChange={(e) => handleCoordinatesChange(0, 'longitude', e.target.value)}
          disabled={site.radius !== ''}
          style={{
            flex: 1,
            padding: '8px',
            borderRadius: '8px',
            border: '1px solid #000',
          }}
        />
        <input
          type="number"
          placeholder="Latitude"
          value={site.sitCoordinates[0]?.latitude || ''}
          onChange={(e) => handleCoordinatesChange(0, 'latitude', e.target.value)}
          disabled={site.radius !== ''}
          style={{
            flex: 1,
            padding: '8px',
            borderRadius: '8px',
            border: '1px solid #000',
          }}
        />
      </div>
    </div>

    {/* Radius Section */}
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        Radius (Kilometre) <StarIcon style={{ fontSize: '16px', color: 'red' }} />
      </span>
      <input
        type="number"
        name="radius"
        placeholder="Type Radius"
        value={site.radius}
        onChange={handleInputChange}
        style={{
          width: '100%',
          padding: '8px',
          borderRadius: '8px',
          border: '1px solid #000',
          marginTop: '8px',
        }}
      />
    </div>
  </div>
</div>

<div className="o-map" style={{marginTop:'16px'}}>
              <Map stops={stops} routes={routes} />
            </div>



        <div className='form_header'>Vehicles on this Site</div>
        <div className='stop_strip'style={{padding:'16px 24px'}}>Existing Vehicles</div>
        <label>
          Assign Vehicles:
          <select multiple={true} value={selectedVehicles} onChange={handleVehicleSelection}>
            {vehicles.map((vehicle) => (
              <option key={vehicle.id} value={vehicle.id}>
                {vehicle.name}
              </option>
            ))}
          </select>
        </label>
        <br />
       
        <div className="addbutton_container">
  
  <button className='customButton_add'style={{background:"rgba(242, 242, 243, 1)"}} > Cancel </button>
  <button className='customButton_add'>Submit</button>
  </div>
      </form>
    </div>
    </>
  )
}

export default AddSite;
