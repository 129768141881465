import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useLocation } from "react-router-dom";
import {
  GoogleMap,
  LoadScript,
  useLoadScript,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import config from "../../config.json";
import DashboardHeader from "../../components/header/DashboardHeader";
import {
  Grid,
  Paper,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CalendarToday, Hail } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import busIcon from "../../images/bus-svgrepo-com.svg";
import RouteVehicleStatusTable from "./RouteVehicleStatusTable";

const styles = {
  container: {
    padding: "32px",
  },
  cardSection: {
    marginBottom: "32px",
  },
  card: {
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "12px",
    color: "white",
  },
  vehicleCard: {
    backgroundColor: "rgba(88, 0, 191, 1)",
  },
  stopCard: {
    backgroundColor: "rgba(0, 178, 191, 1)",
  },
  scheduleCard: {
    backgroundColor: "rgba(191, 80, 0, 1)",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    fontSize: "40px",
    marginTop: "10px",
  },
  mapContainer: {
    width: "100%",
    height: "400px",
    marginBottom: "32px",
  },
  tableContainer: {
    marginTop: "32px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "-7px",
  },
  tableHeader: {
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f5f5f5",
  },
  tableCell: {
    border: "1px solid #ddd",
    padding: "8px",
  },
  noData: {
    textAlign: "center",
    padding: "16px",
  },
  formHeader: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    marginBottom: "16px",
  },
  // mapContainerStyle: { width: '100%',
  //   height: '400px' }
};

const libraries = ["geometry"];

const RouteTrackingComponent = () => {
  const location = useLocation();
  const routeData = location.state?.selectedRouteData;
  const [vehicleData, setVehicleData] = useState({});
  const [mapLoaded, setMapLoaded] = useState(false);
  const [directions, setDirections] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const intervalRef = useRef(null);
  const routeBoundsRef = useRef(null);
  const routePathRef = useRef(null);
  const [mapError, setMapError] = useState(null);
  const [currentVehicleIndex, setCurrentVehicleIndex] = useState(0);
  const [map, setMap] = useState(null);

  // Use the useLoadScript hook
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleMapsApiKey || "YOUR_API_KEY_HERE",
    libraries,
  });

  // // Map load callback
  // const onLoad = useCallback((map) => {
  //   setMap(map);
  // }, []);

  // const onUnmount = useCallback(() => {
  //   setMap(null);
  // }, []);

  const getVehicleIcon = useCallback(() => {
    if (!mapLoaded || !window.google) return null;

    if (busIcon) {
      return {
        url: busIcon,
        scaledSize: new window.google.maps.Size(32, 32),
        anchor: new window.google.maps.Point(16, 16),
      };
    } else {
      return {
        url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
        scaledSize: new window.google.maps.Size(32, 32),
        anchor: new window.google.maps.Point(16, 16),
      };
    }
  }, [mapLoaded]);

  useEffect(() => {
    console.log("Route data received:", routeData);
    if (!routeData) {
      console.error("No route data available");
      return;
    }
    if (!routeData.stops || routeData.stops.length === 0) {
      console.error("No stops data available");
      return;
    }
    if (!routeData.vehicles || routeData.vehicles.length === 0) {
      console.warn("No vehicles data available");
    }
  }, [routeData]);

  const center = useMemo(() => {
    if (routeData?.routeCoordinates?.[0]) {
      return {
        lat: parseFloat(routeData.routeCoordinates[0].latitude),
        lng: parseFloat(routeData.routeCoordinates[0].longitude),
      };
    }
    if (routeData?.stops?.[0]) {
      return {
        lat: parseFloat(routeData.stops[0].latitude),
        lng: parseFloat(routeData.stops[0].longitude),
      };
    }
    return { lat: 0, lng: 0 }; // Default center
  }, [routeData]);

  const stopMarkers = routeData
    ? routeData.stops.map((stop) => ({
      position: {
        lat: parseFloat(stop.latitude),
        lng: parseFloat(stop.longitude),
      },
      label: stop.name,
    }))
    : [];

  const snapToRoute = (position) => {
    if (!routePathRef.current) return position;

    const point = new window.google.maps.LatLng(position.lat, position.lng);
    let closestPoint = point;
    let minDistance = Infinity;

    for (let i = 0; i < routePathRef.current.length - 1; i++) {
      const start = routePathRef.current[i];
      const end = routePathRef.current[i + 1];
      const projectedPoint = window.google.maps.geometry.spherical.interpolate(
        start,
        end,
        0.5
      );
      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          point,
          projectedPoint
        );

      if (distance < minDistance) {
        closestPoint = projectedPoint;
        minDistance = distance;
      }
    }

    return { lat: closestPoint.lat(), lng: closestPoint.lng() };
  };

  const calculateRouteDirections = useCallback(() => {
    if (!window.google || !stopMarkers.length) return;

    const directionsService = new window.google.maps.DirectionsService();
    const origin = stopMarkers[0].position;
    const destination = stopMarkers[stopMarkers.length - 1].position;
    const waypoints = stopMarkers.slice(1, -1).map((marker) => ({
      location: new window.google.maps.LatLng(
        marker.position.lat,
        marker.position.lng
      ),
      stopover: true,
    }));

    const request = {
      origin: new window.google.maps.LatLng(origin.lat, origin.lng),
      destination: new window.google.maps.LatLng(
        destination.lat,
        destination.lng
      ),
      waypoints: waypoints,
      travelMode: window.google.maps.TravelMode.DRIVING,
      optimizeWaypoints: false,
    };

    directionsService.route(request, (result, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        console.log("Directions received:", result); // Debug log
        setDirections(result);
        routePathRef.current = result.routes[0].overview_path;

        // Set bounds after getting directions
        if (mapInstance && !routeBoundsRef.current) {
          const bounds = new window.google.maps.LatLngBounds();
          result.routes[0].overview_path.forEach((point) =>
            bounds.extend(point)
          );
          stopMarkers.forEach((marker) => bounds.extend(marker.position));
          routeBoundsRef.current = bounds;
          mapInstance.fitBounds(bounds);
        }
      } else {
        console.error("Directions request failed:", status); // Debug log
        setError(`Error fetching directions: ${status}`);
      }
    });
  }, [stopMarkers, mapInstance]);

  // Fetch data for a single vehicle
  const fetchVehicleData = async (vehicle) => {
    if (!vehicle) return null;

    try {
      // Get vehicle location
      const locationResponse = await fetch(
        `${config.baseURL}/location/latest/${vehicle.device_id}`
      );
      if (!locationResponse.ok) {
        throw new Error(`Location API error: ${locationResponse.status}`);
      }
      const locationData = await locationResponse.json();

      // Validate location data
      if (!locationData?.latitude || !locationData?.longitude) {
        console.warn(`Invalid location data for vehicle ${vehicle.device_id}`);
        return null;
      }

      const position = {
        lat: parseFloat(locationData.latitude),
        lng: parseFloat(locationData.longitude),
      };

      const snappedPosition = snapToRoute(position);

      // Get distance and ETA to destination
      const destination = stopMarkers[stopMarkers.length - 1].position;
      const disTimeResponse = await fetch(
        `${config.baseURL}/distance/time/stop`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            vehicleId: vehicle.device_id,
            longitude: destination.lng,
            latitude: destination.lat,
            isExtreamPoint: true,
          }),
        }
      );

      if (!disTimeResponse.ok) {
        throw new Error(`Distance/Time API error: ${disTimeResponse.status}`);
      }

      const disTimeData = await disTimeResponse.json();

      // Return formatted vehicle data
      return {
        device_id: vehicle.device_id,
        position: snappedPosition,
        speed: locationData.Speed
          ? parseFloat(locationData.Speed).toFixed(1)
          : 0,
        distance: disTimeData.distance || "N/A",
        eta: disTimeData.eta || "N/A",
        lastUpdate: new Date().toLocaleTimeString(),
        temperature: locationData.Temprature,
        satellites: locationData.numberOfSatellites,
      };
    } catch (error) {
      console.error(
        `Error fetching data for vehicle ${vehicle.device_id}:`,
        error
      );
      return null;
    }
  };
  const updateVehicleData = async () => {
    if (!routeData?.vehicles?.length) return;

    setLoading(true);
    try {
      const vehicle = routeData.vehicles[currentVehicleIndex];
      const data = await fetchVehicleData(vehicle);

      if (data) {
        setVehicleData((prev) => ({
          ...prev,
          [data.device_id]: data,
        }));
      }

      // Rotate to next vehicle
      setCurrentVehicleIndex((prev) => (prev + 1) % routeData.vehicles.length);

      setLastUpdateTime(new Date().toLocaleTimeString());
    } catch (error) {
      console.error("Error updating vehicle data:", error);
      setError("Failed to update vehicle data");
    } finally {
      setLoading(false);
    }
  };

  // Set up polling interval for vehicle updates
  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (routeData) {
      intervalRef.current = setInterval(updateVehicleData, 5000); // Update every 5 seconds
      updateVehicleData(); // Initial fetch
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [routeData, currentVehicleIndex]);

  useEffect(() => {
    console.log("Vehicle data updated:", vehicleData);
  }, [vehicleData]);

  useEffect(() => {
    if (mapLoaded && routeData && mapInstance) {
      console.log("Calculating directions..."); // Debug log
      calculateRouteDirections();
    }
  }, [mapLoaded, routeData, mapInstance]);

  useEffect(() => {
    // Clear any existing interval
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // Only start interval if we have route data
    if (routeData) {
      intervalRef.current = setInterval(() => {
        console.log("Interval triggered at:", new Date().toLocaleTimeString());
        fetchVehicleData();
      }, 10000); // 10 seconds
    }

    // Cleanup function
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [routeData, fetchVehicleData]);

  const onLoad = useCallback((map) => {
    setMapLoaded(true);
    setMapInstance(map);
  }, []);

  const formatTime = (seconds) => {
    if (!seconds || seconds <= 0) return "N/A";

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    } else if (minutes > 0) {
      return `${minutes}m ${secs}s`;
    } else {
      return `${secs}s`;
    }
  };

  const formatDistance = (distance) => {
    if (!distance) return "N/A";
    return `${parseFloat(distance).toFixed(1)} km`;
  };

  useEffect(() => {
    if (routeData?.vehicles) {
      console.log("Initial vehicle data:", routeData.vehicles);
    }
  }, [routeData]);

  if (!routeData) {
    return (
      <div style={styles.noData}>
        <Typography variant="h6">No route data available</Typography>
      </div>
    );
  }

  if (error) {
    return (
      <div style={styles.noData}>
        <Typography color="error">{error}</Typography>
      </div>
    );
  }
  if (loadError) {
    return (
      <div style={styles.noData}>
        <Typography color="error">Failed to load Google Maps</Typography>
        <Typography color="error">{mapError}</Typography>
        <button onClick={() => window.location.reload()}>Refresh</button>
      </div>
    );
  }
  if (!isLoaded) {
    return (
      <div style={styles.noData}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <DashboardHeader
        title={`Routes > Monitor: ${routeData?.name || "Unknown Route"}`}
      />
      <div className="page_container">
        <Grid
          container
          spacing={2}
          marginTop={"2px"}
          direction={{ xs: "column", sm: "row" }}
          alignItems="stretch"
        >
          {/* Vehicles Card */}
          <Grid item xs={12} sm={4}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "rgba(88, 0, 191, 1)",
                color: "white",
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "12px",
                height: "140px",
              }}
            >
              {/* Left side: Vehicles text and car icon */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">Vehicles</Typography>
                <DirectionsCarIcon
                  sx={{ fontSize: "30px", marginTop: "40px" }}
                />
              </div>

              {/* Right side: Arrow icon and number */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <IconButton sx={{ padding: 0 }}>
                  <ArrowForwardIosIcon sx={{ color: "white" }} />
                </IconButton>
                <Typography variant="h5" sx={{ marginTop: "40px" }}>
                  {routeData.vehicles.length}
                </Typography>
              </div>
            </Paper>
          </Grid>

          {/* Stop Card */}
          <Grid item xs={12} sm={4}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "rgba(0, 178, 191, 1)",
                color: "white",
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "12px",
                height: "140px",
              }}
            >
              {/* Left side: Stops text and icon */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">Stops</Typography>
                <Hail sx={{ fontSize: "40px", marginTop: "40px" }} />
              </div>

              {/* Right side: Arrow icon and number of stops */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <IconButton sx={{ padding: 0 }}>
                  <ArrowForwardIosIcon sx={{ color: "white" }} />
                </IconButton>
                <Typography variant="h5" sx={{ marginTop: "40px" }}>
                  {routeData.stops.length}
                </Typography>
              </div>
            </Paper>
          </Grid>

          {/* Schedule Card */}
          <Grid item xs={12} sm={4}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "rgba(191, 80, 0, 1)",
                color: "white",
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "12px",
                height: "140px",
                fontSize: "24px",
              }}
            >
              {/* Left side: Schedules text and icon */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">Schedules</Typography>
                <CalendarToday sx={{ fontSize: "40px", marginTop: "40px" }} />
              </div>

              {/* Right side: Arrow icon and number (if needed) */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <IconButton sx={{ padding: 0 }}>
                  <ArrowForwardIosIcon sx={{ color: "white" }} />
                </IconButton>
                <Typography variant="h5" sx={{ marginTop: "40px" }}>
                  {routeData.schedules.length}{" "}
                  {/* Replace with actual count if available */}
                </Typography>
              </div>
            </Paper>
          </Grid>
        </Grid>

        <div className="form_header" style={{ marginBottom: "0px" }}>
          Live View
        </div>
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "400px" }}
          center={center}
          zoom={14}
          onLoad={onLoad}
        >
          {directions && (
            <DirectionsRenderer
              directions={directions}
              options={{
                suppressMarkers: true,
                polylineOptions: {
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 3,
                },
              }}
            />
          )}

          {stopMarkers.map((marker, index) => (
            <Marker
              key={index}
              position={marker.position}
              label={marker.label}
              icon={
                index === 0 || index === stopMarkers.length - 1
                  ? {
                    url: index === 0
                      ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                      : "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                    scaledSize: new window.google.maps.Size(40, 40),
                  }
                  : undefined
              }
            />
          ))}

          {Object.entries(vehicleData).map(([deviceId, data]) => (
            <Marker
              key={deviceId}
              position={data.position}
              icon={{
                url: busIcon,
                scaledSize: new window.google.maps.Size(32, 32),
                anchor: new window.google.maps.Point(16, 16),
                labelOrigin: new window.google.maps.Point(16, -10)
              }}
              label={{
                text: deviceId,
                color: '#000000',
                fontSize: '14px',
                fontWeight: 'bold'
              }}
            />
          ))}
        </GoogleMap>
        <div className="form_header" style={{ fontSize: "28px" }}>
          Vehicle Status
        </div>

        {/* Vehicle Status Table */}
        {/* <Typography variant="h5" sx={{ mb: 2 }}>Vehicle Status</Typography> */}
        {/* <TableContainer component={Paper} elevation={3}>
          {loading && Object.keys(vehicleData).length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <CircularProgress />
            </div>
          ) : error ? (
            <Typography color="error" sx={{ p: 2 }}>
              {error}
            </Typography>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      fontSize: "1rem",
                    }}
                  >
                    Vehicle ID
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      fontSize: "1rem",
                    }}
                  >
                    Distance
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      fontSize: "1rem",
                    }}
                  >
                    ETA
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      fontSize: "1rem",
                    }}
                  >
                    Speed (km/h)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      fontSize: "1rem",
                    }}
                  >
                    Temperature (°C)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      fontSize: "1rem",
                    }}
                  >
                    Satellites
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(vehicleData).length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No vehicle data available
                    </TableCell>
                  </TableRow>
                ) : (
                  Object.values(vehicleData)
                    .sort((a, b) => {
                      // Convert distance strings to numbers, handling any non-numeric values
                      const distA = parseFloat(a.distance);
                      const distB = parseFloat(b.distance);
                      
                      // If either value is NaN (not a number), handle the sorting
                      if (isNaN(distA) && isNaN(distB)) return 0; // Both are non-numeric
                      if (isNaN(distA)) return 1; // Move a to the end
                      if (isNaN(distB)) return -1; // Move b to the end
                      
                      return distA - distB; // Normal numeric comparison
                    })
                    .map((data) => (
                      <TableRow
                        key={data.device_id}
                        sx={{
                          "&:nth-of-type(odd)": {
                            backgroundColor: "#fafafa",
                          },
                          "&:hover": {
                            backgroundColor: "#f0f0f0",
                          },
                        }}
                      >
                        <TableCell sx={{ fontWeight: "500" }}>
                          {data.device_id}
                        </TableCell>
                        <TableCell>{formatDistance(data.distance)}</TableCell>
                        <TableCell>{formatTime(data.eta)}</TableCell>
                        <TableCell>{data.speed}</TableCell>
                        <TableCell>{data.temperature || "N/A"}</TableCell>
                        <TableCell>{data.satellites || "N/A"}</TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer> */}

        <RouteVehicleStatusTable
          loading={loading}
          error={error}
          vehicleData={vehicleData}
          formatDistance={formatDistance}
          formatTime={formatTime}
        />

        {/* Last Update Time */}
        {lastUpdateTime && (
          <Typography
            variant="caption"
            sx={{
              display: "block",
              textAlign: "right",
              mt: 1,
              color: "text.secondary",
            }}
          >
            Last updated: {lastUpdateTime}
          </Typography>
        )}
      </div>
    </>
  );
};

export default RouteTrackingComponent;
