import React, { useEffect, useState } from 'react'
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import "./driver.css";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { useTheme } from "@mui/material/styles";
import Pagination from '../../components/pagination/Pagination';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircleIcon from '@mui/icons-material/Circle'
import ListItemIcon from '@mui/material/ListItemIcon';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,    
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  TextField,
  } from "@mui/material";
import { useNavigate } from 'react-router';

// Function to create a new row data object
  function createData(id,image_url,driver_id,DriverName,email,vehicle,DL_number,phone) {
	return { id,image_url,driver_id,DriverName,email,vehicle, DL_number,phone };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(32, 168, 224, 1)",
      color: "white",
      fontSize: 20,
      fontWeight: 600,
	  paddingLeft:0
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 24,
    },
  }));
// Define columns for the table
  const columns = [
	{ label: "",id:"img" },
  { label: "Driver ID",id:"driverId" },
  { label: "Driver Name",sortable:true,id: "driverName" },
  { label: "Vehicle",  id: "vehicle" },
  { label: "DLNumber",  id: "DL_number" },
  { label: "Phone",  id: "phone" },
  { label: "Actions", id: "Actions" }
  
  ];

  // Convert rows to the required format
  const getRows = (rows) =>
  rows.map((item, index) =>
	createData(
	  item.id,
	  item.image_url,
	  item.driver_id,
	  item.DriverName,
	  item.email,
	  item.vehicle,
	  item.DL_number,
	  item.phone,
	)
  );
  
function DriverTable({drivers,onSortChange,sortParam,sortDirection,allVehicles}) {
  const theme=useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setitemPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedDriverId,setSelectedDriverId]=useState(null);
  const [isAssignVehicleModalOpen, setIsAssignVehicleModalOpen] = useState(false);
  const navigate=useNavigate();
	
	 // Handle sorting of table columns
  
  const open = Boolean(anchorEl);

  // handling icon button open
  const handleOpenMenu = (event, id) => {
	event.preventDefault();
	setSelectedDriverId(id)
	// console.log("selected user id ",selectedUserId)
	setAnchorEl(event.currentTarget);
  };
  
// handling delete modal open
  const openDeleteModal = (id) => {
	id=selectedDriverId
	console.log("id to delete the user",id)
	setIsDeleteModalOpen(true);
	setAnchorEl(null)
};

// handling delete modal dailog close
const handleDeleteDialogClose = () => {
  setIsDeleteModalOpen(false);
};
// for deleting the driver
const handleDelete=()=>{

}
  const handleCloseMenu = () => {
  
	setAnchorEl(null);
  };

  // open the all vehicle list 
  const openAssignVehicleModal = (id) => {
	setSelectedDriverId(id);
	setIsAssignVehicleModalOpen(true);
	setAnchorEl(null);
  };
  // close vehicle list 
  const handleAssignVehicleDialogClose = () => {
	setIsAssignVehicleModalOpen(false);
  };

  const styles = {
  header: { backgroundColor: theme.palette.primary.main, color: "white" ,fontSize:"18px"},
  };

//   // calculating pagination 
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  const totalCount = parseInt(drivers?.length);
  const totalPages = Math.ceil(totalCount / itemsPerPage)||1;

  // handle pagination page change
  const handlePageChange = (pageNumber) => {
	setCurrentPage(pageNumber);
  };
  const rows = drivers?.slice(startIndex,endIndex)
  return (
	<>
	
	<TableContainer component={Paper}>
	  <Table>
		<TableHead>
		<TableRow>
				{columns.map((column) => (
				  <StyledTableCell
					align="justify"
					key={column.id}
					onClick={() => onSortChange(column.id)}
				  >
					{column.label}
					{column.sortable && (
					  <>
						{sortParam === column.id && sortDirection === 'asc' && <ArrowDropUp style={{ verticalAlign: "middle", cursor: "pointer" ,color:"rgba(24, 48, 89, 1)" }} />}
						{sortParam === column.id && sortDirection === 'desc' && <ArrowDropDown style={{ verticalAlign: "middle", cursor: "pointer",color:"rgba(24, 48, 89, 1)" }}/>}
						{sortParam !== column.id && <ArrowDropDown style={{color:"rgba(24, 48, 89, 1)" }} />}
					  </>
					)}
				  </StyledTableCell>
				))}
			  </TableRow>
		</TableHead>
		<TableBody style={{padding:"0px",}} className='TableBody-root'>
		  {getRows(rows).map((row)=>(
			<TableRow  
			key={row.id}
			className="MuiTableRow-root"
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
			>
			  <TableCell align='justify'style={{paddingLeft:'20px'}}>
	  {row.image_url ? (
		  <>
			  <img src={row.image_url} alt={`Driver ${row.driver_id}`} style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: "10px" }} />
			  {row.driver_id}
		  </>
	  ) : (
		  'no img'
	  )}
  </TableCell>   
				
  <TableCell style={{fontWeight:"600",fontSize:"16px",paddingLeft:'20px'}}>{row.driver_id}</TableCell>
				  <TableCell>
				  <div>{row.DriverName}</div>
				   <div>{row.email}</div>
					   </TableCell>
				  <TableCell align='justify' style={{paddingLeft:'20px'}}>{row.vehicle}</TableCell>
				  <TableCell align='justify' style={{paddingLeft:'20px'}} >{row.DL_number}</TableCell>
				  <TableCell  align='justify' style={{paddingLeft:'20px'}}>{row.phone}</TableCell>
			  
			  <TableCell >
  
	<IconButton
	  aria-label="action-btn"
	  id="action-btn"
	  onClick={(event) => handleOpenMenu(event, row.id)}
	>
	  <MoreHorizIcon  />
	</IconButton >
	{anchorEl && (
	  <Menu
		id="action-menu"
		anchorEl={anchorEl}
		open={open}
		onClose={handleCloseMenu}
		anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
		MenuListProps={{
		  "aria-labelledby": "action-btn",
		}}
		PaperProps={{
		  style: {
			backgroundColor:"#F1F1F1",
			borderRadius:"8px",
			boxShadow:"none"
		  },
		}}
	  >
	  <MenuItem >
		  <ListItemIcon>
			<CircleIcon />
		  </ListItemIcon>
		  Update
		</MenuItem>
		<MenuItem onClick={()=>openAssignVehicleModal(row.id)} >
		  <ListItemIcon>
			<CircleIcon />
		  </ListItemIcon>
		  Assign Vehicle
		</MenuItem>
		<MenuItem onClick={() => openDeleteModal( row.id)} >
		  <ListItemIcon>
			<CircleIcon />
		  </ListItemIcon>
		  Delete
		</MenuItem>
	  </Menu>
	)}
  </TableCell>
  
			</TableRow>
			)
		  )}  
		</TableBody>
	  </Table>
	</TableContainer>



	<Pagination
		  currentPage={currentPage}
		  totalPages={totalPages}
		  onPageChange={handlePageChange}
		/>


	<Dialog
		  open={isDeleteModalOpen}
		  onClose={handleDeleteDialogClose}
		  PaperProps={{
			style: {
			  backgroundColor: "#F3F3F3 ",
			  width: "518px",
			  height:"350px",
			  marginLeft:"300px",
			  borderRadius:"20px",
			  maxWidth: "600px",
			},
		  }}
		>
		  <DialogTitle style={{ fontWeight:"600",fontSize:"32px", height:"85px",marginLeft:"40px",marginTop:"40px"}}>
			Delete !
			<DialogContentText style={{fontWeight:"400px",fontSize:"24px"}}> Do you want to proceed?</DialogContentText>
		  </DialogTitle>
		  <DialogActions style={{display:"flex", justifyContent:"center",marginTop:"50px", paddingBottom:"40px", gap:"40px"}}>
			<Button
			  onClick={handleDeleteDialogClose}
			  color="primary"
			  style={{ background: "#FFFFFF",width:"140px",height:"60px",fontWeight:"600",textTransform:"none" ,fontSize:"24px"}}
			  
			>
			  Cancel
			</Button>
			<Button
			  
			  color="primary"
			  variant="contained"
			  autoFocus
			  onClick={handleDelete}
			  style={{ fontWeight:"600" ,textTransform:"none", width:"140px",height:"60px",fontSize:"24px"}}
			>
			  Delete
			</Button>
		  </DialogActions>
		</Dialog>

		<Dialog
		  open={isAssignVehicleModalOpen}
		  onClose={handleAssignVehicleDialogClose}
		  PaperProps={{ style: { backgroundColor: "#FFFFFF", width: "220px", height: "260px", marginLeft: "300px", borderRadius: "16px", maxWidth: "600px" } }}
		>
		  <DialogTitle >
			Assign Vehicle <KeyboardArrowUpIcon/> 
		  </DialogTitle>
		  <Divider/>
			 <div className='add_icon'>
			 <AddCircleOutlineIcon style={{marginTop:"10px",marginLeft:'10px'}} />
			 <h4 style={{marginLeft:"10px",marginTop:"10px",paddingLeft:"5px",fontWeight:"550",fontSize:"16px"}}>    Add New Vehicle</h4> 
			 </div>
		  <DialogContent style={{paddingLeft:"0px",margin:"0"}}>
			<List style={{marginTop:"-25px"}}>
			  {allVehicles.map((vehicle) => (
				<ListItem
				  key={vehicle.id} 
				>
				  <ListItemText primary= {vehicle.name} />
				</ListItem>
			  ))} 
			</List>
		  </DialogContent>
		</Dialog>
  </> 
	)
}

export default DriverTable