import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, useLoadScript, Marker, DirectionsRenderer } from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
import config from "../../config.json";
import axios from "axios";
import DashboardHeader from "../../components/header/DashboardHeader";
import BusIcon from "../../images/bus-svgrepo-com.svg";
import { CircularProgress } from "@mui/material";
import RouteDetails from "./RouteDetails";
import StopsTable from "./StopsTable";
import NextStopTable from "./NextStopTable";

const mapContainerStyle = {
  width: '100%',
  height: '300px'
};

const mapStyles = [
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "transit",
    elementType: "labels.icon",
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ visibility: "simplified" }, { color: "black" }],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [{ visibility: "on" }, { color: "black" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ visibility: "on" }, { color: "black" }],
  },
];

const libraries = ["places", "directions"];

const DeviceLocationMap = () => {
  const location = useLocation();
  const [position, setPosition] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [route, setRoute] = useState(null);
  const [stops, setStops] = useState([]);
  const [userState, setUserState] = useState(null);
  const [hasMapPermission, setHasMapPermission] = useState(false);
  const [speed, setSpeed] = useState(null);
  const [directions, setDirections] = useState(null);
  const [stopDetails, setStopDetails] = useState({});
  const [previousPosition, setPreviousPosition] = useState(null);
  const [heading, setHeading] = useState(0);
  const [map, setMap] = useState(null);

  // Load Google Maps Script
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleMapsApiKey,
    libraries,
  });

  // Map load callback
  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  // Initialize user state and permissions
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('userState'));
    if (user) {
      setUserState(user);
      const mapPermission = user.features.some(
        (feature) => feature.api_path === '/api/stop/organization' && feature.action === 'GET'
      );
      setHasMapPermission(mapPermission);
    }
  }, []);

  // Fetch route data
  const getRouteById = async (id) => {
    try {
      const resp = await axios.get(`${config.baseURL}/route/id`, {
        params: { id },
        withCredentials: true,
      });
      return resp.data;
    } catch (error) {
      console.error("Error fetching route:", error);
      return null;
    }
  };

  // Fetch stops for route
  const fetchStopsForRoute = async (route) => {
    if (!route?.routeCoordinates) return [];

    const stops = [];
    for (const coordinate of route.routeCoordinates) {
      try {
        const stopsResponse = await axios.get(
          `${config.baseURL}/stop/organization/location`,
          {
            params: {
              organizationId: 17,
              longitude: coordinate.longitude,
              latitude: coordinate.latitude,
            },
            withCredentials: true,
          }
        );
        stops.push(stopsResponse.data);
      } catch (error) {
        console.error("Error fetching stops:", error);
      }
    }
    return stops;
  };

  // Initialize vehicle and route data
  useEffect(() => {
    const fetchVehicleData = async () => {
      if (!location.state) return;

      try {
        const response = await axios.get(
          `${config.baseURL}/vehicle/id`,
          {
            params: {
              vehicleId: location.state,
              organizationId: 1,
            },
            withCredentials: true,
          }
        );

        const vehicleData = response.data.vehicle;
        setVehicle(vehicleData);

        const routeData = await getRouteById(vehicleData.routeId);
        if (routeData) {
          setRoute(routeData);
          const stopsData = await fetchStopsForRoute(routeData);
          setStops(stopsData);
        }
      } catch (error) {
        console.error("Error fetching vehicle data:", error);
      }
    };

    fetchVehicleData();
  }, [location.state]);

  // Update vehicle location
  useEffect(() => {
    if (!vehicle?.device_id) return;

    const fetchLocation = async () => {
      try {
        const response = await fetch(
          `${config.baseURL}/location/latest/${vehicle.device_id}`
        );
        const data = await response.json();

        if (position) {
          setPreviousPosition(position);
        }

        const newPosition = {
          lat: parseFloat(data?.latitude),
          lng: parseFloat(data?.longitude),
        };

        if (position) {
          const newHeading = calculateHeading(
            position.lat,
            position.lng,
            newPosition.lat,
            newPosition.lng
          );
          setHeading(newHeading);
        }

        setPosition(newPosition);
        setSpeed(parseFloat(data?.Speed) || 0);
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };

    fetchLocation();
    const intervalId = setInterval(fetchLocation, 10000);
    return () => clearInterval(intervalId);
  }, [vehicle, position]);

  // Calculate vehicle heading
  const calculateHeading = (lat1, lng1, lat2, lng2) => {
    const toRad = (deg) => (deg * Math.PI) / 180;
    const toDeg = (rad) => (rad * 180) / Math.PI;

    const dLng = toRad(lng2 - lng1);
    const lat1Rad = toRad(lat1);
    const lat2Rad = toRad(lat2);

    const y = Math.sin(dLng) * Math.cos(lat2Rad);
    const x =
      Math.cos(lat1Rad) * Math.sin(lat2Rad) -
      Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(dLng);

    let heading = toDeg(Math.atan2(y, x));
    return (heading + 360) % 360;
  };

  // Update directions
  useEffect(() => {
    if (!isLoaded || !stops?.length || !route?.routeCoordinates) return;

    const directionsService = new window.google.maps.DirectionsService();
    const waypoints = stops.map(stop => ({
      location: new window.google.maps.LatLng(
        parseFloat(stop.latitude),
        parseFloat(stop.longitude)
      ),
      stopover: true
    }));

    const request = {
      origin: waypoints[0].location,
      destination: waypoints[waypoints.length - 1].location,
      waypoints: waypoints.slice(1, -1),
      travelMode: window.google.maps.TravelMode.DRIVING,
      optimizeWaypoints: false,
    };

    directionsService.route(request, (result, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        setDirections(result);
      } else {
        console.error("Directions request failed:", status);
      }
    });
  }, [stops, route, isLoaded]);

  // Fetch stop details
  const fetchStopDetails = async (stop, index, totalStops) => {
    if (!vehicle?.device_id) return { distance: "N/A", eta: "N/A" };

    try {
      const response = await fetch(
        `${config.baseURL}/distance/time/stop`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            vehicleId: vehicle.device_id,
            longitude: stop.longitude,
            latitude: stop.latitude,
            isExtreamPoint: index === 0 || index === totalStops - 1
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Distance/Time API error: ${response.status}`);
      }

      const data = await response.json();
      return {
        distance: data.distance || "N/A",
        eta: data.eta || "N/A",
      };
    } catch (error) {
      console.error(`Error fetching details for stop:`, error);
      return { distance: "N/A", eta: "N/A" };
    }
  };

  // Update stop details periodically
  useEffect(() => {
    if (!stops?.length || !vehicle) return;

    const updateStopDetails = async () => {
      const details = {};
      for (let i = 0; i < stops.length; i++) {
        const stopData = await fetchStopDetails(stops[i], i, stops.length);
        details[stops[i].stopId] = stopData;
      }
      setStopDetails(details);
    };

    updateStopDetails();
    const intervalId = setInterval(updateStopDetails, 30000);
    return () => clearInterval(intervalId);
  }, [stops, vehicle]);

  if (loadError) {
    return (
      <div>
        <DashboardHeader title="Vehicle" />
        <div style={{ padding: "32px" }}>
          Error loading maps. Please try again later.
        </div>
      </div>
    );
  }

  if (!isLoaded || !position || !vehicle || !route || !stops) {
    return (
      <div>
        <DashboardHeader title={`Vehicle > ${vehicle?.name || 'Loading...'}`} />
        <div style={{ alignItems: "center", marginLeft: "50%", marginTop: "20%" }}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <>
      <DashboardHeader title={`Vehicle > ${vehicle.name}`} />
      <div style={{ padding: "32px" }}>
        {hasMapPermission && (
          <>
            <NextStopTable stops={stops} stopDetails={stopDetails}/>

            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={position}
              zoom={10}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={{
                styles: mapStyles,
                disableDefaultUI: true,
                zoomControl: true,
                streetViewControl: true,
                fullscreenControl: true,
                mapTypeControl: false,
                gestureHandling: 'greedy',
                minZoom: 5,
                maxZoom: 40,
              }}
            >
              {directions && (
                <DirectionsRenderer
                  directions={directions}
                  options={{
                    suppressMarkers: true,
                    polylineOptions: {
                      strokeColor: "#3D3D3D",
                      strokeOpacity: 1.8,
                      strokeWeight: 3,
                    },
                  }}
                />
              )}

              {stops.map((stop, index) => (
                <Marker
                  key={index}
                  position={{
                    lat: parseFloat(stop.latitude),
                    lng: parseFloat(stop.longitude),
                  }}
                  label={{
                    text: stop.name,
                    color: index === 0 ? 'green' : index === stops.length - 1 ? 'blue' : 'black',
                    fontSize: '10px',
                    fontWeight: 'bold',
                    fontFamily: 'Arial, sans-serif',
                  }}
                  icon={{
                    url: index === 0 || index === stops.length - 1
                      ? `http://maps.google.com/mapfiles/ms/icons/${index === 0 ? 'green' : ''}-dot.png`
                      : undefined,
                    scaledSize: new window.google.maps.Size(30, 30),
                    labelOrigin: new window.google.maps.Point(35, 60),
                  }}
                />
              ))}

              {position && (
                <Marker
                  position={position}
                  icon={{
                    url: BusIcon,
                    scaledSize: new window.google.maps.Size(30, 30),
                    anchor: new window.google.maps.Point(15, 15),
                    rotation: heading - 45,
                  }}
                  animation={window.google.maps.Animation.DROP}
                />
              )}
            </GoogleMap>
          </>
        )}
        <RouteDetails details={{ route, vehicle, speed }} />
        <StopsTable stops={stops} stopDetails={stopDetails} />
      </div>
    </>
  );
};

export default DeviceLocationMap;