import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config.json";
import sortByParam from "../../hooks/SortByParam";
import sarchInTheTable from "../../hooks/SarchInTable";
import filterBy from "../../hooks/FilterBy";
import DashboardHeader from "../../components/header/DashboardHeader";
import { Paper } from "@mui/material";
import RoleHeader from "./RoleHeader";
import RoleTable from "./RoleTable";
import { useNavigate } from "react-router-dom";

let rolesList = []; // to store incoming roles objects

async function getAllRoles() {
  try {
    const response = await axios.get(`${config.baseURL}/role/organization/`, {
      withCredentials: true,
    });
    console.log("this is features", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching features:", error);
    throw error;
  }
}

async function createRole(role) {
  try {
    const response = await axios.post(`${config.baseURL}/role`, role, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error Creating features:", error);
    throw error;
  }
}

async function deleteRole(id) {
  try {
    const response = await axios.delete(`${config.baseURL}/role/${id}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error Deleting features:", error);
    throw error;
  }
}
const Role = () => {
  const [currentRoleData, setCurrentRoleData] = useState([]);
  const [searchInpuChange, setSearchInputChange] = useState("");
  const navigate=useNavigate();
  async function updateRole(role) {
    try {
      const response = await axios.put(`${config.baseURL}/role`, role, {
        withCredentials: true,
      });
      console.log(response.data, "updated role");
      setCurrentRoleData((prevData) =>
        prevData.map((item) =>
          item.id === role.id ? { ...item, ...response.data.role } : item
        )
      );
      return response.data;
    } catch (error) {
      console.error("Error Creating features:", error);
      throw error;
    }
  }
  // on change for user inputchange for searching roles
  const handleSearch = (e) => {
    const searchText = e.target.value.toLowerCase();
    console.log("role search text", searchInpuChange);
    setSearchInputChange(searchText);
  };
  useEffect(() => {
    getAllRoles()
      .then((roles) => {
        console.log(roles);
        rolesList = roles.roles;
        setCurrentRoleData(roles.roles);

        // Update roleList state

        // Search for a specific role within the fetched roles
        const searchedRole = sarchInTheTable(searchInpuChange, roles.roles);
        console.log(searchedRole, "searched role");
        setCurrentRoleData(searchedRole);
      })
      .catch((err) => {
        console.error(err)
        navigate("/");
      });
  }, [searchInpuChange]);
  // createRole({

  //   "name": "sub-admin",
  //   "description": "hi",
  //   "organizationId": 1

  // }).then(response => console.log(response)).catch(err => console.log(err));
  // updateRole({
  //   id: 1,
  //   "name": "super man",
  //   "description": "he can fly"
  // }).then(response => {
  //   console.log(response, "update----")
  // }).catch(err => console.log(err))

  // deleteRole(12).then(response => console.log(response, "deleted------ ")).catch(err => console.log(err));
  return (
   <>
   <DashboardHeader title="Users > User Roles" />
   
   <div className='page_container'>
    <RoleHeader roleList={currentRoleData}
    totalRoles={rolesList.length}
     createRole={createRole}
     onSearchChange={handleSearch}
     setCurrentRoleData={setCurrentRoleData}
     />
    <RoleTable roleList={currentRoleData} 
    updateRole={updateRole}
    deleteRole={deleteRole}
    />

   </div>
   </>
  )
}

export default Role;
