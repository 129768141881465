import React, { useState } from 'react'
import './feature.css'
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Snackbar, TextField, TextareaAutosize, Typography } from '@mui/material'
import { styled, width } from '@mui/system';
import AddIcon from "@mui/icons-material/Add";
import StarIcon from '../../components/starIcon/StarIcon';
//import { makeStyles } from '@mui/styles'; // updated to @mui/styles for MUI v5


// const useStyles = makeStyles((theme) => ({
// 	roundedTextField: {
// 	  '& .MuiOutlinedInput-root': {
// 		borderRadius: '16px', // Adjust the radius as needed
// 	  },
// 	  '& .MuiSelect-root': {
// 		borderRadius: '16px', // Adjust the radius as needed
// 	  },
// 	},
// 	paper: {
// 	  background: '#B7B7B7',
// 	  color: 'black',
// 	  padding: theme.spacing(2),
// 	  borderRadius: theme.spacing(1),
// 	  width: '100%',
// 	  marginTop: '30px',
// 	},
// 	select: {
//         borderRadius: '16px',
//         '& .MuiOutlinedInput-notchedOutline': {
//             borderColor: 'black',
//             borderWidth:"2px"
//         },
//     },
//   }));

function FeatureForm({createFeature,addFeature}) {
 // const classes = useStyles();

  const [formData, setFormData] = useState({
    name: '',
    apiPath: '',
    description: '',
    action: '',
  });
  const [errors, setErrors] = useState({});
  const [selectedAction, setSelectedAction] = useState("")
  // Handler function to update the selected action
const handleActionChange = (e) => {
  setSelectedAction(e.target.value);
};

  // handle form dta change
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Clear the error for the current field
    const updatedErrors = { ...errors };
    delete updatedErrors[name];
    setErrors(updatedErrors);

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // submit handler for creating the feature
  // submit handler for creating the feature
  const handleSubmit = async (e) => {
    console.log('Form submitted'); // Check if form submission is triggered
    e.preventDefault();

    const errors = {};
    if (!formData.name.trim()) {
      errors.name = 'Please enter a name.';
    }
    if (!formData.apiPath.trim()) {
      errors.apiPath = 'Please enter an API path.';
    }
    if (!selectedAction) {
      errors.action = 'Please select an action.';
    }
    setErrors(errors);

    // If there are validation errors, do not proceed with submission
    // if (Object.keys(errors).length > 0) {
    //   return;
    // }
   
  
    
    // Check if any errors exist
   
  
    const newFeature = {
      name: formData.name,
      description: formData.description,
      api_path: formData.apiPath,
      action: selectedAction,
    };
    try {
      const result = await createFeature(newFeature);
      console.log('Feature created successfully:', result);
      
      // Reset form fields
      addFeature(result)
      setFormData({
        name: '',
        apiPath: '',
        description: '',
        action: '',
      });
  
    } catch (error) {
      console.error('Error creating feature:', error);
    }
  };
  const selectStyles={
		color:"black",
		fontWeight: 'bold',
		border:"2px solid black ",
		BorderStyle:"none",
		height:"55px",
		borderRadius:"16px",
    marginRight:"14px",
    borderWidth:"2px solid black"
	  }
    const [totalFeatures, setTotalFeatures] = useState(0);
  return (
	<>
  <div className='form_header'style={{marginTop:'0px'}}>Add Features</div>
	
	<Box className="form-container"
        component="form"
        noValidate
        autoComplete="off"
      >
         <Grid item xs={12} sm={6}>
         <InputLabel
                htmlFor="Name"
                sx={{
                  fontWeight: "400",
                  size: "24px",
                  paddingBottom: "8px",
                  color: "black",
                }}
              >
              Name
                <StarIcon />
              </InputLabel>
        <TextField
          id="outlined-required"
        placeholder="Type Feature Name"
          name="name"
          value={formData.name}
          error={errors.name}
          helperText={errors.name}
          onChange={handleChange}
          fullWidth
      InputProps={{
        style: { borderRadius:'16px' }, // Custom styles for input label
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderWidth: '2px',
            borderColor: 'black', // Thicker border
          },
          '&:hover fieldset': {
            borderWidth: '2px',
          },
          '&.Mui-focused fieldset': {
            borderWidth: '2px',
          },
        },
      }}
        />
        </Grid>
        <Grid item xs={12} sm={6}>
         <InputLabel
                htmlFor="API Path"
                sx={{
                  fontWeight: "400",
                  size: "24px",
                  paddingBottom: "8px",
                  color: "black",
                }}
              >
              API Path
                <StarIcon />
              </InputLabel>
        <TextField
          id="outlined-required-2"
          placeholder="Type API Path"
          name="apiPath" 
          value={formData.apiPath}
          error={errors.apiPath}
          helperText={errors.apiPath}
          onChange={handleChange}
          fullWidth
      InputProps={{
        style: { borderRadius:'16px' }, // Custom styles for input label
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderWidth: '2px',
            borderColor: 'black', // Thicker border
          },
          '&:hover fieldset': {
            borderWidth: '2px',
          },
          '&.Mui-focused fieldset': {
            borderWidth: '2px',
          },
        },
      }}
        /> 
        </Grid>
      </Box>

      
      <Box className="form-container" component="form" noValidate autoComplete="off">
      <Grid item xs={12} sm={6}>
    <InputLabel
      htmlFor="Description"
      sx={{
        fontWeight: "400",
        size: "24px",
        color: "black",
        borderRadius: '16px',
        marginTop:'16px',
        marginBottom:'8px'
      }}
    >
      Description
      <StarIcon />
    </InputLabel>
    
    <TextField
      name="description"
      placeholder="Description"
      value={formData.description}
      fullWidth
      onChange={handleChange}
      multiline
      rows={1}
      sx={{
        fontFamily: 'roboto',
        fontSize: '24px',
        fontWeight: "400",
        '& .MuiOutlinedInput-root': {
          borderRadius: '16px', 
          '& fieldset': {
            borderWidth: '2px',
            borderColor: 'black',
          },
          '&:hover fieldset': {
            borderWidth: '2px',
          },
          '&.Mui-focused fieldset': {
            borderWidth: '2px',
          },
        }
      }}
    />
  </Grid>
        <FormControl fullWidth sx={{ mt: 3}}>
          
        <Grid item xs={12} sm={6} style={{marginTop:'-7px'}}>
  <Typography
    variant="h6"
    sx={{
      color: 'black',
      fontFamily: "Roboto",
      fontWeight: '400',
      marginBottom: '4px', 
      fontSize:"unset"
    }}
  >
    Operations <StarIcon sx={{ color: 'red' }} />
  </Typography>

  <InputLabel
    htmlFor="operation"
    sx={{
      color: 'black',
      fontFamily: "Roboto",
      fontWeight: '400',
      marginBottom: '4px', 
    }}
  >
  </InputLabel>

  <Select
    labelId="operation-label"
    id="operation"
    name="action" 
    fullWidth
    error={errors.apiPath}
    value={selectedAction}
    onChange={handleActionChange}
    sx={{
      borderRadius: '16px',
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderWidth: '2px',
          borderColor: 'black', 
        },
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black', 
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black', 
      },
    }}
    displayEmpty 
    renderValue={(selected) => {
      if (!selected) {
        return <em>Select Operations</em>; 
      }
      return selected;
    }}
  >
    <MenuItem value="">
      <em>Select Operations</em>
    </MenuItem>
    <MenuItem value='All'>All</MenuItem>
    <MenuItem value="GET">GET</MenuItem>
    <MenuItem value="POST">POST</MenuItem>
    <MenuItem value="PUT">PUT</MenuItem>
    <MenuItem value="DELETE">DELETE</MenuItem>
    <MenuItem value="PATCH">PATCH</MenuItem>
  </Select>
  
  {errors.apiPath && <FormHelperText>{errors.apiPath}</FormHelperText>}
</Grid>


          <div className='addbutton_container' style={{marginTop:'32px'}}> 
          <button
		  variant="contained"
		  className="customButton_add"
      style={{background:'rgba(249, 231, 25, 1)',color:'black'}}
      onClick={handleSubmit}
		>
		  Submit
		</button>
    </div>

        </FormControl>
      </Box>
	</>
  )
}

export default FeatureForm