import React, { useState } from "react";
import { InputAdornment, TextField } from "@mui/material";

const SearchVehicle = ({ handleVehicleSearch }) => {
  const [isFocused, setIsFocused] = useState(false);

  // Handlers for focus and blur events
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div className="o-searchbar">
      <TextField
        size="small"
        id="search-vehicle"
        label="Search"
        variant="outlined"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleVehicleSearch}
        style={{
          background: "rgba(242, 242, 243, 1)",
          width: "300px",
          borderRadius: "8px",
        }}
        InputProps={{
          style: {
            border: isFocused ? "none" : "1px solid rgba(0, 0, 0, 0.23)", // Hide border on focus
            borderRadius: "8px",
            padding: "0 8px",
          },
          endAdornment: (
            <InputAdornment position="end">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 17.5C13.4183 17.5 17 13.9183 17 9.5C17 5.08172 13.4183 1.5 9 1.5C4.58172 1.5 1 5.08172 1 9.5C1 13.9183 4.58172 17.5 9 17.5Z"
                  stroke="#183059"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.9984 19.4999L14.6484 15.1499"
                  stroke="#183059"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: {
            backgroundColor: "rgba(242, 242, 243, 1)", // Prevent overlap
            padding: "0 4px",
          },
        }}
      />
    </div>
  );
};

export default SearchVehicle;
