// googleMapsConfig.js
export const GOOGLE_MAPS_API_KEY = "AIzaSyD7rqUpTzUpEbxm-Xc7ikltFJGzOhd92Qk";

export const loadOptions = {
  id: "google-map-script",
  googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  libraries: ["places"],
};


