import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config.json';
import DashboardHeader from '../../components/header/DashboardHeader';
import { Paper } from '@mui/material';
import DriverHeader from './DriverHeader';
import DriverTable from './DriverTable';
import sarchInTheTable from '../../hooks/SarchInTable';
import sortByParam from '../../hooks/SortByParam';

let driversList=[];

const getAllDrivers = async () => {
  try {
    const response = await axios.get(`${config.baseURL}/driver/organization`);
    return response.data;
  } catch (error) {
    console.error('Error while fetching the data: ', error);
    throw error;
  }
};

const fetchImage = async (imageUrl) => {
  try {
    const response = await axios.get(imageUrl, { responseType: 'arraybuffer' });
    const blob = new Blob([response.data], { type: 'image/jpeg' });
    const imageDataUrl = URL.createObjectURL(blob);
    return imageDataUrl;
  } catch (error) {
    console.error('Error while fetching image:', error);
    return null;
  }
};

const transformDriverData = async (data) => {
  try {
    const drivers = data;
    const transformedData = [];
    for (const driver of drivers) {
      const photoDocument = driver.documents.find((doc) => doc.name === 'photo');
      const photoFileName = photoDocument ? photoDocument.location : null;

      let imageUrl = null;
      if (photoFileName) {
        imageUrl = `${config.baseURL}/upload/image?fileName=${photoFileName}`;
        const imageData = await fetchImage(imageUrl);
        imageUrl = imageData;
      }

      transformedData.push({
        id: driver.id,
        DriverName: `${driver.first_name} ${driver.last_name}`,
        driver_id: driver.driver_id,
        vehicle: driver.vehicle ? driver.vehicle.device_id : null,
        DL_number: driver.driving_license_number,
        image_url: imageUrl,
        email:driver.email,
        phone: driver.phone_number,
      });
    }

    console.log(transformedData);
    return transformedData;
  } catch (error) {
    console.error('Error while transforming the data:', error);
    return [];
  }
};

const Driver = () => {
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchInput,setSearchInput]=useState("");
  const [sortParam, setSortParam] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [allVehicles,setAllVehicles]=useState([]);

  // handle change for getting search input for searching
  const handleSearch=(e)=>{
    setSearchInput(e.target.value)
    console.log("search input",searchInput)
  }
  // for sorting
  const handleSortChange = (param) => {
    // If the currently sorted parameter is the same as the clicked one, toggle direction
    const newSortDirection = sortParam === param ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
    setSortDirection(newSortDirection);
    setSortParam(param);
  };
  useEffect(()=>{
    const fetchvehicles = async ()=>{
      try {
        const vehiclesResponse = await axios.get(`${config.baseURL}/vehicle/`, {
          withCredentials: true,
        })
        console.log("vehicles response",vehiclesResponse.data.vehicles)
        setAllVehicles(vehiclesResponse.data.vehicles)
      }
      catch(error){
        console.log("error in fetching vehicles",error);
      }
    }
    fetchvehicles();
  },[])
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllDrivers();
        const transformedData = await transformDriverData(response);
        driversList=transformedData
        setDrivers(transformedData);
         // Apply sorting
     let sortedFeatures = sortByParam(sortParam, driversList);
     if (sortDirection === 'desc') {
       // If sorting direction is descending, reverse the sorted array
       sortedFeatures = sortedFeatures.reverse();
     }
     setDrivers(sortedFeatures);
     if(searchInput){
      const searchResult = sarchInTheTable(searchInput, driversList);
      setDrivers(searchResult)
        console.log("search result", searchResult);
     }

      } catch (error) {
        console.error('Error while fetching and transforming the data:', error);
        setError('Failed to fetch driver data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchInput,sortParam,sortDirection]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <DashboardHeader title="Drivers" />
      <div className='page_container'>
       <DriverHeader 
       totalDrivers={driversList.length}
       handleSearch={handleSearch}
       />
       <DriverTable 
       drivers={drivers}
       onSortChange={handleSortChange}
       sortParam={sortParam}
       sortDirection={sortDirection}
       allVehicles={allVehicles}
       />
      </div>
    </>
  );
};

export default Driver;
