// import React from "react";
// import Box from "@mui/material/Box";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// // import './stops.css';
// import "./stopScheduleDetails.css";


// const repitations = [
//   { id: 1, repitation: "daily" },
//   { id: 2, repitation: "weekly" },
//   { id: 3, repitation: "monthly" }
// ]

// const StopScheduleFilter = ({
//   handleRepetitionChange,
//   handleVehicleChange,
//   routesData,
//   vehiclesData,
//   handleRouteChange,
//   filterSchedules }) => {

//   return (
//     <Box
//     sx={{
//       minWidth: 700, 
//       display: "flex",
//       gap: 2,
//       justifyContent: "space-around",
//       "@media (max-width: 600px)": {
//         flexDirection: "column", // Stacks items vertically on small screens
//         // alignItems: "center",  
//         flexWrap: "wrap",
//         minWidth: 250,
//       },
//     }}
//     >

//       {/* Dropdown for filtering by repetition */}
//       <FormControl fullWidth size="small" style={{ background: "rgba(242, 242, 243, 1)" }}>
//         <InputLabel id="routes-dropdown">Repitation</InputLabel>
//         <Select
//           labelId="routes-dropdown"
//           id="routes-dropdown"
//           label="Routes"
//           value={filterSchedules.repitation}
//           onChange={handleRepetitionChange}
//         >
//           <MenuItem value={'All'}>All</MenuItem>
//           {repitations.map((item) => (
//             <MenuItem key={item.id} value={item.repitation}>
//               {item.repitation}
//             </MenuItem>
//           ))}

//         </Select>
//       </FormControl>
//       <FormControl fullWidth size="small" style={{ background: "rgba(242, 242, 243, 1)" }}>
//         <InputLabel id="stops-dropdown">Vehicles</InputLabel>
//         <Select
//           labelId="stops-dropdown"
//           id="name"
//           label="stops"
//           value={filterSchedules.Vehicle}
//           onChange={handleVehicleChange}
//         >
//           <MenuItem value={'All'}>All</MenuItem>
//           {vehiclesData.map((vehicle) => (
//             <MenuItem key={vehicle.id} value={vehicle.name}>
//               {vehicle.name}
//             </MenuItem>
//           ))}

//         </Select>
//       </FormControl>
//       <FormControl fullWidth size="small" style={{ background: "rgba(242, 242, 243, 1)" }}>
//         <InputLabel id="stops-dropdown">Routes</InputLabel>
//         <Select
//           labelId="stops-dropdown"
//           id="name"
//           label="stops"
//           value={filterSchedules.routes}
//           onChange={handleRouteChange}
//         >
//           <MenuItem value={'All'}>All</MenuItem>
//           {routesData.map((route) => (
//             <MenuItem key={route.id} value={route.name}>
//               {route.name}
//             </MenuItem>
//           ))}

//         </Select>
//       </FormControl>
//     </Box>
//   );
// };

// export default StopScheduleFilter;

import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./stopScheduleDetails.css";

const repitations = [
  { id: 1, repitation: "daily" },
  { id: 2, repitation: "weekly" },
  { id: 3, repitation: "monthly" },
];

const StopScheduleFilter = ({
  handleRepetitionChange,
  handleVehicleChange,
  routesData,
  vehiclesData,
  handleRouteChange,
  filterSchedules,
}) => {
  return (
    <Box
      sx={{
        minWidth: 700, 
        display: "flex",
        gap: 2,
        flexWrap: "wrap", 
        justifyContent: "space-between",
        "@media (max-width: 600px)": {
          gap: 1, 
          justifyContent: "flex-start", 
          minWidth: 250,
        },
      }}
    >
      {/* Dropdown for filtering by repetition */}
      <FormControl
        size="small"
        sx={{
          flex: "1 1 30%", 
          minWidth: "100px",
          background: "rgba(242, 242, 243, 1)",
        }}
      >
        <InputLabel id="repitation-dropdown" sx={{
          background: "rgba(242, 242, 243, 1)",
          padding: "0 4px",
        }}>Repetition</InputLabel>
        <Select
          labelId="repitation-dropdown"
          id="repitation-dropdown"
          label="Repetition"
          value={filterSchedules.repitation}
          onChange={handleRepetitionChange}
        >
          <MenuItem value={"All"}>All</MenuItem>
          {repitations.map((item) => (
            <MenuItem key={item.id} value={item.repitation}>
              {item.repitation}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Dropdown for filtering by vehicles */}
      <FormControl
        size="small"
        sx={{
          flex: "1 1 30%", // Dynamic width; adjusts for small screens
          minWidth: "100px",
          background: "rgba(242, 242, 243, 1)",
        }}
      >
        <InputLabel id="vehicles-dropdown" sx={{
          background: "rgba(242, 242, 243, 1)",
          padding: "0 4px",
        }}>Vehicles</InputLabel>
        <Select
          labelId="vehicles-dropdown"
          id="vehicles-dropdown"
          label="Vehicles"
          value={filterSchedules.Vehicle}
          onChange={handleVehicleChange}
        >
          <MenuItem value={"All"}>All</MenuItem>
          {vehiclesData.map((vehicle) => (
            <MenuItem key={vehicle.id} value={vehicle.name}>
              {vehicle.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Dropdown for filtering by routes */}
      <FormControl
        size="small"
        sx={{
          flex: "1 1 30%", 
          minWidth: "100px",
          background: "rgba(242, 242, 243, 1)",
        }}
      >
        <InputLabel id="routes-dropdown" sx={{
          background: "rgba(242, 242, 243, 1)",
          padding: "0 4px",
          border:"2px solid red"
        }}>Routes</InputLabel>
        <Select
          labelId="routes-dropdown"
          id="routes-dropdown"
          label="Routes"
          value={filterSchedules.routes}
          onChange={handleRouteChange}
        >
          <MenuItem value={"All"}>All</MenuItem>
          {routesData.map((route) => (
            <MenuItem key={route.id} value={route.name}>
              {route.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default StopScheduleFilter;
