import React, { useState, useEffect } from "react";

const formatTime = (seconds) => {
  if (!seconds || seconds <= 0) return "N/A";

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  } else if (minutes > 0) {
    return `${minutes}m ${secs}s`;
  } else {
    return `${secs}s`;
  }
};

const StopsTable = ({ stops, stopDetails }) => {
    // Sort stops based on distance
    const sortedStops = [...stops].sort((a, b) => {
      const distA = stopDetails[a.stopId]?.distance;
      const distB = stopDetails[b.stopId]?.distance;
  
      // Convert distances to numbers, handling 'N/A' cases
      const numA = typeof distA === 'number' ? distA : parseFloat(distA);
      const numB = typeof distB === 'number' ? distB : parseFloat(distB);
  
      // If either is NaN (not a number), move it to the end
      if (isNaN(numA) && isNaN(numB)) return 0;
      if (isNaN(numA)) return 1; // Move a to the end
      if (isNaN(numB)) return -1; // Move b to the end
  
      // Normal numeric comparison
      return numA - numB;
    });
  
    return (
      <table style={{ width: "100%", marginTop: "20px", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={tableHeaderStyle}>Stop Name</th>
            <th style={tableHeaderStyle}>Stop ID</th>
            <th style={tableHeaderStyle}>Distance</th>
            <th style={tableHeaderStyle}>ETA</th>
          </tr>
        </thead>
        <tbody>
          {sortedStops.map((stop) => {
            const details = stopDetails[stop.stopId] || { distance: "N/A", eta: "N/A" };
            return (
              <tr key={stop.stopId}>
                <td style={tableCellStyle}>{stop.name}</td>
                <td style={tableCellStyle}>{stop.stopId}</td>
                <td style={tableCellStyle}>
                  {details.distance !== "N/A"
                    ? `${parseFloat(details.distance).toFixed(1)} km`
                    : "N/A"}
                </td>
                <td style={tableCellStyle}>
                  {formatTime(details.eta)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
  
  const tableHeaderStyle = {
    backgroundColor: "#20A8E0",
    color: "white",
    fontSize: "16px",
    fontWeight: "600",
    textAlign: "left",
    padding: "12px 20px",
    borderBottom: "2px solid #ddd",
  };
  
  const tableCellStyle = {
    padding: "12px 20px",
    textAlign: "left",
    fontSize: "14px",
    borderBottom: "1px solid #ddd",
    color: "#333",
  };
  
  const evenRowStyle = {
    backgroundColor: "#f2f2f2",
  };
  
  const oddRowStyle = {
    backgroundColor: "#ffffff",
  };

  export default StopsTable;