import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Autocomplete, Marker, InfoWindow } from '@react-google-maps/api';
import CircleIcon from "@mui/icons-material/Circle";
import { InputAdornment, TextField } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';

const containerStyle = {
  width: '100%',
  height: '50vh'
};

const inputStyles = {
  position: "absolute",
  top: "20px",
  left: "20px",
  zIndex: 1
};

const defaultCenter = {
  lat: -3.745,
  lng: -38.523
};

function Map({ initialLatitude, initialLongitude, onPositionChange }) {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyD7rqUpTzUpEbxm-Xc7ikltFJGzOhd92Qk",
    libraries: ['places']
  });

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(defaultCenter);
  const [marker, setMarker] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);
  
  const autoCompleteRef = React.useRef(null);

  useEffect(() => {
    if (initialLatitude && initialLongitude) {
      const newPosition = {
        lat: parseFloat(initialLatitude),
        lng: parseFloat(initialLongitude)
      };
      setCenter(newPosition);
      setMarker(newPosition);
      setInfoWindow(newPosition);
      if (map) {
        map.panTo(newPosition);
        
        map.setZoom(15);
      }
    }
  }, [initialLatitude, initialLongitude, map]);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, [center]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handlePlaceSelect = () => {
    if (autoCompleteRef.current && typeof autoCompleteRef.current.getPlace === 'function') {
      const place = autoCompleteRef.current.getPlace();
      if (place && place.geometry && place.geometry.location) {
        const newPosition = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };
        updatePosition(newPosition);
      }
    }
  };

  const handleMapClick = (event) => {
    const newPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };
    updatePosition(newPosition);
  };

  const updatePosition = (position) => {
    setCenter(position);
    setMarker(position);
    setInfoWindow(position);
    if (map) {
      map.panTo(position);
      map.setZoom(15);
    }
    onPositionChange(position.lat, position.lng);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={handleMapClick}
    >
      <div style={inputStyles}>
        <Autocomplete
          onLoad={(autoComplete) => {
            autoCompleteRef.current = autoComplete;
          }}
          onPlaceChanged={handlePlaceSelect}
        >

          <div className="search-filter-wrapper search_container" style={{ position: 'absolute', zIndex: 1000 ,backgroundColor:"transparent"
}}>
          <TextField
            size="small"
            id="search"
            label="Search"
            variant="outlined"
            InputProps={{ 
              endAdornment: (
                <InputAdornment position="end">
                   <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000000"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-search"
        style={{ cursor: 'pointer', marginLeft: '5px' }}
      >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
      </svg>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: {
               border:"none",
              },
            }}
          />
          </div>
         
        </Autocomplete>
      </div>
      
      {marker && (
        <Marker position={marker} />
      )}
  
      {infoWindow && (
        // <InfoWindow
        //   position={infoWindow}
        //   onCloseClick={() => setInfoWindow(null)}
        // >
        //   <div>{`Latittttude: ${infoWindow.lat.toFixed(6)}, Longitude: ${infoWindow.lng.toFixed(6)}`}</div>
        // </InfoWindow>
        <LocationOnIcon style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 2 }} />
      )}
  
      {infoWindow && (
        <div
          style={{
            position: 'absolute',
            background: 'white',
            padding: '5px',
            borderRadius: '5px',
            fontSize: '14px',
            fontWeight: 'bold',
            zIndex: 2,
            left: '10px',
            bottom: '10px',
          }}
        >
          {`Latittttude: ${infoWindow.lat.toFixed(6)}, Longitude: ${infoWindow.lng.toFixed(6)}`}
        </div>
      )}
    </GoogleMap>
  );
}

export default React.memo(Map);