import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Material UI Components
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  useTheme,
} from "@mui/material";
import { alpha } from '@mui/system';


// Material UI Icons
import {
  DirectionsCar,
  Route,
  LocationOn,
  Group,
  Notifications,
  Alarm,
  People,
  Hail,
  PolylineOutlined,
  ArrowForwardIos,
  EmojiPeople,
  DriveEta,
} from "@mui/icons-material";

// Local Components and Config
import "./Overview.css";
import { card } from "./Carddata";
import config from "../../../config.json";
import Map from "../../../components/map/Map";
import DashboardHeader from "../../../components/header/DashboardHeader";
import { StyledPaper } from "../../../components/formComponents";
import Navbar from "../../../components/navbar/Navbar";



const Overview = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [vehicles, setVehicles] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [stops, setStops] = useState([]);
  const [users, setUsers] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [nextThreeHoursSchedules, setNextThreeHoursSchedules] = useState([]);
  const [overview, setOverview] = useState({
    users: 0,
    vehicles: 0,
    routes: 0,
    stops: 0,
    schedules: 0,
    activeVehicles: 0,
    inactiveVehicles: 0,
    deadVehicles: 0,
    totalAlerts: 0,
    vehicleAlerts: 0,
    userAlerts: 0,
  });

  async function getAllUsers() {
    try {
      const resp = await axios.get(`${config.baseURL}/user/organization/17`, {
        withCredentials: true,
      });
      return resp.data.users;
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        navigate("/");
        return [];
      }
      return [];
    }
  }
  async function getAllSchedules() {
    try {
      const resp = await axios.get(`${config.baseURL}/schedule/organization`, {
        withCredentials: true,
      });
      console.log(resp)
      return resp.data;

    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        navigate("/");
        return [];
      }
      return [];
    }
  }
  async function getAllVehicles() {
    try {
      const resp = await axios.get(`${config.baseURL}/vehicle/organization`, {
        withCredentials: true,
      });
      console.log("this is vehicles", resp.data);
      return resp.data;
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        navigate("/");
        return [];
      }
      return [];
    }
  }

  async function getAllRoutes() {
    try {
      const resp = await axios.get(`${config.baseURL}/route/organization`, {
        withCredentials: true,
      });
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        return [];
      }
      console.error(error);
      return [];
    }
  }

  async function getAllStops() {
    try {
      const resp = await axios.get(`${config.baseURL}/stop/organization`, {
        withCredentials: true,
      });
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        return [];
      }
      console.error(error);
      return [];
    }
  }


  const filterNextThreeHoursSchedules = (schedules) => {
    const currentTime = new Date();
    const threeHoursLater = new Date(currentTime.getTime() + 3 * 60 * 60 * 1000);

    return schedules.filter((schedule) => {
      const scheduleTime = new Date(schedule.startTime); // Adjust this to match your API's schedule time field
      return scheduleTime >= currentTime && scheduleTime <= threeHoursLater;
    });
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const [schedulesData, vehiclesData, routesData, stopsData, usersData] =
          await Promise.all([
            getAllSchedules(),
            getAllVehicles(),
            getAllRoutes(),
            getAllStops(),
            getAllUsers(),
          ]);

        if (isMounted) {
          setSchedules(schedulesData);
          setVehicles(vehiclesData);
          setRoutes(routesData);
          setStops(stopsData);
          setUsers(usersData);

          setNextThreeHoursSchedules(filterNextThreeHoursSchedules(schedulesData));

          setOverview({
            users: usersData.length,
            vehicles: vehiclesData.length,
            routes: routesData.length,
            stops: stopsData.length,
            schedules: schedulesData.length,
            activeVehicles: vehiclesData.filter(
              (vehicle) => vehicle.status === "ACTIVE"
            ).length,
            inactiveVehicles: vehiclesData.filter(
              (vehicle) =>
                vehicle.status === "INACTIVE" || vehicle.status === "DEAD"
            ).length,
            deadVehicles: vehiclesData.filter(
              (vehicle) => vehicle.status === "DEAD"
            ).length,
            totalAlerts: 0,
            vehicleAlerts: 0,
            userAlerts: 0,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const StatCard = ({ icon: Icon, title, value, subStats, bgcolor }) => (
    <Card
      sx={{
        minHeight: 150, // Ensure uniform card height
        background: `linear-gradient(135deg, ${bgcolor} 0%, ${alpha(bgcolor, 0.8)} 100%)`,
        color: 'white',
        display: 'flex',
        flexDirection: 'column', // Ensure content alignment
        justifyContent: 'space-between', // Space out content
        transition: 'transform 0.2s',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: theme.shadows[8],
        },
      }}
    >
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Icon sx={{ fontSize: 32 }} />
            <Typography variant="h6">{title}</Typography>
          </Box>
          <Typography variant="h4">{value}</Typography>
        </Box>

        {subStats && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              mt: 2,
              '& > div': {
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                padding: '3px 8px',
                borderRadius: '20px',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            {subStats.map((stat, index) => (
              <Box key={index}>
                {stat.icon}
                <Typography sx={{ fontSize: '10px' }}>{stat.value}</Typography>
              </Box>
            ))}
          </Box>
        )}
      </CardContent>
    </Card>
  );




  return (
    <>

      <DashboardHeader title="Dashboard" />

      <StyledPaper>
        <Box sx={{ p: 3, height: '100%' }}>
          <Grid container spacing={3}>
            {/* Stats Section */}
            <Grid item xs={12} md={9}>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <StatCard
                    icon={DirectionsCar}
                    title="Vehicles"
                    value={overview.vehicles}
                    bgcolor="#5800BF"
                    subStats={[
                      { icon: <DirectionsCar sx={{ color: '#4CAF50' }} />, value: overview.activeVehicles },
                      { icon: <DirectionsCar sx={{ color: '#FFC107' }} />, value: overview.inactiveVehicles },
                      { icon: <DirectionsCar sx={{ color: '#F44336' }} />, value: overview.deadVehicles }
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <StatCard
                    icon={Route}
                    title="Routes"
                    value={overview.routes}
                    bgcolor="#E58A2E"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <StatCard
                    icon={Hail}
                    title="Stops"
                    value={overview.stops}
                    bgcolor="#007FBF"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <StatCard
                    icon={PolylineOutlined}
                    title="Sites"
                    value="3"
                    bgcolor="#A600A6"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <StatCard
                    icon={Notifications}
                    title="Alerts"
                    value={overview.totalAlerts}
                    bgcolor="#E51717"
                    subStats={[
                      { icon: <DirectionsCar />, value: overview.vehicleAlerts },
                      { icon: <People />, value: overview.userAlerts },
                      { icon: <Alarm />, value: overview.schedulesAlerts }
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <StatCard
                    icon={Group}
                    title="Drivers"
                    value="25"
                    bgcolor="#1100A6"
                  />
                </Grid>
              </Grid>
            </Grid>


            {/* Schedule Section */}
            <Grid item xs={12} md={3}>
              <Paper
                elevation={3}
                sx={{
                  height: '100%',
                  maxHeight: 500, // Set a max height to prevent excessive growth
                  overflow: 'auto', // Enable scrolling if content exceeds max height
                  p: 0,
                  backgroundColor: alpha(theme.palette.background.paper, 0.9), // Retain the original background for the rest
                  borderRadius: 2,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    mb: 2,
                    fontWeight: 'bold',
                    backgroundColor: '#FFC94D',
                    color: 'black',
                    padding: 1,
                    borderRadius: 1,
                    fontSize: "14px",
                    paddingLeft:"10px"
                  }}
                >
                  Next 3 Hours Schedules
                </Typography>
                {nextThreeHoursSchedules.length > 0 ? (
                  <List>
                    {nextThreeHoursSchedules.map((schedule, index) => (
                      <React.Fragment key={index}>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'black' }}>
                                {schedule.vehicleName || `Vehicle ${index + 1}`}
                              </Typography>
                            }
                            secondary={
                              <>
                                <Typography component="span" variant="body2" sx={{ color: 'black' }}>
                                  Route: {schedule.routeName || "N/A"}
                                </Typography>
                                <br />
                                <Typography component="span" variant="body2" sx={{ color: 'black' }}>
                                  Origin: {schedule.origin || "Unknown"}
                                </Typography>
                                <br />
                                <Typography component="span" variant="body2" sx={{ color: 'black' }}>
                                  Start: {new Date(schedule.startTime).toLocaleTimeString()}
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                        {index < nextThreeHoursSchedules.length - 1 && <Divider />}
                      </React.Fragment>
                    ))}
                  </List>
                ) : (
                  <Typography
                    color="textSecondary"
                    sx={{
                      textAlign: 'center',
                      mt: 2,
                      color: 'black', // Black text color for "No schedules" message
                    }}
                  >
                    No schedules in the next 3 hours.
                  </Typography>
                )}
              </Paper>
            </Grid>



            {/* Map Section */}


            <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  height: 400, // Ensuring the Paper has a fixed height
                  borderRadius: 2,
                  overflow: 'hidden', // Prevents any content overflow
                  display: 'flex', // Ensures flexbox layout
                }}
              >
                <Box
                  sx={{
                    flex: 1, // Occupies all available space
                    display: 'flex', // Nested flexbox ensures no extra spacing
                    flexDirection: 'column',
                    justifyContent: 'center', // Aligns the content
                    alignItems: 'center', // Center alignment
                  }}
                >
                  <Map stops={stops} routes={routes} />
                </Box>
              </Paper>
            </Grid>


          </Grid>
        </Box>
      </StyledPaper>

    </>

  );
};

export default Overview;
