import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import sortByParam from '../../hooks/SortByParam';
import searchInTheTable from '../../hooks/SarchInTable';
import filterBy from '../../hooks/FilterBy';
import config from '../../config.json';
import Pagination from '../../components/pagination/Pagination';
import { Box, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, Table, TableBody, tableCellClasses, TableContainer, TableHead, TableRow, TextField, TableCell, styled } from '@mui/material';
import DashboardHeader from '../../components/header/DashboardHeader'
import Navbar from '../../components/navbar/Navbar';
async function getAllSites() {
  try {
    const response = await axios.get(`${config.baseURL}/site/organization?organizationId=1`, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.log("Error in fetching site data: ", error);
    throw error;
  }
}

async function deleteSite(id) {
  try {
    const response = await axios.delete(`${config.baseURL}/site`, {
      data: { id },
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.log("Error in deleting site data: ", error);
    throw error;
  }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {

    backgroundColor: 'rgba(32, 168, 224, 1)',
    color: ' white', fontSize: 20, fontWeight: 600
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 24,
  },
}));
const Site = () => {
  const [siteList, setSiteList] = useState([]);
  const [sortedTableData, setSortedTableData] = useState([]);
  const [searchStr, setSearchStr] = useState('');
  const [filterSiteId, setFilterSiteId] = useState('');
  const [filterName, setFilterName] = useState('');
  const [sortParam, setSortParam] = useState('siteId');
  const [sortDirection, setSortDirection] = useState('asc');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const sites = await getAllSites();
        setSiteList(sites);
        setSortedTableData(sortByParam(sortParam, sites));
      } catch (error) {
        console.log(error);
      }
    };

    fetchSites();
  }, []);

  useEffect(() => {
    let filteredData = searchInTheTable(searchStr, siteList);
    if (filterSiteId) {
      filteredData = filterBy('siteId', filterSiteId, filteredData);
    }
    if (filterName) {
      filteredData = filterBy('name', filterName, filteredData);
    }
    setSortedTableData(sortByParam(sortParam, filteredData, sortDirection));
  }, [searchStr, filterSiteId, filterName, sortParam, sortDirection, siteList]);

  const tableHeaders = ['Site ID', 'Name', 'Description', 'Vehicles', 'Actions'];

  const handleSortChange = (param) => {
    const newSortDirection = sortParam === param ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
    setSortDirection(newSortDirection);
    setSortParam(param);
  };

  const handleSearchChange = (event) => {
    setSearchStr(event.target.value);
  };

  const handleFilterSiteIdChange = (event) => {
    setFilterSiteId(event.target.value);
  };

  const handleFilterNameChange = (event) => {
    setFilterName(event.target.value);
  };

  const handleUpdate = (siteId) => {
    navigate(`/site/${siteId}`);
  };

  const handleDelete = async (siteId) => {
    try {
      await deleteSite(siteId);
      setSiteList(siteList.filter(site => site.id !== siteId));
    } catch (error) {
      console.log(error);
    }
  };

  return (
<>

      <DashboardHeader title="Sites" />
      <div className='page_container'>
        <div className="addbutton_container ">
          <button className="customButton_add" onClick={() => navigate('/site/add')}>+ Add Site</button>
        </div>


        <Box className="Headercard" sx={{ width: "33%" }}>
          <Grid item xs>
            <Box className="Headercard_Total">
              <div className="Headercard_Total_text">
                <div className="org-para">
                  Total<br />Sites
                </div>
                <div>
                  <span className="org-count">
                    {/* {totalDrivers} Drivers */}
                    0
                  </span>
                </div>
              </div>
            </Box>
          </Grid>
        </Box>



        <div className='search-filter-wrapper' style={{padding:"20px"}}>

          <div className="o-searchbar shared-bg">
            <TextField
              size="small"
              id="search-organization"
              label="Search "
              variant="outlined"
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 17.5C13.4183 17.5 17 13.9183 17 9.5C17 5.08172 13.4183 1.5 9 1.5C4.58172 1.5 1 5.08172 1 9.5C1 13.9183 4.58172 17.5 9 17.5Z" stroke="#183059" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M18.9984 19.4999L14.6484 15.1499" stroke="#183059" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </InputAdornment>
                ),
              }}
            />



          </div>

          <Box
            sx={{ minWidth: 500 }}
            display="flex"
            gap={3}
            alignItems="center"
            justifyContent="space-between"
          >
            <div style={{ display: "flex", gap: 16, width: '100%' }}>
              <FormControl fullWidth size="small" style={{ background: "rgba(242, 242, 243, 1)" }}>
                <InputLabel id="filter-site-id-dropdown ">Site ID</InputLabel>
                <Select
                  labelId="filter-site-id-dropdown"
                  id="filterSiteId"
                  value={filterSiteId}
                  onChange={handleFilterSiteIdChange}
                  label="Site ID"
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {siteList.map((site) => (
                    <MenuItem key={site.siteId} value={site.siteId}>
                      {site.siteId}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth size="small" style={{ background: "rgba(242, 242, 243, 1)" }}>
                <InputLabel id="filter-name-dropdown">Site Name</InputLabel>
                <Select
                  labelId="filter-name-dropdown"
                  id="filterName"
                  value={filterName}
                  onChange={handleFilterNameChange}
                  label="Site Name"
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {siteList.map((site) => (
                    <MenuItem key={site.siteId} value={site.name}>
                      {site.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Box>



        </div>


        <div className='page_conatiner'>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((header, index) => (
                  <StyledTableCell align='justify' key={index} onClick={() => handleSortChange(header.toLowerCase().replace(' ', ''))}>
                    {header} {sortParam === header.toLowerCase().replace(' ', '') ? (sortDirection === 'asc' ? <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.5328 15C10.763 16.3333 8.83853 16.3333 8.06873 15L1.14053 3C0.370726 1.66667 1.33298 1.78935e-06 2.87258 1.65476e-06L16.729 4.43391e-07C18.2686 3.08794e-07 19.2308 1.66667 18.461 3L11.5328 15Z" fill="#183059" />
                    </svg>
                      : <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5328 15C10.763 16.3333 8.83853 16.3333 8.06873 15L1.14053 3C0.370726 1.66667 1.33298 1.78935e-06 2.87258 1.65476e-06L16.729 4.43391e-07C18.2686 3.08794e-07 19.2308 1.66667 18.461 3L11.5328 15Z"
                          fill="#183059" transform="rotate(180, 9.5, 8)" />
                      </svg>
                    ) : ''}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTableData.length > 0 ? (
                sortedTableData.map((row, index) => (
                  <TableRow key={index}>
                    <StyledTableCell style={{ fontWeight: 600 }}>{row.siteId}</StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.description}</StyledTableCell>
                    <StyledTableCell>{JSON.stringify(row.vehicles.map((vehicle) => vehicle.name))}</StyledTableCell>
                    <StyledTableCell>
                      <button onClick={() => handleUpdate(row.id)}>Update</button>
                      <button onClick={() => handleDelete(row.id)}>Delete</button>
                    </StyledTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <StyledTableCell colSpan={tableHeaders.length}>No data available</StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div>
      <Pagination
            totalItems={sortedTableData.length}
            itemsPerPage={10}
            currentPage={1}
            onPageChange={(page) => console.log(page)}
          />
          </div>
      </div>

    </>
  );
}

export default Site;
